<template>
    <a @click="run" href="javascript:void(0)" :class="btnClasses">Зарегистрировать инцидент</a>
</template>

<script>
import {defineComponent} from 'vue';
import CreateTicket from "@/modules/CreateTicket";

export default defineComponent({
    name: 'FromService',
    props: {
        id: {
            type: Number
        },
        classes: {
            type: Array
        }
    },
    data() {
        return {
            serviceId: 0
        }
    },
    mounted() {
        if (this.id) {
            this.serviceId = this.id;
        }

        document.addEventListener(
            'SwitchCreateTicketFromService',
            (e) => this.serviceId = e.detail,
            false
        );
    },
    methods: {
        run() {
            if (this.serviceId) {
                CreateTicket.runWithService(this.serviceId);
            }
        }
    },
    computed: {
        btnClasses() {
            let classes = {};

            if (this.classes) {
                for (let i in this.classes) {
                    classes[this.classes[i]] = true;
                }
            }

            classes.btn = true;
            classes.disabled = !this.serviceId;

            return classes;
        }
    }
});
</script>
