export default class Notification {

    static error(message) {
        document.dispatchEvent(new CustomEvent('ShowError', {detail: message}));
    }

    static errors(messages) {
        document.dispatchEvent(new CustomEvent('ShowErrors', {detail: messages}));
    }

    static success(message) {
        document.dispatchEvent(new CustomEvent('ShowSuccess', {detail: message}));
    }

    static message(title, message) {
        document.dispatchEvent(new CustomEvent('ShowMessage', {detail: {title, message}}));
    }

}
