import axios from "axios";
import defaultConfig from "@/modules/api/ApiDefaultConfig";
import ApiResult from "@/utils/ApiResult";

export default class {

    static apiPrefix = '/api/CreateTicket/'

    static async getOptions(draft) {
        const {data} = await axios.post(this.apiPrefix + 'Options', {draft: draft}, defaultConfig());
        return ApiResult.process(data);
    }

    static async getInfrastructureObjects() {
        const {data} = await axios.get(this.apiPrefix + 'InfrastructureObjects', defaultConfig());
        return ApiResult.process(data);
    }

    static async getServices() {
        const {data} = await axios.get(this.apiPrefix + 'Services', defaultConfig());
        return ApiResult.process(data);
    }

    static async getServiceById(id) {
        let config = defaultConfig();
        config.params = {filter: {id: [id]}};

        const {data} = await axios.get(this.apiPrefix + 'Services', config);
        return ApiResult.process(data);
    }

    static async getServiceOptions() {
        const {data} = await axios.get(this.apiPrefix + 'ManualServiceOptions', defaultConfig());
        return ApiResult.process(data);
    }

    static async getChannelById(id) {
        let config = defaultConfig();
        config.params = {filter: {id: [id]}};

        const {data} = await axios.get(this.apiPrefix + 'TvChannels', config);
        return ApiResult.process(data);
    }

    static async getInfrastructureObjectById(id) {
        let config = defaultConfig();
        config.params = {filter: {id: [id]}};

        const {data} = await axios.get(this.apiPrefix + 'InfrastructureObjects', config);
        return ApiResult.process(data);
    }

    static async saveDraft(draft) {
        let formData = draft.toFormData(), config = defaultConfig();

        config['Content-Type'] = 'multipart/form-data';
        formData.append('action', 'save');

        const {data} = await axios.post(this.apiPrefix + 'SaveDraft', formData, config);

        return ApiResult.process(data);
    }

    static async register(draft) {
        let formData = draft.toFormData(), config = defaultConfig();

        config['Content-Type'] = 'multipart/form-data';
        formData.append('action', 'register');

        const {data} = await axios.post(this.apiPrefix + 'SaveDraft', formData, config);
        return ApiResult.process(data);
    }

    static async getFieldPermissions() {
        const {data} = await axios.get(this.apiPrefix + 'FieldPermissions', defaultConfig());
        return ApiResult.process(data);
    }

    static async saveInfrastructureObject(fields) {
        const {data} = await axios.post(this.apiPrefix + 'CreateInfrastructureObject', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async getScenario() {
        const {data} = await axios.get(this.apiPrefix + 'TicketScenario', defaultConfig());
        return ApiResult.process(data);
    }

    static async getDraft(draftNumber) {
        const {data} = await axios.get(this.apiPrefix + 'Draft?draftNumber=' + draftNumber, defaultConfig());
        return ApiResult.process(data);
    }
}
