<template>
    <div class="modal modal-blur fade"
         :data-bs-keyboard="!isForced"
         :data-bs-backdrop="isForced? 'static' : true"
         :id="id"
         tabindex="-1"
         role="dialog"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" :class="`modal-${size}`" role="document">
            <div class="modal-content border border-light-subtle">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button v-if="!isForced" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div v-if="$slots.footer" class="modal-footer d-flex justify-content-center">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import PopupUtils from "@/utils/PopupUtils";

export default defineComponent({
    name: 'ModalPopup',
    props: {
        id: {
            type: String
        },
        title: {
            type: String
        },
        size: {
            type: String,
            default: '',
        },
        isForced: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {};
    },
    methods: {
        show() {
            PopupUtils.show(this.id);
        },
        hide() {
            PopupUtils.hide(this.id);
        },
    }
});
</script>

<style>
.show-forced {
    display: block !important;
}
</style>
