<template>
    <select class="form-select" v-model="value" :disabled="isDisabled">
        <option
            v-for="timeValue in calculateTimeUnit"
            :key="padStart(timeValue-1)"
            :value="padStart(timeValue-1)"
        >
            {{ padStart(timeValue - 1) }}
        </option>
    </select>
</template>

<script>

import {reactive, ref} from "vue";
import {padStart} from "@/components/utils/utilsMethods/dateMethods";

export default {
    name: 'TimeSelect',
    props: reactive({
        typeOfTime: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
        },
        isDisabled: ref(Boolean),
    }),
    emits: ['update:modelValue'],
    data() {

    },
    computed: {
        calculateTimeUnit() {
            return this.$props.typeOfTime === 'hours'? 24 : 60;
        },

        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    methods: {
        padStart,
    }
}
</script>

<style lang="scss" scoped>

</style>
