const appendObjectDeep = (formData, prefix, value) => {
    if (typeof value !== 'object') {
        formData.append(prefix, value);
        return formData;
    }

    for (let objKey in value) {
        formData = appendObjectDeep(formData, `${prefix}[${objKey}]`, value[objKey]);
    }

    return formData;
}

const toFormData = (data, rootName = '', objectKeys = {}, fileKeys = []) => {
    let formData = new FormData(), key, i, j, objKey, namePrefix;

    for (key in data) {
        if (typeof data[key] === 'undefined' || data[key] === null) {
            continue;
        }

        namePrefix = rootName ? `${rootName}[${key}]` : `${key}`;

        if (fileKeys.find((value) => value === key)) {
            for (i in data[key]) {
                formData.append(`${namePrefix}[${i}]`, data[key][i]);
            }
            continue;
        }

        if (objectKeys[key]) {
            for (i in data[key]) {
                for (j in objectKeys[key]) {
                    objKey = objectKeys[key][j];

                    formData.append(`${namePrefix}[${i}][${objKey}]`, data[key][i][objKey]);
                }
            }
            continue;
        }

        if (typeof data[key] === 'object') {
            formData = appendObjectDeep(formData, namePrefix, data[key]);
            continue;
        }

        formData.append(`${namePrefix}`, data[key]);
    }

    return formData;
}

export {toFormData};
