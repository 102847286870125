<template>
    <ModalPopup :id="popupsId.FUNCTION_ASSIGN_BACK"
                :is-forced="true"
                title="Возврат ТТ поставщику">

        <div class="row mb-4 mt-3">
            <div class="col d-flex justify-content-center">
                <div>
                    Подтвердите факт возврата ТТ поставщику
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div @click="assignBack" class="btn">Вернуть и выйти</div>
                    <div class="btn" @click="finish" data-bs-dismiss="modal">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_ASSIGN_BACK" popup-title="Подтверждение возврата поставщику">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="assignBack">Всё равно вернуть ТТ</div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>

</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as popupsId from '../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import {mapGetters} from "vuex";

export default defineComponent({
    name: 'FunctionAssignBack',
    components: {ConfirmPopup, ModalPopup},
    props: {
    },
    emits: ['assignBack'],
    data() {
        return {
            popupsId: popupsId,
            confirmPopupText: '',
            lastTryDate: null, // Относится к обоим попапам
        };
    },
    computed: {
        ...mapGetters('detailTicketPage', [
            'lastPopup',
        ]),
    },
    mounted() {
        // Если в прошлый раз один из попапов остался незакрытым, открывает его
        if (this.lastPopup.getId() === popupsId.FUNCTION_ASSIGN_BACK) {
            this.showFunction(
                this.lastPopup.getData().date
            );
        } else if (this.lastPopup.getId() === popupsId.CONFIRM_ASSIGN_BACK) {
            this.showConfirm(
                this.lastPopup.getData().text,
                this.lastPopup.getData().date
            );
        }
    },
    methods: {
        assignBack() {
            // Закрываем все попапы и очищаем данные
            const data = {date: this.lastTryDate};

            this.hideFunction();
            this.hideConfirm();
            this.$emit('assignBack', data);
        },

        showFunction(date) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideConfirm();

            // Данные для этого попапа
            this.lastTryDate = date;

            PopupUtils.show(popupsId.FUNCTION_ASSIGN_BACK);

            // Запомним последний открытый попап и его данные
            this.saveFunctionPopupState();
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_ASSIGN_BACK);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.FUNCTION_ASSIGN_BACK) {
                this.lastPopup.close();
            }

            // Очистить его данные (но всё, кроме даты, нужно для попапа с подверждением, поэтому не трогаем)
            this.lastTryDate = null;
        },
        showConfirm(message, date) {
            this.hideFunction();

            // Данные для попапа
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе.
            this.lastTryDate = date;
            PopupUtils.show(popupsId.CONFIRM_ASSIGN_BACK);

            // Запомним последний открытый попап и его данные
            this.saveConfirmPopupState();
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_ASSIGN_BACK);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.CONFIRM_ASSIGN_BACK) {
                this.lastPopup.close();
            }

            // Очистить его данные
            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideFunction();
        },
        saveFunctionPopupState() {
            this.lastPopup.open(popupsId.FUNCTION_ASSIGN_BACK, {
                date: this.lastTryDate
            });
        },
        saveConfirmPopupState() {
            this.lastPopup.open(popupsId.CONFIRM_ASSIGN_BACK, {
                text: this.confirmPopupText,
                date: this.lastTryDate,
            });
        }
    }
});
</script>
