<template>

    <a v-if="!showTextEditor && !text" @click="showTextEditor=true"
       href="javascript:void(0)">Нажмите, чтобы ввести {{ description }}</a>
    <div v-if="!showTextEditor && text" @click="showTextEditor=true"
         class="me-auto" :class="{'multiline': multiline}">{{ text }}
    </div>
    <textarea v-if="showTextEditor && multiline"
              @focusout="this.showTextEditor = false; $emit('update:text', $event.target.value)"
              :value="text"
              class="form-control" rows="10"></textarea>
    <input v-else-if="showTextEditor"
           @focusout="this.showTextEditor = false; $emit('update:text', $event.target.value)"
           :value="text" class="form-control"
           type="text">

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'TextUnderLink',
    components: {},
    emits: ['update:text'],
    props: {
        text: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        multiline: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showTextEditor: false
        };
    },
    methods: {
    },
});
</script>
<style scoped>
.multiline {
    white-space: pre-line;
}
</style>
