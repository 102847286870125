<template>
    <div class="row g-1">
        <div class="col">
            <div class="input-icon">
                <datepicker-lite
                    v-if="typeOfDateRange === 'min'"
                    :id-attr="idDatePicker"
                    :value-attr="dateObj.day"
                    :class-attr="'form-control js-datepicker'"
                    :to="stringToDateObj(dateLimitString).day"
                    placeholder-attr="Не выбрано"
                    :locale="datepickerLocale"
                    :show-bottom-button="false"
                    :disable-input="isDisabledField"
                    @value-changed="(value) => setDay(value)"
                />

                <datepicker-lite
                    v-else
                    :id-attr="idDatePicker"
                    :value-attr="dateObj.day"
                    :class-attr="'form-control js-datepicker'"
                    :from="stringToDateObj(dateLimitString).day"
                    placeholder-attr="Не выбрано"
                    :locale="datepickerLocale"
                    :show-bottom-button="false"
                    :disable-input="isDisabledField"
                    @value-changed="(value) => setDay(value)"
                />
                <span class="input-icon-addon">
                    <i class="ti ti-calendar fs-2"></i>
                </span>
            </div>
        </div>
        <div class="col-auto">
            <TimeSelect
                v-model="dateObj.hours"
                :is-disabled="isDisabledField"
                typeOfTime="hours"
            />
        </div>
        <div class="col-auto align-items-center d-flex">
            :
        </div>
        <div class="col-auto">
            <TimeSelect
                v-model="dateObj.minutes"
                :is-disabled="isDisabledField"
                typeOfTime="minutes"
            />
        </div>
    </div>
</template>

<script>
import DatepickerLite from 'vue3-datepicker-lite';
import TimeSelect from "@/components/utils/TimeSelect.vue";
import {reactive, ref} from "vue";

export default {
    components: {DatepickerLite, TimeSelect},
    props: {
        idDatePicker: String,
        isDisabledField:  ref(Boolean),
        dateString: String,
        typeOfDateRange: 'min' || 'max',
        dateLimitString: String,
    },
    data() {
        return {
            selectedDay: '',
            dateObj: reactive({
                day: '',
                hours: '',
                minutes: '',
            }),
            resultDateString: '',
            datepickerLocale: {
                format: 'YYYY-MM-DD',
                weekday: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
                months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                startsWeeks: 1,
                todayBtn: "",
                clearBtn: "",
                closeBtn: "",
            },
        }
    },
    emits: ['changeDate'],
    mounted() {
        this.initDateObj();
    },
    methods: {
        initDateObj () {
            this.dateObj = this.stringToDateObj(this.dateString);
        },
        stringToDateObj (dateString) {
            return {
                day: dateString.slice(0, 10),
                hours: dateString.slice(11, 13),
                minutes: dateString.slice(14, 16),
            }
        },
        dateToString(dateObj) {
            return `${dateObj.day} ${dateObj.hours}:${dateObj.minutes}`;
        },
        setDay (value) {
            this.dateObj.day = value;
        },
    },
    watch: {
        'dateString': function() {
            this.initDateObj();
        },
        'dateObj': {
            handler: function () {
                this.resultDateString = this.dateToString(this.dateObj);
                this.$emit('changeDate', this.resultDateString);
            },
            deep:true
        }
    },

}
</script>

<style lang="scss" scoped>

</style>
