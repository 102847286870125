<template>
    <ModalPopup :id="id"
                title="Смена приоритета"
                ref="modal"
                :is-forced="true">
        <div class="list-group list-group-flush list-group-hoverable card">
            <div v-for="priority in priorities" :key="priority.code"
                 class="list-group-item p-2"
                 :class="{'bg-blue-lt': priority.code===currentPriority?.code}"
                 @click="selectPriority(priority)"
                 @dblclick="choosePriority(priority)"
            >
                <div class="row align-items-center">
                    <div class="col text-truncate ps-3">
                        {{ priority.title }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="needReason" class="row mb-3">
            <div class="col">
                <label class="form-label required">Причина смены приоритета</label>
                <textarea :value="reason" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center mt-3">
                <div class="btn-list">
                    <div @click="choosePriority(null)" class="btn">Выбрать</div>
                    <div class="btn" data-bs-dismiss="modal">Выйти без выбора</div>
                </div>
            </div>
        </div>
    </ModalPopup>

</template>

<script>
import {defineComponent} from 'vue';
import Notification from "@/utils/Notification";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";

export default defineComponent({
    name: 'TicketPriority',
    components: {ModalPopup},
    props: {
        priorities: {
            type: Array
        },
        initialPriority: {
            type: Object,
        },
        needReason: {
            type: Boolean,
            default: true
        },
        id: {
            type: String
        }
    },
    emits: ['choosePriority'],
    mounted() {
        this.setInitialPriority();
    },
    data() {
        return {
            reason: "",
            currentPriority: ""
        };
    },
    methods: {
        choosePriority(priority) {
            if (priority) {
                this.selectPriority(priority);
            }

            if (this.currentPriority && getObjectByCode(this.priorities, this.currentPriority.code)
                && this.validate()) {
                this.$emit('choosePriority', this.currentPriority, this.reason);
                this.$refs.modal.hide();
            }
        },

        selectPriority(priority) {
            this.currentPriority = priority;
        },

        validate() {
            if (this.needReason && !this.reason) {
                Notification.error('Заполните причину смены приоритета');
                return false;
            }

            return true;
        },

        setInitialPriority() {
            if (this.initialPriority) {
                const priority = getObjectByCode(this.priorities, this.initialPriority.code);
                if (priority) {
                    this.currentPriority = priority;
                    return;
                }
            }

            this.currentPriority = this.priorities[0];
        }
    },
    watch: {
        'initialPriority': function () {
            this.setInitialPriority();
        }
    }
});
</script>
