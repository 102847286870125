import {LOCAL_STORAGE_LAST_POPUP_POSTFIX} from "@/modules/constants/LocalStorage";

export default class DetailTicketLastPopup {
    constructor(ticketNumber) {
        this.ticketNumber = ticketNumber;
        this.__init(this.__getData());
    }

    getId() {
        return this.id;
    }

    getData() {
        return this.data;
    }

    open(id, data = {}) {
        this.id = id;
        this.data = data;

        this.__saveData();
    }

    setData(data) {
        this.data = data;

        this.__saveData();
    }

    close() {
        this.__init(null);
        this.__saveData();
    }

    delete() {
        localStorage.removeItem(this.__getKey());
    }

    isExpired() {
        return !this?.date || this.date < Date.now() - 3 * 24 * 3600 * 1000; // миллисекунды
    }

    __init(data) {
        this.id = data?.popupId;
        this.data = data?.data;
        this.date = data?.date;
    }

    __getData() {
        return JSON.parse(localStorage.getItem(this.__getKey()));
    }

    __saveData() {
        if (this.id === null) {
            localStorage.removeItem(this.__getKey());
        }

        localStorage.setItem(this.__getKey(), JSON.stringify({
            date: Date.now(),
            popupId: this.id,
            data: this.data
        }));
    }

    __getKey() {
        return this.ticketNumber + LOCAL_STORAGE_LAST_POPUP_POSTFIX;
    }

}
