import TicketFunctions from "@/modules/api/TicketFunctions";

export default {
    namespaced: true,
    actions: {
        addCommentForCustomer: async (context, payload) => {
            return await TicketFunctions.addCommentForCustomer(payload);
        },

        addCommentForAll: async (context, payload) => {
            return await TicketFunctions.addCommentForAll(payload);
        },

        takeIncidentAssigned: async (context, payload) => {
            return await TicketFunctions.takeIncidentAssigned(payload);
        },

        changeTicketPriority: async (context, payload) => {
            return await TicketFunctions.changeTicketPriority(payload);
        },

        cancelIncident: async (context, payload) => {
            return await TicketFunctions.cancelIncident(payload);
        },

        setAssignee: async (context, payload) => {
            return await TicketFunctions.setAssignee(payload);
        },

        assignBack: async (context, payload) => {
            return await TicketFunctions.assignBack(payload);
        },

        confirmRecovery: async (context, payload) => {
            return await TicketFunctions.confirmRecovery(payload);
        },

        close: async (context, payload) => {
            return await TicketFunctions.close(payload);
        },

        changeFields: async (context, payload) => {
            return await TicketFunctions.changeFields(payload);
        },
    }
}
