import Notification from "@/utils/Notification";

export default class ComponentErrors {

    errors = [];

    constructor(validations) {
        for (let index in validations) {
            if (!Object.prototype.hasOwnProperty.call(validations, index) || !validations[index][0]) {
                continue;
            }

            this.errors.push(validations[index][1]);
        }
    }

    notEmpty() {
        return this.errors.length > 0;
    }

    show() {
        if (this.notEmpty()) {
            Notification.errors(this.errors);
            return true;
        }

        return false;
    }

    static execute(validations) {
        return !(new ComponentErrors(validations)).show();
    }

}
