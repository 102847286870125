<template>
    <ConfirmPopup :popup-id="popupsId.CONFIRM_DELETING_SUBSCRIBER_POPUP"
                  popup-title="Подтверждение удаления дополнительного подписчика">
        <div class="mb-2">Подтвердите удаление дополнительного подписчика:</div>
        <div v-if="subscriber" class="mb-2">
            {{ subscriber.name }}
            <span v-if="subscriber.description">
                - {{ subscriber.description }}
            </span>
            <span v-if="subscriber.email">
                ({{ subscriber.email }})
            </span>
        </div>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="deleteSubscriber">Подтвердить</div>
            <div class="btn" @click.prevent="finish">Выйти без изменений</div>
        </template>
    </ConfirmPopup>
</template>

<script>
import * as popupsId from '../../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";

export default {
    name: 'DeleteSubscriber',
    components: {ConfirmPopup},
    props: {},
    data() {
        return {
            popupsId: popupsId,
            subscriber: null,
        }
    },
    emits: ['deleteSubscriber'],
    methods: {
        show(subscriber) {
            this.subscriber = subscriber;
            PopupUtils.show(this.popupsId.CONFIRM_DELETING_SUBSCRIBER_POPUP);
        },
        hide() {
            PopupUtils.hide(this.popupsId.CONFIRM_DELETING_SUBSCRIBER_POPUP);
        },
        finish() {
            this.hide();
        },
        deleteSubscriber() {
            this.hide();

            this.$emit('deleteSubscriber', this.subscriber);
        },
    },
}
</script>
