<template>
    <input type="text" class="form-control" :placeholder="placeholder" v-model="searchQuery" @input="search">
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'FrontendSearch',
    props: {
        items: {
            type: Array
        },
        searchableFields: {
            type: Object
        },
        placeholder: {
            type: String,
            default: "Поиск"
        }
    },
    emits: ['filtered'],
    data() {
        return {
            searchQuery: ''
        };
    },
    methods: {
        searchInObject(object, fieldName) {
            return Object.prototype.hasOwnProperty.call(object, fieldName) &&
                object[fieldName] &&
                object[fieldName].toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1;
        },

        emitFiltered(items) {
            this.$emit('filtered', items);
        },

        searchInFirstLevel(item, searchableFields) {
            let itemFieldName;

            for (let nameIndex in searchableFields) {
                if (!Object.prototype.hasOwnProperty.call(searchableFields, nameIndex)) {
                    continue;
                }

                itemFieldName = searchableFields[nameIndex];
                if (typeof itemFieldName === 'object') {
                    continue;
                }

                if (this.searchInObject(item, itemFieldName)) {
                    return true;
                }
            }

            return false;
        },

        filter(items, fieldNames) {
            let filtered = [], item;

            if (!items) {
                return filtered;
            }

            for (let index in items) {
                if (!Object.prototype.hasOwnProperty.call(items, index)) {
                    continue;
                }
                item = items[index];

                if (this.searchInFirstLevel(item, fieldNames)) {
                    filtered.push(item);
                }
            }

            return filtered;
        },

        search() {
            let result = [], item, itemCopy, childrenFieldNames, filteredChildren;

            if (!this.searchQuery.length) {
                this.emitFiltered(this.items);
                return;
            }

            for (let index in this.items) {
                if (!Object.prototype.hasOwnProperty.call(this.items, index)) {
                    continue;
                }
                item = this.items[index];

                // Сначала ищем в полях первого уровня
                if (this.searchInFirstLevel(item, this.searchableFields)) {
                    result.push(Object.assign({}, item));
                    continue;
                }

                // Теперь ищем во вложенных объектах
                // Сделана обработка только полей второго уровня вложенности
                for (let childrenKey in this.searchableFields) {
                    if (!Object.prototype.hasOwnProperty.call(this.searchableFields, childrenKey)) {
                        continue;
                    }

                    childrenFieldNames = this.searchableFields[childrenKey];
                    if (typeof childrenFieldNames !== 'object' ||
                        !Object.prototype.hasOwnProperty.call(item, childrenKey)) {
                        continue;
                    }

                    filteredChildren = this.filter(item[childrenKey], childrenFieldNames);

                    if (filteredChildren.length) {
                        itemCopy = Object.assign({}, item)
                        itemCopy[childrenKey] = filteredChildren;
                        result.push(itemCopy);
                    }
                }
            }

            this.emitFiltered(result);
        }
    }
});
</script>
