<template>
    <div class="row">
        <div class="col">
            <div v-if="detailTicketPermissionsList && detailTicketPageInfo" class="card">
                <div class="card-header d-print-none">
                    <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
                        <li
                            v-for="tab in currentTabsList"
                            :key="tab"
                            class="nav-item"
                            role="presentation"
                        >
                            <a :href="`#tab-${tab}`"
                               class="nav-link"
                               :class="{'active': tab === activeTab}"
                               data-bs-toggle="tab"
                               aria-selected="true"
                               role="tab"
                               @click="setActiveTab(tab)"
                            >
                                {{ tabs[tab]?.title }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            v-for="tab in currentTabsList"
                            :key="tab"
                            class="tab-pane"
                            :class="{'active show' : activeTab === tab}"
                            :id="`tab-${tab}`"
                            role="tabpanel"
                        >
                            <div class="row mb-3 ticket-fixed-zone d-print-none">
                                <div v-if="fieldPermissions.canRead(fields.NAME)" class="col-2">
                                    Номер ТТ: <b>{{ detailTicketPageInfo.state.number }}</b>
                                </div>
                                <div v-if="fieldPermissions.canRead(fields.PRIORITY)" class="col-2">
                                    Приоритет: <b>{{ detailTicketPageInfo.state.priority.title }}</b>
                                </div>
                                <div v-if="fieldPermissions.canRead(fields.REGISTER_DATE)" class="col-3">
                                    Дата регистрации: <b><a href="#">{{ formatDate(detailTicketPageInfo.state.registerDate) }}</a></b>
                                </div>
                                <div v-if="fieldPermissions.canRead(fields.WORK_STATE)" class="col-5">
                                    Состояние работ: <b>{{ detailTicketPageInfo.state.workState.title }}</b>
                                </div>
                            </div>

                            <component
                                :is="tabs[tab]?.componentName"
                                :permissions="detailTicketPermissionsList"
                                :state="detailTicketPageInfo.state"
                                :ticket-number="ticketNumber"
                                :add-comment="$refs.addComment"
                                @reload-page-data="($event) => reload($event)"
                                @reload-permissions="reloadPermissions"
                                @show-add-comment-for-all="$refs.addComment.showAddCommentForAll()"
                                @show-add-comment-for-customer="$refs.addComment.showAddCommentForCustomer()"
                            ></component>
                        </div>
                    </div>
                </div>

                <AddCommentController
                    ref="addComment"
                    :ticket-number="ticketNumber"
                    :state="detailTicketPageInfo.state"
                    :permissions="detailTicketPermissionsList"
                    @reload-page-data="() => reload()"
                />

            </div>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MainTab from "@/components/ticket/detailTicket/tabs/MainTab.vue";
import AllMessagesTab from "@/components/ticket/detailTicket/tabs/AllMessagesTab.vue";
import UserCommentsTab from "@/components/ticket/detailTicket/tabs/UserCommentsTab.vue";
import HistoryTab from "@/components/ticket/detailTicket/tabs/HistoryTab.vue";
import SubscriptionTab from "@/components/ticket/detailTicket/tabs/SubscriptionTab.vue";
import {formatDate} from "@/components/utils/utilsMethods/dateMethods";
import * as fields from "@/modules/constants/DetailTicketMainTabFields";
import TicketFieldPermissions from "@/modules/TicketFieldPermissions";
import {FUNCTION_POPUP_PERMISSIONS} from "@/components/utils/constants/popupsId";
import FunctionAddComment from "@/components/ticket/functions/FunctionAddComment.vue";
import AddCommentController from "@/components/ticket/detailTicket/AddCommentController.vue";
import Notification from "@/utils/Notification";
import TicketHeader from "@/components/ticket/detailTicket/TicketHeader.vue";

export default {
    name: 'DetailTicketPage',
    components: {
        AddCommentController,
        FunctionAddComment, MainTab, AllMessagesTab, UserCommentsTab, HistoryTab, SubscriptionTab},
    props: {
        ticketNumber: {
            type: String,
            default: '',
        },
        header: {
            type: Object,
        }
    },
    data() {
        return {
            tabs: {
                main: {
                    title: 'Инцидент',
                    componentName: 'MainTab',
                    stateName: 'main',
                },
                allMessages: {
                    title: 'Все сообщения',
                    componentName: 'AllMessagesTab',
                    stateName: 'messages',
                },
                userComments: {
                    title: 'Комментарии пользователей',
                    componentName: 'UserCommentsTab',
                    stateName: 'comments',
                },
                history: {
                    title: 'История изменений',
                    componentName: 'HistoryTab',
                    stateName: 'history',
                },
                subscription: {
                    title: 'Подписка',
                    componentName: 'SubscriptionTab',
                    stateName: 'subscription',
                },
            },
            currentTabsList: [],
            activeTab: '',
            fields: fields,
        }
    },
    methods: {
        ...mapActions('detailTicketPage', [
            'getDetailTicketPermissionsList',
            'getDetailTicketPageInfo',
            'initDetailTicketLastPopup',
            'initDetailTicketLastTab',
        ]),

        sortTabsList(resultList) {
            const referenceList = Object.keys(this.tabs)

            resultList.sort((a, b) => {
                return referenceList.indexOf(a) - referenceList.indexOf(b);
            });
        },

        blockNotAllowedPopup() {
            if (this.lastPopup.getId() && this.detailTicketPermissionsList) {
                let notAllowed = false;
                for (let functionName in FUNCTION_POPUP_PERMISSIONS) {
                    if (FUNCTION_POPUP_PERMISSIONS[functionName].includes(this.lastPopup.getId()) &&
                        !this.detailTicketPermissionsList.functions.includes(functionName)
                    ) {
                        notAllowed = true;
                        break;
                    }
                }

                if (notAllowed) {
                    this.lastPopup.close();
                }
            }
        },

        initActiveTab() {
            const lastTab = this.lastTab.getTab();
            if (lastTab && this.currentTabsList.includes(lastTab)) {
                this.activeTab = lastTab;
                return;
            }

            this.activeTab = this.currentTabsList.includes('main') ? 'main' : this.currentTabsList[0];
        },

        setActiveTab(tab) {
            this.activeTab = tab;
        },

        async reload(data) {
            await this.getDetailTicketPermissionsList({ticketNumber: this.ticketNumber});
            await this.getDetailTicketPageInfo({
                ticketNumber: this.ticketNumber,
                requireFresh: data?.requireFresh || false
            });

            this.blockNotAllowedPopup();
        },

        async reloadPermissions(changedFields) {
            await this.getDetailTicketPermissionsList({ticketNumber: this.ticketNumber, changedFields: changedFields});
        },

        formatDate: formatDate,
    },
    computed: {
        ...mapGetters('detailTicketPage', [
            'detailTicketPageInfo',
            'detailTicketPermissionsList',
            'lastPopup',
            'lastTab',
        ]),
        fieldPermissions() {
            if (this.detailTicketPermissionsList?.fields) {
                return new TicketFieldPermissions(this.detailTicketPermissionsList.fields)
            }

            return null;
        },
    },
    async mounted() {
        await this.initDetailTicketLastPopup(this.ticketNumber);
        await this.initDetailTicketLastTab(this.ticketNumber);
        await this.reload();

        this.initActiveTab();
    },
    watch: {
        'detailTicketPermissionsList': function() {
            this.currentTabsList = this.detailTicketPermissionsList.tabs;
            this.sortTabsList(this.currentTabsList);
        },
        'currentTabsList': function (previousTabsList) {
            if (this.currentTabsList.toString() !== previousTabsList.toString()) {
                this.initActiveTab();
            }
        },
        'detailTicketPageInfo.state.previousSolutionControlDateExpiredNotification': function () {
            if (this.detailTicketPageInfo.state.previousSolutionControlDateExpiredNotification) {
                Notification.message('Внимание!', 'Просрочено контрольное время');
            }
        },
        'detailTicketPageInfo.state.isMaster': function (newValue) {
            if (!this.header || this.header?.$options?.name !== TicketHeader.name) {
                return;
            }

            if (newValue) {
                this.header.setMaster();
            } else {
                this.header.unsetMaster();
            }
        },
        'detailTicketPageInfo.state.master': function (newValue) {
            if (!this.header || this.header?.$options?.name !== TicketHeader.name) {
                return;
            }

            if (newValue) {
                this.header.setSlave();
            } else {
                this.header.unsetSlave();
            }
        },
        'activeTab': function (newValue) {
            this.lastTab.set(newValue);
        }
    },
}
</script>
<style>
.ticket-fixed-zone {
    background-color: #fff;
}

.ticket-dynamic-zone {
    background-color: #eff2f5;
}

.ticket-initials-zone {
    background-color: #dae0e6;
}
</style>
