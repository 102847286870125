<template>
    <ModalPopup :id="id"
                title="Условия отбора учётных записей"
                size="lg">
        <div v-if="filterInit" class="divide-y">
            <div>
                <div class="mb-3">
                    <label class="form-label">Номер ТТ</label>
                    <div class="row g-2">
                        <div class="col">
                            <select type="text" class="form-select js-select-search" v-model="selectedTicketFilters.ticketNumber">
                                <option value="0">Не выбрано</option>
                                <option v-for="(ticketNumberValue, index) in filterInit.lists.ticketNumber" :key="index" :value="ticketNumberValue">
                                    {{ ticketNumberValue }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn px-2"
                                :disabled="selectedTicketFilters.ticketNumber === '0'"
                                @click="resetSelect('ticketNumber')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Дата регистрации ТТ</label>
                    <div class="row g-2">
                        <div class="col">
                            <date-select
                                :date-string="selectedTicketFilters.ticketRegistrationDate.min"
                                id-date-picker="ticket-registration-date-min"
                                type-of-date-range="min"
                                :is-disabled-field="isAllDisabled"
                                :date-limit-string="selectedTicketFilters.ticketRegistrationDate.max"
                                @change-date="value => selectedTicketFilters.ticketRegistrationDate.min = value"
                            />
                        </div>
                        <div class="col-auto align-items-center d-flex">
                            -
                        </div>
                        <div class="col">
                            <date-select
                                :date-string="selectedTicketFilters.ticketRegistrationDate.max"
                                id-date-picker="ticket-registration-date-max"
                                type-of-date-range="max"
                                :is-disabled-field="isAllDisabled"
                                :date-limit-string="selectedTicketFilters.ticketRegistrationDate.min"
                                @change-date="value => selectedTicketFilters.ticketRegistrationDate.max = value"
                            />
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn px-2"
                                @click="setDefaultDate('ticketRegistrationDate', filterInit.default.ticketRegistrationDate)"
                                :disabled="isAllDisabled"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Дата восстановления услуги</label>
                    <div class="row g-2">
                        <div class="col">
                            <date-select
                                :date-string="selectedTicketFilters.subjectRecoveryDate.min"
                                :is-disabled-field="isAllDisabled"
                                id-date-picker="service-restoration-date-min"
                                type-of-date-range="min"
                                :date-limit-string="selectedTicketFilters.subjectRecoveryDate.max"
                                @change-date="value => selectedTicketFilters.subjectRecoveryDate.min = value"
                            />
                        </div>

                        <div class="col-auto align-items-center d-flex">
                            -
                        </div>

                        <div class="col">
                            <date-select
                                :date-string="selectedTicketFilters.subjectRecoveryDate.max"
                                id-date-picker="ticket-registration-date-max"
                                :is-disabled-field="isAllDisabled"
                                type-of-date-range="max"
                                :date-limit-string="selectedTicketFilters.subjectRecoveryDate.min"
                                @change-date="value => selectedTicketFilters.subjectRecoveryDate.max = value"
                            />
                        </div>

                        <div class="col-auto">
                            <button
                                class="btn px-2"
                                @click="setDefaultDate('subjectRecoveryDate', filterInit.default.subjectRecoveryDate)"
                                :disabled="isAllDisabled"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-label">Приоритет</div>
                    <div>
                        <label
                            v-for="proiorityValue in filterInit.lists.priority"
                            :key="proiorityValue.code"
                            class="form-check form-check-inline"
                        >
                            <input
                                class="form-check-input" type="checkbox"
                                :disabled="isAllDisabled"
                                :value="proiorityValue.code"
                                v-model="selectedTicketFilters.priority"
                            >
                            <span class="form-check-label">{{ proiorityValue.title }}</span>
                        </label>
                    </div>
                </div>
                <div>
                    <label class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" v-model="selectedTicketFilters.includeClosed"
                               :disabled="isAllDisabled || isIncludeClosedDisabled">
                        <span class="form-check-label">{{ filterInit.texts.includeClosed || 'Включая закрытые' }}</span>
                    </label>
                </div>
                <div>
                    <label class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" v-model="selectedTicketFilters.onlyExpiredTargetTime" :disabled="isAllDisabled">
                        <span class="form-check-label">Только с просроченным ЦВР</span>
                    </label>
                </div>
            </div>

            <div>
                <div class="mb-3">
                    <label class="form-label">Тип объекта влияния</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                :disabled="isAllDisabled"
                                v-model="selectedTicketFilters.subjectType"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="subjectTypeValue in filterInit.lists.subjectType"
                                    :key="subjectTypeValue.code"
                                    :value="subjectTypeValue.code"
                                >
                                    {{ subjectTypeValue.title }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.subjectType === '0' || isAllDisabled"
                                @click="resetSelect('subjectType')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Владелец объекта влияния</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                :disabled="selectedTicketFilters.subjectType === '0' || isAllDisabled"
                                v-model="selectedTicketFilters.subjectSupplier"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="subjectTypeSupplier in subjectSuppliers"
                                    :key="subjectTypeSupplier.supplier.id"
                                    :value="subjectTypeSupplier.supplier.id"
                                >{{ subjectTypeSupplier.supplier.title }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.subjectType === '0' || selectedTicketFilters.subjectSupplier === '0' || isAllDisabled"
                                @click="resetSelect('subjectSupplier')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="showCustomers" class="mb-3">
                    <label class="form-label">Заказчик объекта влияния</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                :disabled="selectedTicketFilters.subjectSupplier === '0' || isAllDisabled"
                                v-model="selectedTicketFilters.subjectCustomer"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="subjectCustomer in subjectCustomers"
                                    :key="subjectCustomer.customer.id"
                                    :value="subjectCustomer.customer.id"
                                >{{ subjectCustomer.customer.title }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.subjectSupplier === '0' || selectedTicketFilters.subjectCustomer === '0' || isAllDisabled"
                                @click="resetSelect('subjectCustomer')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Объект влияния</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                :disabled="selectedTicketFilters.subjectType === '0' || selectedTicketFilters.subjectSupplier === '0' || (showCustomers && selectedTicketFilters.subjectCustomer === '0') || isAllDisabled"
                                v-model="selectedTicketFilters.subject"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="subject in subjects"
                                    :key="subject.id"
                                    :value="subject.id"
                                >{{ subject.title }}</option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.subjectType === '0' || selectedTicketFilters.subjectSupplier === '0' || (showCustomers && selectedTicketFilters.subjectCustomer === '0') || selectedTicketFilters.subject === '0' || isAllDisabled"
                                @click="resetSelect('subject')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="tvChannels.length && !isAllDisabled" class="mb-3">
                    <label class="form-label">Телеканал</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                v-model="selectedTicketFilters.tvChannel"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="tvChannel in tvChannels"
                                    :key="tvChannel.id"
                                    :value="tvChannel.id"
                                >{{ tvChannel.title }}</option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.tvChannel === '0' || isAllDisabled"
                                @click="resetSelect('tvChannel')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="mb-3">
                    <label class="form-label">Компания участника решения</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                v-model="selectedTicketFilters.memberCompany"
                                :disabled="isAllDisabled"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="memberGroup in filterInit.lists.memberGroup"
                                    :key="memberGroup.company.id"
                                    :value="memberGroup.company.id"
                                >
                                    {{ memberGroup.company.title }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.memberCompany === '0' || isAllDisabled"
                                @click="resetSelect('memberCompany')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Группа участника решения</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                v-model="selectedTicketFilters.memberGroup"
                                :disabled="!+selectedTicketFilters.memberCompany || isAllDisabled"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="memberGroup in memberGroups"
                                    :key="memberGroup.code"
                                    :value="memberGroup.code"
                                >
                                    {{ memberGroup.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.memberGroup === '0' || isAllDisabled"
                                @click="resetSelect('memberGroup')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3"
                     v-if="filterInit.lists.memberGroup.length">
                    <label class="form-label">Участник решения</label>
                    <div class="row g-2">
                        <div class="col">
                            <select
                                type="text"
                                class="form-select js-select-search"
                                v-model="selectedTicketFilters.member"
                                :disabled="!+selectedTicketFilters.memberGroup || isAllDisabled"
                            >
                                <option value="0">Не выбрано</option>
                                <option
                                    v-for="member in members"
                                    :key="member.code"
                                    :value="member.code"
                                >
                                    {{ member.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-outline-danger px-2"
                                :disabled="selectedTicketFilters.memberGroup === '0' || selectedTicketFilters.member === '0' || isAllDisabled"
                                @click="resetSelect('member')"
                            >
                                <i class="ti ti-x fs-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-slot:footer>
            <div class="btn-list">
                <div class="btn btn-primary" @click.prevent="submitTicketFilterForm">Применить фильтр</div>
                <div class="btn btn-danger" @click="cancelTicketFilterForm">Отменить</div>
            </div>
        </template>
    </ModalPopup>
</template>

<script>

import {reactive, ref} from "vue";
import {mapActions, mapGetters} from 'vuex';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import DateSelect from "@/components/utils/DateSelect.vue";
import PopupUtils from "@/utils/PopupUtils";
import _ from 'lodash';

export default {
    name: 'TicketFilter',
    components: {ModalPopup, DateSelect},
    props: {
        page: {type: String}
    },
    data() {
        return {
            id: 'ticket-filter-popup',
            isDefaultValuesSettled: reactive({
                subject: false,
                member: false,
            }),
            isAllDisabled: ref(false),
            selectedTicketFilters: reactive({
                ticketNumber: '0',
                ticketRegistrationDate: {
                    min: '',
                    max: '',
                },
                subjectRecoveryDate: {
                    min: '',
                    max: '',
                },
                priority: [],
                includeClosed: false,
                onlyExpiredTargetTime: false,
                subjectType: '0',
                subjectSupplier: '0',
                subjectCustomer: '0',
                subject: '0',
                tvChannel: '0',
                memberCompany: '0',
                memberGroup: '0',
                member: '0',
            })
        }
    },
    methods: {
        ...mapActions('ticketFilter', [
            'getFilterInit',
            'applyTicketFilter',
        ]),

         async run() {
             if (!this.filterInit) {
                 await this.getFilterInit();
             }
            PopupUtils.show(this.id);
        },

        resetSelect(modelValue) {
            this.selectedTicketFilters[modelValue] = '0';
        },

        cancelTicketFilterForm() {
            PopupUtils.hide(this.id);
        },

        async applyCurrentTicketFilter(data) {
            const result = await this.applyTicketFilter(data);
            if (result.isSuccess()) {
                PopupUtils.hide(this.id);
                //редирект на другую страницу при успешной отправке формы
                window.location.href = result.getData()["redirectTo"];
            }
        },

        setDefaultDate(dateNameObj, defaultDateObj) {
            this.selectedTicketFilters[dateNameObj].min = defaultDateObj.min;
            this.selectedTicketFilters[dateNameObj].max = defaultDateObj.max;
        },

        setDefaultSelectedData(dataObj) {
            this.selectedTicketFilters = Object.assign(this.selectedTicketFilters, _.cloneDeep(dataObj));

            if (dataObj.subject) this.isDefaultValuesSettled.subject = true;
            if (dataObj.member) this.isDefaultValuesSettled.member = true;
        },

        transformFilterDataToSubmittingFormat() {
            if (this.selectedTicketFilters.ticketNumber !== '0') {
                // Если выбран номер тикета, то остальные поля не учитываются
                return {ticketNumber: this.selectedTicketFilters.ticketNumber, page: this.page};
            }

            let filters = {page: this.page}, clearedMin, clearedMax;
            for (let key in this.selectedTicketFilters) {
                if (this.selectedTicketFilters[key] === '0') {
                    continue;
                }

                if (this.selectedTicketFilters[key]?.min && this.selectedTicketFilters[key]?.max) {
                    clearedMin = this.fixDate(this.selectedTicketFilters[key].min);
                    clearedMax = this.fixDate(this.selectedTicketFilters[key].max);

                    if (clearedMin || clearedMax) {
                        filters[key] = {};
                        if (clearedMin) {
                            filters[key].min = clearedMin;
                        }
                        if (clearedMax) {
                            filters[key].max = clearedMax;
                        }
                    }

                    continue;
                }

                filters[key] = this.selectedTicketFilters[key];
            }

            return filters;
        },

        submitTicketFilterForm() {
            //Преобразование данных для отправки
            const resultFiltersData = this.transformFilterDataToSubmittingFormat();

            this.applyCurrentTicketFilter(resultFiltersData);
        },

        fixDate(dateString) {
            const clearedDate = dateString.replace(/[^\d]/g, ""),
                hour = clearedDate.substring(8, 10) || '00',
                minute = clearedDate.substring(10, 12) || '00';

            if (!clearedDate) {
                return '';
            }

            return `${clearedDate.substring(0, 4)}-${clearedDate.substring(4, 6)}-${clearedDate.substring(6, 8)} ${hour}:${minute}`;
        }
    },
    computed: {
        ...mapGetters('ticketFilter', [
            'filterInit'
        ]),
        isIncludeClosedDisabled() {
            return !this.selectedTicketFilters.ticketRegistrationDate.max.replace(/[^\d]/g, "") &&
                !this.selectedTicketFilters.ticketRegistrationDate.min.replace(/[^\d]/g, "") &&
                !this.selectedTicketFilters.subjectRecoveryDate.max.replace(/[^\d]/g, "") &&
                !this.selectedTicketFilters.subjectRecoveryDate.min.replace(/[^\d]/g, "");
        },
        subjectSuppliers() {
            const selectedType = (this.selectedTicketFilters.subjectType === '0')
                ? this.filterInit.lists.subjectType[0].code : this.selectedTicketFilters.subjectType;

            return this.filterInit.lists.subjectType.filter(type => type.code === selectedType)[0].suppliers;
        },
        selectedSupplier() {
            if (!this.subjectSuppliers?.length || this.selectedTicketFilters.subjectSupplier === '0') {
                return null;
            }

            const selectedSubjectSupplierId = this.selectedTicketFilters.subjectSupplier;
            const selectedSubjectSupplierList = this.subjectSuppliers.filter(item => item?.supplier.id === selectedSubjectSupplierId);

            if (!selectedSubjectSupplierList.length) {
                return null;
            }

            return selectedSubjectSupplierList[0];
        },
        subjectCustomers() {
            return this.selectedSupplier?.customers || [];
        },
        showCustomers() {
            return this.selectedSupplier
            ? Object.prototype.hasOwnProperty.call(this.selectedSupplier, 'customers') : false;
        },
        subjects() {
            return this.showCustomers ? this.subjectsOfCustomers : this.subjectsOfSuppliers;
        },
        subjectsOfCustomers() {
            if (!this.subjectCustomers?.length) {
                return [];
            }

            const selectedCustomerId = (this.selectedTicketFilters.subjectCustomer === '0')
                ? this.subjectCustomers[0]?.customer.id : this.selectedTicketFilters.subjectCustomer;
            const selectedCustomerList = this.subjectCustomers.filter(item => item?.customer.id === selectedCustomerId);

            if (!selectedCustomerList.length) {
                return [];
            }

            return selectedCustomerList[0].variants;
        },
        subjectsOfSuppliers() {
            return this.selectedSupplier?.variants || [];
        },

        tvChannels() {
            if (!this.subjects?.length || this.selectedTicketFilters.subject === '0') {
                return [];
            }

            const selectedSubjectId = this.selectedTicketFilters.subject;
            const selectedSubjectList = this.subjects.filter(item => item?.id === selectedSubjectId);

            if (!selectedSubjectList.length || !selectedSubjectList[0].channels?.length) {
                return [];
            }

            return selectedSubjectList[0].channels;
        },

        memberGroups() {
            const selectedGroup = this.selectedTicketFilters.memberCompany;
            if (selectedGroup === '0') {
                return [];
            }

            return this.filterInit.lists.memberGroup.filter(item => item.company.id === selectedGroup)[0].groups;
        },

        members() {
            if (!this.memberGroups?.length || this.selectedTicketFilters.memberGroup === '0') {
                return [];
            }

            const selectedGroupId = (this.selectedTicketFilters.memberGroup === '0')
                ? this.memberGroups[0]?.code : this.selectedTicketFilters.memberGroup;
            const selectedGroupList = this.memberGroups.filter(item => item.code === selectedGroupId);

            if (!selectedGroupList.length) {
                return [];
            }

            return selectedGroupList[0].members;
        },
    },
    watch: {
        "selectedTicketFilters.subjectType": function() {
            if (this.isDefaultValuesSettled.subject) {
                // Сбрасываем флаг, чтобы при следующем изменении типа объекта значение поля объект сбрасывалось
                this.isDefaultValuesSettled.subject = false;
                return;
            }
            this.selectedTicketFilters.subjectSupplier = '0';
            this.selectedTicketFilters.subjectCustomer = '0';
            this.selectedTicketFilters.subject = '0';
            this.selectedTicketFilters.tvChannel = '0';
        },
        "selectedTicketFilters.subjectSupplier": function() {
            if (this.isDefaultValuesSettled.subject) {
                // Сбрасываем флаг, чтобы при следующем изменении типа объекта значение поля объект сбрасывалось
                this.isDefaultValuesSettled.subject = false;
                return;
            }
            this.selectedTicketFilters.subjectCustomer = '0';
            this.selectedTicketFilters.subject = '0';
            this.selectedTicketFilters.tvChannel = '0';
        },
        "selectedTicketFilters.subjectCustomer": function() {
            if (this.isDefaultValuesSettled.subject) {
                // Сбрасываем флаг, чтобы при следующем изменении типа объекта значение поля объект сбрасывалось
                this.isDefaultValuesSettled.subject = false;
                return;
            }
            this.selectedTicketFilters.subject = '0';
            this.selectedTicketFilters.tvChannel = '0';
        },
        "selectedTicketFilters.subject": function() {
            this.selectedTicketFilters.tvChannel = '0';
        },
        "selectedTicketFilters.memberCompany": function() {
            if (this.isDefaultValuesSettled.member) {
                // Сбрасываем флаг, чтобы при следующем изменении группы участника решения значение поля участник сбрасывалось
                this.isDefaultValuesSettled.member = false;
                return;
            }

            this.selectedTicketFilters.memberGroup = '0';
            this.selectedTicketFilters.member = '0';
        },
        "selectedTicketFilters.memberGroup": function() {
            if (this.isDefaultValuesSettled.member) {
                // Сбрасываем флаг, чтобы при следующем изменении группы участника решения значение поля участник сбрасывалось
                this.isDefaultValuesSettled.member = false;
                return;
            }

            this.selectedTicketFilters.member = '0';
        },
        "selectedTicketFilters.ticketNumber": function() {
            this.isAllDisabled = this.selectedTicketFilters.ticketNumber !== '0';
        },
        "filterInit": function() {
            this.setDefaultSelectedData(this.filterInit.default);
        },
        "isIncludeClosedDisabled": function (value) {
            if (value) {
                this.selectedTicketFilters.includeClosed = false;
            }
        }
    },
}
</script>
