<template>
    <div
        v-if="permissions.functions.includes('commentForAll')"
        class="btn"
        @click="$emit('showAddCommentForAll')"
    >
        {{state.functions['commentForAll'].buttonText}}
    </div>
    <div
        v-if="permissions.functions.includes('commentForCustomer')"
        class="btn"
        @click="$emit('showAddCommentForCustomer')"
    >
        {{state.functions['commentForCustomer'].buttonText}}
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'AddCommentButtons',
    components: {},
    emits: ['showAddCommentForAll', 'showAddCommentForCustomer'],
    props: {
        state: {
            type: Object,
            default: () => ({})
        },
        permissions: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
    }
});
</script>
