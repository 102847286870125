<template>
    <ModalPopup :id="id"
                title="Регистрация ТТ. Ручной ввод объекта влияния (услуга)"
                :is-forced="true">
        <form id="manual-service-form">
            <div class="mb-3">
                <label class="form-label">Наименование объекта влияния</label>
                <input type="text" class="form-control" v-model="name">
            </div>
            <div v-if="suppliers.length" class="mb-3">
                <label class="form-label">Поставщик</label>
                <select type="text" class="form-select js-select-search"
                        v-model="currentSupplierId">
                    <option value="">Не выбрано</option>
                    <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">
                        {{ supplier.title }}
                    </option>
                </select>
            </div>
            <div v-if="customers.length" class="mb-3">
                <label class="form-label">Заказчик</label>
                <select type="text" class="form-select js-select-search"
                        v-model="currentCustomerId">
                    <option value="">Не выбрано</option>
                    <option v-for="customer in customers" :key="customer.id" :value="customer.id">
                        {{ customer.title }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label class="form-label">Договор</label>
                <input v-model="contract" type="text" class="form-control">
            </div>
        </form>
        <template v-slot:footer>
            <div class="btn-list">
                <div @click="submitService" class="btn">Ввести</div>
                <div class="btn" data-bs-dismiss="modal">Отменить регистрацию</div>
            </div>
        </template>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import ComponentHelper from "@/utils/ComponentHelper";
import ComponentErrors from "@/utils/ComponentErrors";
import SwitchStepMixin from "@/components/ticket/create/steps/SwitchStepMixin";
import ModalPopup from "@/components/utils/ModalPopup.vue";

export default defineComponent({
    name: 'ManualService',
    components: {ModalPopup},
    props: {
        options: {
            type: Object
        }
    },
    emits: ['submitService'],
    mixins: [SwitchStepMixin],
    mounted() {
        ComponentHelper.reInitSelects();
    },
    data() {
        return {
            id: 'ticket-create-popup-service-step-choose-service-manual',
            currentCustomerId: 0,
            currentSupplierId: 0,
            name: '',
            contract: ''
        };
    },
    methods: {
        submitService() {
            if (this.validate()) {
                this.$emit('submitService',
                    this.name,
                    this.itemById(this.customers, this.currentCustomerId),
                    this.itemById(this.suppliers, this.currentSupplierId),
                    this.contract);
            }
        },
        validate() {
            return ComponentErrors.execute([
                [!this.name, 'Заполните наименование объекта влияния'],
                [this.customers.length && !this.currentCustomerId, 'Выберите заказчика'],
                [this.suppliers.length && !this.currentSupplierId, 'Выберите поставщика']
            ])
        },
        itemById(list, id) {
            if (!id) {
                return null;
            }

            for (let index in list) {
                if (!Object.prototype.hasOwnProperty.call(list, index)) {
                    continue;
                }

                if (list[index].id === id) {
                    return list[index];
                }
            }

            return null;
        }
    },
    computed: {
        customers() {
            return this.options.lists.serviceCustomerVariants && this.options.lists.serviceCustomerVariants.length
                ? this.options.lists.serviceCustomerVariants : [];
        },
        suppliers() {
            return this.options.lists.serviceSupplierVariants && this.options.lists.serviceSupplierVariants.length
                ? this.options.lists.serviceSupplierVariants : [];
        },
    }
});
</script>
