<template>
    <ModalPopup :id="popupsId.ADDING_MANUAL_SUBSCRIBER_POPUP"
                :is-forced="true"
                title="Добавить нового подписчика"
                size="lg"
    >
        <form>
        <div class="mb-3">
            <label class="form-label">Имя подписчика*</label>
            <input type="text" class="form-control" v-model="subscriber.name">
        </div>

        <div class="mb-3">
            <label class="form-label">Email*</label>
            <input type="email" class="form-control" v-model="subscriber.email">
        </div>
        <div class="mb-3">
            <label class="form-label">Описание</label>
            <textarea class="form-control" rows="2" v-model="subscriber.description"></textarea>
        </div>
        </form>
        <template v-slot:footer>
            <div class="btn-list">
                <div class="btn" @click.prevent="addSubscriber">Ввести</div>
                <div class="btn" @click.prevent="finish">Отменить регистрацию</div>
            </div>
        </template>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_ADDING_SUBSCRIBER_POPUP" popup-title="Подтверждение добавления дополнительного подписчика">
        <div class="mb-2">Подтвердите добавление подписчика с внешним email:</div>
        <div class="mb-2">
            {{ subscriber.name }}
            <span v-if="subscriber.description">-
                {{ subscriber.description }}
            </span>
            <span>({{ subscriber.email }})</span>
        </div>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="addSubscriber(true)">Подтвердить</div>
            <div class="btn" @click.prevent="finish">Выйти без изменений</div>
        </template>
    </ConfirmPopup>
</template>

<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";
import ComponentErrors from "@/utils/ComponentErrors";
import * as regExpressions from '../../../../utils/constants/regularExpressions';
import * as popupsId from '../../../../utils/constants/popupsId';
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import PopupUtils from "@/utils/PopupUtils";

export default {
    name: 'AddManualSubscriber',
    components: {ConfirmPopup, ModalPopup},
    data() {
        return {
            popupsId: popupsId,
            subscriber: {
                name: '',
                email: '',
                description: '',
            },
            regExpressions: regExpressions,
        }
    },
    emits: ['addSubscriber'],
    methods: {
        showAdd(){
            this.clearData();
            this.hideConfirm();

            PopupUtils.show(this.popupsId.ADDING_MANUAL_SUBSCRIBER_POPUP);
        },
        hideAdd(){
            PopupUtils.hide(this.popupsId.ADDING_MANUAL_SUBSCRIBER_POPUP);
        },
        showConfirm(){
            this.hideAdd();

            PopupUtils.show(this.popupsId.CONFIRM_ADDING_SUBSCRIBER_POPUP);
        },
        hideConfirm(){
            PopupUtils.hide(this.popupsId.CONFIRM_ADDING_SUBSCRIBER_POPUP);
        },
        clearData() {
            Object.keys(this.subscriber).forEach(field => {
                this.subscriber[field] = '';
            })
        },
        finish() {
            this.hideConfirm();
            this.hideAdd();
        },
        addSubscriber(confirmed = false) {
            if (this.validate()) {
                const subscriber = Object.assign({}, this.subscriber);
                subscriber.confirmed = confirmed;
                subscriber.ex = false;

                this.hideAdd();
                this.hideConfirm();

                this.$emit('addSubscriber', subscriber);
            }
        },
        validate() {
            return ComponentErrors.execute([
                [!(this.subscriber.name.length >= 2), 'Заполните имя нового подписчика'],
                [!(this.regExpressions.REG_EXP_EMAIL.test(this.subscriber.email)), 'Заполните email корректно'],
            ]);
        }
    }
}
</script>
