<template>
    <div v-for="(file,index) in files" :key="index" class="fw-bold">{{ file.name }}
        <a @click.prevent="removeFile(index)" href="javascript:void(0)">Удалить</a>
    </div>
    <AddFile ref="addFile" :id="prefix +'upload-files'" @add-file="addFile($event)"/>
</template>

<script>
import {defineComponent} from 'vue';
import Notification from "@/utils/Notification";
import AddFile from "@/components/utils/AddFile.vue";

export default defineComponent({
    name: 'UploadedFiles',
    components: {AddFile},
    props: {
        maxBytes: {
            type: Number
        },
        prefix: {
            type: String
        }
    },
    emits: ['updateFiles'],
    data() {
        return {
            files: []
        };
    },
    methods: {
        clear() {
            this.files = [];
            this.$refs.addFile.clear();
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        addFile(file) {
            if (!this.checkFileSize(file)) {
                Notification.error('Нельзя приложить более ' + this.fixedMaxBytes() / 1048576 + ' Мб');
                return;
            }

            this.files.push(file);
        },
        checkFileSize(file) {
            let sizeLeft = this.fixedMaxBytes(), i;

            for (i in this.files) {
                sizeLeft -= this.files[i].size;
            }

            return file.size <= sizeLeft;
        },
        fixedMaxBytes() {
            return this.maxBytes || (2 * 1048576);
        },
    },
    watch: {
        files: function () {
            this.$emit('updateFiles', {files: this.files});
        }
    }
});
</script>
