<template>
    <ModalPopup :id="id"
                ref="modal"
                title="Выбор источника информации"
                :is-forced="true">
        <div class="list-group list-group-flush list-group-hoverable card">
            <div v-for="source in sources" :key="source.code"
                 class="list-group-item p-2"
                 :class="{'bg-blue-lt': source.code===currentSource?.code}"
                 @click="selectSource(source)"
                 @dblclick="chooseSource(source)"
            >
                <div class="row align-items-center">
                    <div class="col text-truncate ps-3">
                        {{ source.title }}
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 d-flex justify-content-center">
            <div class="btn" @click="chooseSource(null)">Выбрать</div>
            <div class="btn" data-bs-dismiss="modal">Выйти без выбора</div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";

export default defineComponent({
    name: 'InformationSource',
    components: {ModalPopup},
    props: {
        sources: {
            type: Array
        },
        id: {
            type: String
        }
    },
    emits: ['chooseSource'],
    data() {
        return {
            currentSource: null
        };
    },
    methods: {
        chooseSource(source) {
            if (source) {
                this.selectSource(source);
            }

            if (this.currentSource && getObjectByCode(this.sources, this.currentSource.code)) {
                this.$emit('chooseSource', this.currentSource);
                this.$refs.modal.hide();
            }
        },
        selectSource(source) {
            this.currentSource = source;
        }
    }
});
</script>
