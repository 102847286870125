export default class {

    static runFromScratch() {
        if (typeof window.CreateTicketApp !== 'undefined') {
            window.CreateTicketApp.run();
        }
    }

    static runWithService(id) {
        if (typeof window.CreateTicketApp !== 'undefined') {
            window.CreateTicketApp.runWithService(id);
        }
    }

    static runWithChannel(id) {
        if (typeof window.CreateTicketApp !== 'undefined') {
            window.CreateTicketApp.runWithChannel(id);
        }
    }

    static runWithDraft(number) {
        if (typeof window.CreateTicketApp !== 'undefined') {
            window.CreateTicketApp.runWithDraft(number);
        }
    }
}
