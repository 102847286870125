<template>

    <ModalPopup :id="popupsId.FUNCTION_CLOSE_ASK"
                :is-forced="true"
                title="Конфигурация менялась?">
        <div class="row">
            <div class="col">
                <div>
                    <label class="form-check">
                        <input class="form-check-input" type="radio" :value="true" v-model="configurationChanged">
                        <span class="form-check-label">Да</span>
                    </label>
                    <label class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :value="false" v-model="configurationChanged">
                        <span class="form-check-label">Нет</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div class="btn" @click.prevent="showFunction">Принять и продолжить</div>
                    <div class="btn" @click.prevent=finish data-bs-dismiss="modal">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ModalPopup :id="popupsId.FUNCTION_CLOSE"
                :is-forced="true"
                size="xl"
                title="Закрытие ТТ">

        <div class="row">
            <div class="col">
                <div class="row mb-2">
                    <div class="col">
                        Дата закрытия
                    </div>
                    <div class="col" v-if="options.closeAt">
                        <a href="javascript:void(0)">{{ formatDate(options.closeAt) }}</a>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        Время устранения инцидента
                    </div>
                    <div class="col">
                        <a href="javascript:void(0)">{{ secondsToTimeFormat(options.unavailableSeconds) }}</a>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        Зона ответственности
                    </div>
                    <div class="col">
                        <a href="javascript:void(0)"
                           data-modal="#ticket-close-final-assignee-popup">
                            {{ currentFinalAssignee.title }}
                        </a>
                    </div>
                    <FunctionGroup v-if="options.finalAssigneeVariants?.length"
                                   :groups="options.finalAssigneeVariants"
                                   id="ticket-close-final-assignee-popup"
                                   @choose-group="setFinalAssignee"
                    />
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <div><label class="form-label label-inline">Истинная причина инцидента</label></div>
                        <div><textarea v-model="reason" class="form-control" rows="2"></textarea></div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-3">
                    <label class="form-label">Действия, выполненные для устранения инцидента</label>
                    <textarea class="form-control" rows="10" v-model="comment"></textarea>
                </div>
                <div>
                    <label class="form-label mb-0">Файлы</label>
                    <UploadedFiles
                        ref="files"
                        prefix="close-ticket"
                        :max-bytes="options.filesMaxBites"
                        @update-files="updateFiles"
                    />
                </div>
            </div>
        </div>

        <div class="row" v-if="configurationChanged && subjectParams?.length">
            <div class="col">
                <h4>Технические параметры услуги</h4>
                <div class="card table-responsive">
                    <table class="table card-table table-vcenter text-nowrap datatable table-bordered">
                        <thead>
                        <tr>
                            <th class="col text-center">Параметр</th>
                            <th class="col text-center">Значение</th>
                            <th class="col text-center">Изменено на</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="parameter in flatSubjectParams" :key="parameter.code">
                            <td class="col text-center">{{ parameter.title }}</td>
                            <td class="col text-center">{{ parameter.value }}</td>
                            <td class="col text-center">
                                <TextUnderLink
                                    :text="getChangedParam(parameter.channelId, parameter.code)"
                                    @update:text="setChangedParam(parameter.channelId, parameter.code, $event)"
                                    description="новое значение"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div class="btn" @click.prevent="closeTicket">Закрыть ТТ и выйти</div>
                    <div class="btn" @click.prevent="finish" data-bs-dismiss="modal">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_CLOSE" popup-title="Подтверждение закрытия тикета">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="closeTicket">Всё равно закрыть тикет</div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>

</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as popupsId from '../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";
import {secondsToTimeFormat, formatDate} from "@/components/utils/utilsMethods/dateMethods";
import TextUnderLink from "@/components/ticket/simpleFieldSetter/TextUnderLink.vue";
import FunctionGroup from "@/components/ticket/simpleFieldSetter/FunctionGroup.vue";
import UploadedFiles from "@/components/ticket/simpleFieldSetter/UploadedFiles.vue";
import ComponentErrors from "@/utils/ComponentErrors";

export default defineComponent({
    name: 'FunctionClose',
    components: {UploadedFiles, FunctionGroup, TextUnderLink, ConfirmPopup, ModalPopup},
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        subjectParams: {
            type: Array
        }
    },
    emits: ['closeTicket'],
    data() {
        return {
            configurationChanged: null,
            reason: "",
            currentFinalAssignee: {
                code: "",
                title: "Не выбрано"
            },
            changedParams: {},
            comment: "",
            files: [],
            popupsId: popupsId,
            confirmPopupText: '',
            lastTryDate: null, // Относится к обоим попапам

            secondsToTimeFormat: secondsToTimeFormat,
        };
    },
    computed: {
        flatSubjectParams() {
            let params = [], prefix, channel;

            for (let i in this.subjectParams) {
                if (!Object.prototype.hasOwnProperty.call(this.subjectParams, i)) {
                    continue;
                }
                channel = this.subjectParams[i];
                prefix = this.subjectParams.length > 1 ? channel.title + ': ' : '';

                if (channel.accessParams) {
                    params = this.collectFlatParams(channel.accessParams, channel.id, prefix, params);
                }
                if (channel.specifications) {
                    params = this.collectFlatParams(channel.specifications, channel.id, prefix, params);
                }
            }

            return params;
        }
    },
    methods: {
        formatDate,
        closeTicket() {
            if (this.validate()) {
                const data = {
                    configurationChanged: this.configurationChanged,
                    subjectParameters: JSON.stringify(this.changedParams),
                    reason: this.reason,
                    finalAssignee: this.currentFinalAssignee.code,
                    comment: this.comment,
                    files: this.files,
                    date: this.lastTryDate
                };

                // Закрываем все попапы и очищаем данные
                this.hideAsk();
                this.hideFunction();
                this.hideConfirm();
                this.$emit('closeTicket', data);
            }
        },
        validate() {
            return ComponentErrors.execute([
                [!this.comment, 'Заполните комментарий'],
                [!this.reason, 'Заполните причину инцидента'],
                [!this.currentFinalAssignee.code, 'Выберите зону ответственности'],
                [this.configurationChanged && this.isChangedParametersEmpty(), 'Заполните изменившиеся технические параметры услуги']
            ])
        },
        showAsk(currentAssignee = null) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideFunction();
            this.hideConfirm();

            if (currentAssignee) {
                this.setFinalAssignee(currentAssignee);
            }

            this.$refs.files.clear();

            this.configurationChanged = null;
            this.reason = "";
            this.changedParams = {};
            this.comment = "";
            this.confirmPopupText = '';
            this.lastTryDate = null;

            PopupUtils.show(popupsId.FUNCTION_CLOSE_ASK);
        },
        hideAsk() {
            PopupUtils.hide(popupsId.FUNCTION_CLOSE_ASK);
        },
        showFunction() {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideAsk();
            this.hideConfirm();

            PopupUtils.show(popupsId.FUNCTION_CLOSE);
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_CLOSE);
            this.lastTryDate = null;
        },
        showConfirm(message, date) {
            this.hideAsk();
            this.hideFunction();

            // Данные для попапа
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе
            this.lastTryDate = date;

            PopupUtils.show(popupsId.CONFIRM_CLOSE);
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_CLOSE);

            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideFunction();
            this.hideAsk();
        },
        setFinalAssignee(group) {
            if (getObjectByCode(
                this.options.finalAssigneeVariants,
                group?.code
            )) {
                this.currentFinalAssignee = group;
            } else {
                this.currentFinalAssignee = {
                    code: "",
                    title: "Не выбрано"
                };
            }
        },
        collectFlatParams(list, channelId, namePrefix, result) {
            for (let i in list) {
                if (!Object.prototype.hasOwnProperty.call(list, i)) {
                    continue;
                }

                result.push({
                    channelId: channelId,
                    code: list[i].code,
                    title: namePrefix + list[i].title,
                    value: list[i].value
                })
            }

            return result;
        },
        updateFiles(data) {
            this.files = data.files;
        },
        isChangedParametersEmpty() {
            let i, channelId, paramCode, value;

            if (!this.flatSubjectParams?.length) {
                return false;
            }

            for (i in this.flatSubjectParams) {
                if (!Object.prototype.hasOwnProperty.call(this.flatSubjectParams, i)) {
                    continue;
                }

                channelId = this.flatSubjectParams[i].channelId;
                paramCode = this.flatSubjectParams[i].code;
                value = this.getChangedParam(channelId, paramCode);

                if (value && value !== this.flatSubjectParams[i].value) {
                    return false;
                }
            }

            return true;
        },
        getChangedParam(channelId, paramCode) {
            if (!this.changedParams[channelId]) {
                return '';
            }

            return this.changedParams[channelId][paramCode] ? this.changedParams[channelId][paramCode] : '';
        },
        setChangedParam(channelId, paramCode, value) {

            if (!this.changedParams[channelId]) {
                this.changedParams[channelId] = {};
            }

            this.changedParams[channelId][paramCode] = value;
        }
    }
});
</script>
