import {LOCAL_STORAGE_LAST_TAB_POSTFIX} from "@/modules/constants/LocalStorage";

export default class DetailTicketLastTab {
    constructor(ticketNumber) {
        this.ticketNumber = ticketNumber;
        this.__init(this.__getData());
    }

    getTab() {
        return this.tab;
    }

    set(tab) {
        this.tab = tab;

        this.__saveData();
    }

    reset() {
        this.__init(null);
        this.__saveData();
    }

    delete() {
        localStorage.removeItem(this.__getKey());
    }

    isExpired() {
        return !this?.date || this.date < Date.now() - 3 * 24 * 3600 * 1000; // миллисекунды
    }

    __init(data) {
        this.tab = data?.tab;
        this.date = data?.date;
    }

    __getData() {
        return JSON.parse(localStorage.getItem(this.__getKey()));
    }

    __saveData() {
        if (this.tab === null) {
            localStorage.removeItem(this.__getKey());
        }

        localStorage.setItem(this.__getKey(), JSON.stringify({
            date: Date.now(),
            tab: this.tab,
        }));
    }

    __getKey() {
        return this.ticketNumber + LOCAL_STORAGE_LAST_TAB_POSTFIX;
    }

}
