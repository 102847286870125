import axios from "axios";
import defaultConfig from "@/modules/api/ApiDefaultConfig";
import ApiResult from "@/utils/ApiResult";

export default class {

    static apiPrefix = '/api/Tickets/'

    static async getFieldPermissions() {
        const {data} = await axios.get(this.apiPrefix + 'FieldPermissions', defaultConfig());
        return ApiResult.process(data);
    }
}
