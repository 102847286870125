<template>
    <div class="row divide-x">
        <div class="col">
            <div class="card overflow-auto heigth-500 d-print-height-auto d-print-absolute">
                <div class="card-body divide-y fst-italic">
                    <AllMessages :state="state"/>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3 d-print-none">
        <div class="col d-flex justify-content-center">
            <div class="btn-list">
                <div class="btn" @click="print">Распечатать</div>
                <AddCommentButtons
                    :state="state"
                    :permissions="permissions"
                    @show-add-comment-for-all="$emit('showAddCommentForAll')"
                    @show-add-comment-for-customer="$emit('showAddCommentForCustomer')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AllMessages from "@/components/ticket/detailTicket/utilsComponents/AllMessages.vue";
import AddCommentButtons from "@/components/ticket/detailTicket/AddCommentButtons.vue";
import PrintMixin from "@/components/ticket/detailTicket/tabs/PrintMixin";

export default {
    name: 'AllMessagesTab',
    components: {AddCommentButtons, AllMessages},
    mixins: [PrintMixin],
    props: {
        permissions: {
            type: Object,
            default: () => ({}),
        },
        state: {
            type: Object,
            default: () => ({}),
        },
        ticketNumber: {
            type: String,
            default: '',
        },
        addComment: {
            type: Object,
            default: () => ({})
        },
    },
    emits: ['reloadPageData', 'showAddCommentForAll', 'showAddCommentForCustomer'],
}
</script>
