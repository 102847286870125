<template>
    <ModalPopup :id="popupsId.FUNCTION_CHANGING_PRIORITY"
                :is-forced="true"
                title="Смена приоритета">
        <div class="row mb-3">
            <div class="col">
                <label class="form-label">Приоритет</label>
                <select v-model="currentPriorityCode"
                        type="text" class="form-select">
                    <option v-for="priority in priorities" :key="priority.code" :value="priority.code">
                        {{ priority.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <label class="form-label required">Причина смены приоритета</label>
                <textarea v-model="reason" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div @click="choosePriority" class="btn">Применить и выйти</div>
                    <div class="btn" @click="finish" data-bs-dismiss="modal">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_CHANGING_PRIORITY" popup-title="Подтверждение смены приоритета">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="choosePriority">Всё равно сменить приоритет</div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>

</template>

<script>
import {defineComponent} from 'vue';
import Notification from "@/utils/Notification";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as popupsId from '../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import {mapGetters} from "vuex";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";

export default defineComponent({
    name: 'FunctionChangePriority',
    components: {ConfirmPopup, ModalPopup},
    props: {
        priorities: {
            type: Array
        }
    },
    emits: ['choosePriority'],
    data() {
        return {
            reason: "",
            currentPriorityCode: "",
            popupsId: popupsId,
            initialPriority: null,
            confirmPopupText: '',
            lastTryDate: null, // Относится к обоим попапам
        };
    },
    computed: {
        ...mapGetters('detailTicketPage', [
            'lastPopup',
        ]),
    },
    mounted() {
        // Если в прошлый раз один из попапов остался незакрытым, открывает его
        if (this.lastPopup.getId() === popupsId.FUNCTION_CHANGING_PRIORITY) {
            this.showFunction(
                this.lastPopup.getData().priority,
                this.lastPopup.getData().reason,
                this.lastPopup.getData().date);
        } else if (this.lastPopup.getId() === popupsId.CONFIRM_CHANGING_PRIORITY) {
            this.showConfirm(
                this.lastPopup.getData().text,
                this.lastPopup.getData().date,
                this.lastPopup.getData().priority,
                this.lastPopup.getData().reason
            );
        }
    },
    methods: {
        choosePriority() {
            if (this.validate()) {
                const data = {
                    priority: this.currentPriorityCode,
                    comment: this.reason,
                    date: this.lastTryDate
                };

                // Закрываем все попапы и очищаем данные - дальше все сценарии зависят от обработки события choosePriority
                this.hideFunction();
                this.hideConfirm();
                this.$emit('choosePriority', data);
            }
        },

        validate() {
            if (this.reason.length === 0) {
                Notification.error('Заполните причину смены приоритета');
                return false;
            }

            return true;
        },

        setDefaultPriority(priorityCode) {
            const priority = getObjectByCode(this.priorities, priorityCode);
            if (priority) {
                this.currentPriorityCode = priority.code;
                return;
            }

            this.currentPriorityCode = this.priorities[0].code;
        },

        showFunction(priority, reason, date) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideConfirm();

            // Данные для этого попапа
            this.setDefaultPriority(priority);
            this.reason = reason;
            this.lastTryDate = date;

            PopupUtils.show(popupsId.FUNCTION_CHANGING_PRIORITY);

            // Запомним последний открытый попап и его данные
            this.saveFunctionPopupState();
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_CHANGING_PRIORITY);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.FUNCTION_CHANGING_PRIORITY) {
                this.lastPopup.close();
            }

            // Очистить его данные (но всё, кроме даты, нужно для попапа с подверждением, поэтому не трогаем)
            this.lastTryDate = null;
        },
        showConfirm(message, date, priority = null, reason = '') {
            this.hideFunction();

            // Данные для попапа
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе. Если не переданы - не затираем текущие
            this.lastTryDate = date;

            if (priority) {
                this.setDefaultPriority(priority);
            }
            if (reason) {
                this.reason = reason;
            }

            PopupUtils.show(popupsId.CONFIRM_CHANGING_PRIORITY);

            // Запомним последний открытый попап и его данные
            this.saveConfirmPopupState();
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_CHANGING_PRIORITY);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.CONFIRM_CHANGING_PRIORITY) {
                this.lastPopup.close();
            }

            // Очистить его данные
            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideFunction();
        },
        saveFunctionPopupState() {
            this.lastPopup.open(popupsId.FUNCTION_CHANGING_PRIORITY, {
                priority: this.currentPriorityCode,
                reason: this.reason,
                date: this.lastTryDate
            });
        },
        saveConfirmPopupState() {
            this.lastPopup.open(popupsId.CONFIRM_CHANGING_PRIORITY, {
                text: this.confirmPopupText,
                date: this.lastTryDate,
                priority: this.currentPriorityCode,
                reason: this.reason,
            });
        }
    },
    watch: {
        currentPriorityCode: function () {
            this.saveFunctionPopupState();
        },
        reason: function () {
            this.saveFunctionPopupState();
        }
    }
});
</script>
