<template>
    <template v-if="currentStep">
        <SubjectType v-if="currentStep==='SubjectType' && scenario?.serviceAllowed && scenario?.infrastructureAllowed"
                     ref="SubjectType"
                     @chooseSubjectType="subjectTypeChoose"/>

        <SubjectService
            v-if="currentStep==='SubjectService' && servicesReady && scenario?.serviceAllowed"
            ref="SubjectService"
            :services="services"
            :manual-allowed="scenario.manualServiceAllowed"
            @choose-service="chooseService"
            @choose-manual="chooseManualService"
            @choose-channel="chooseChannel"
        />

        <ManualService
            v-if="currentStep==='ManualService' && manualServiceReady && scenario?.manualServiceAllowed"
            ref="ManualService"
            :options="serviceOptions"
            @submit-service="submitService"
        />

        <SubjectInfrastructure
            v-if="currentStep==='SubjectInfrastructure' && infrastructureObjectsReady && scenario?.infrastructureAllowed"
            ref="SubjectInfrastructure"
            :infrastructure-objects="infrastructureObjects"
            :manual-allowed="scenario.manualInfrastructureAllowed"
            @choose-manual="chooseManualInfrastructure"
            @choose-infrastructure-object="chooseInfrastructureObject"
        />

        <ManualInfrastructure
            v-if="currentStep==='ManualInfrastructure' && scenario?.manualInfrastructureAllowed"
            ref="ManualInfrastructure"
            :type="manualInfrastructureType"
            @submit-infrastructure="submitInfrastructure"
        />

        <DraftForm v-if="currentStep==='DraftForm' && fieldPermissionsService && createTicketReady"
                   ref="DraftForm"
                   :draft="draft"
                   :service="chosenService"
                   :channel="chosenChannel"
                   :manual-service="manualService"
                   :manual-infrastructure="manualInfrastructure"
                   :infrastructure-object="chosenInfrastructureObject"
                   :create-ticket-options="options"
                   :field-permissions-service="fieldPermissionsService"
                   :user="user"
                   @save-draft="saveTicketDraft"
                   @register="registerTicket"
                   @need-reload-options="reloadOptions"
        />
    </template>
</template>

<script>
import {defineComponent} from "vue";
import {mapActions, mapGetters} from 'vuex';
import TicketFieldPermissions from "@/modules/TicketFieldPermissions";
import User from "@/modules/User";
import SubjectService from "@/components/ticket/create/steps/SubjectService.vue";
import ManualService from "@/components/ticket/create/steps/ManualService.vue";
import SubjectType from "@/components/ticket/create/steps/SubjectType.vue";
import DraftForm from "@/components/ticket/create/steps/DraftForm.vue";
import SubjectInfrastructure from "@/components/ticket/create/steps/SubjectInfrastructure.vue";
import ManualInfrastructure from "@/components/ticket/create/steps/ManualInfrastructure.vue";
import Notification from "@/utils/Notification";
import TicketDraft from "@/modules/TicketDraft";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";

export default defineComponent({
    name: 'CreateTicket',
    components: {
        ManualInfrastructure,
        SubjectInfrastructure,
        DraftForm,
        ManualService,
        SubjectType,
        SubjectService,
    },
    props: {},
    data() {
        return {
            currentStep: '',
            chosenService: null,
            chosenChannel: null,
            chosenInfrastructureObject: null,
            manualService: null,
            manualInfrastructure: null,
            manualInfrastructureType: null,
            savedDraft: null,
        };
    },
    mounted() {
        this.getScenario();
    },
    methods: {

        ...mapActions('createTicket', [
            'demandService',
            'demandChannel',
            'demandInfrastructureObject',
            'getServices',
            'getServiceOptions',
            'getInfrastructureObjects',
            'getOptions',
            'register',
            'saveDraft',
            'getFieldPermissions',
            'saveInfrastructureObject',
            'getScenario',
            'getDraft',
        ]),

        run() {
            if (!this.scenario) {
                return;
            }

            this.resetState();

            if (this.scenario.serviceAllowed && this.scenario.infrastructureAllowed) {
                this.askSubjectType();
                return;
            }

            if (this.scenario.serviceAllowed) {
                this.askSubjectService();
                return;
            }

            if (this.scenario.infrastructureAllowed) {
                this.askSubjectInfrastructure();
            }
        },

        async runWithService(id) {
            if (!this.scenario || !this.scenario.serviceAllowed) {
                return;
            }

            const service = this.serviceOnDemand(id);

            this.resetState();

            if (service) {
                this.chooseService(service);
                return;
            }

            const result = await this.demandService(id);
            if (result) {
                this.chooseService(result);
            }
        },

        async runWithChannel(id) {
            if (!this.scenario || !this.scenario.serviceAllowed) {
                return;
            }

            const channel = this.channelOnDemand(id);

            this.resetState();

            if (channel) {
                this.chooseChannel(channel.service, channel);
                return;
            }

            const result = await this.demandChannel(id);
            if (result) {
                this.chooseChannel(result.service, result);
            }
        },

        async runWithDraft(number) {
            const draft = await this.getDraft(number);

            this.resetState();

            if (draft.isSuccess()) {
                await this.continueDraft(draft.getData().state);
            }
        },

        askSubjectType() {
            if (this.currentStep === 'SubjectType') {
                this.$refs.SubjectType.show();
            } else {
                this.currentStep = 'SubjectType';
            }
        },

        askSubjectService() {
            if (!this.servicesReady) {
                this.getServices();
            }

            if (this.currentStep === 'SubjectService') {
                this.$refs.SubjectService.show();
            } else {
                this.currentStep = 'SubjectService';
            }
        },

        askManualService() {
            if (!this.manualServiceReady) {
                this.getServiceOptions();
            }

            if (this.currentStep === 'ManualService') {
                this.$refs.ManualService.show();
            } else {
                this.currentStep = 'ManualService';
            }
        },

        askSubjectInfrastructure() {
            if (!this.infrastructureObjectsReady) {
                this.getInfrastructureObjects();
            }

            if (this.currentStep === 'SubjectInfrastructure') {
                this.$refs.SubjectInfrastructure.show();
            } else {
                this.currentStep = 'SubjectInfrastructure';
            }
        },

        askManualInfrastructure() {
            if (this.currentStep === 'ManualInfrastructure') {
                this.$refs.ManualInfrastructure.show();
            } else {
                this.currentStep = 'ManualInfrastructure';
            }
        },

        askDraftFields() {
            this.getOptions(this.draft);

            if (!this.fieldPermissionsService) {
                this.getFieldPermissions();
            }

            if (this.currentStep === 'DraftForm') {
                this.$refs.DraftForm.show();
            } else {
                this.currentStep = 'DraftForm';
            }
        },

        resetState() {
            this.chosenService = null;
            this.chosenChannel = null;
            this.chosenInfrastructureObject = null;
            this.manualService = null;
            this.manualInfrastructure = null;
            this.manualInfrastructureType = null;
            this.savedDraft = null;
        },

        subjectTypeChoose(subjectTypeCode) {
            if (subjectTypeCode === 'service') {
                this.askSubjectService();
            } else if (subjectTypeCode === 'infrastructure') {
                this.askSubjectInfrastructure();
            }
        },

        chooseService(service) {
            this.chosenService = service;
            this.askDraftFields();
        },

        chooseManualService() {
            this.askManualService();
        },

        chooseChannel(service, channel) {
            this.chosenService = service;
            this.chosenChannel = channel;
            this.askDraftFields();
        },

        async continueDraft(draft) {
            if (draft.service) {
                const service = this.serviceOnDemand(draft.service) || await this.demandService(draft.service);
                if (!service) {
                    Notification.error('Черновик содержит недопустимые данные');
                    return;
                }

                this.chosenService = service;

                if (draft.channel) {
                    const channel = getObjectByCode(service.channels, parseInt(draft.channel), 'id');
                    if (!channel) {
                        Notification.error('Черновик содержит недопустимые данные');
                        return;
                    }

                    this.chosenChannel = channel;
                }
            }

            if (draft.infrastructureObject) {
                const infrastructureObject = this.infrastructureObjectsOnDemand(draft.infrastructureObject) ||
                    await this.demandInfrastructureObject(draft.infrastructureObject);
                if (!infrastructureObject) {
                    Notification.error('Черновик содержит недопустимые данные');
                    return;
                }

                this.chosenInfrastructureObject = infrastructureObject;
            }

            this.manualService = draft.manualService;
            if (draft.manualService?.customer) {
                this.manualService.customer = {id: draft.manualService.customer};
            }
            if (draft.manualService?.supplier) {
                this.manualService.supplier = {id: draft.manualService.supplier};
            }

            this.manualInfrastructure = draft.manualInfrastructure;
            if (draft.manualInfrastructure?.type) {
                this.manualInfrastructure.type = draft.manualInfrastructure.type.code;
            }

            this.savedDraft = {
                subjectState: draft.subjectState?.code,
                priority: draft.priority?.code,
                informationSource: draft.informationSource?.code,
                informationSourceContact: draft.informationSourceContact,
                coordinatorGroup: draft.coordinatorGroup?.code,
                assigneeGroup: draft.assigneeGroup?.code,
                coordinator: draft.coordinator?.code,
                assignee: draft.assignee?.code,
                previewText: draft.previewText,
                detailText: draft.detailText,
                storedFiles: draft.files,
                number: draft.name,
            };

            this.askDraftFields();
        },

        submitService(subjectName, customer, supplier, contractName) {
            this.manualService = {
                "contract": contractName,
                "service": subjectName,
                "customer": customer,
                "supplier": supplier,
            };
            this.askDraftFields();
        },

        hideDraftForm(message) {
            this.currentStep = '';
            Notification.success(message);
        },

        async registerTicket(data) {
            await this.register(data);
        },

        async saveTicketDraft(data) {
            await this.saveDraft(data);
        },

        async submitInfrastructure(name, linkNode, type) {
            this.manualInfrastructure = {
                "name": name,
                "linkNode": linkNode,
                "type": type
            }

            this.askDraftFields();

            // Использовать этот код, если this.manualInfrastructure нужно будет сохранять в базе отдельно
            // const id = await this.saveInfrastructureObject(this.manualInfrastructure);
            //
            // if (id) {
            //     await this.getInfrastructureObjects();
            //
            //     const infrastructureObject = this.getInfrastructureObjectById(id);
            //     if (infrastructureObject) {
            //         this.chooseInfrastructureObject(infrastructureObject);
            //     } else {
            //         Notification.error('Ошибка при сохранении объекта инфраструктуры');
            //     }
            // }
        },

        chooseManualInfrastructure(type) {
            this.manualInfrastructureType = type;

            this.askManualInfrastructure();
        },

        chooseInfrastructureObject(infrastructureObject) {
            this.chosenInfrastructureObject = infrastructureObject;
            this.askDraftFields();
        },

        getInfrastructureObjectById(id) {
            for (let i in this.infrastructureObjects) {
                if (Object.prototype.hasOwnProperty.call(this.infrastructureObjects, i)
                    && this.infrastructureObjects[i].id === id) {
                    return this.infrastructureObjects[i]
                }
            }

            return null;
        },

        reloadOptions(draft) {
            this.getOptions(draft);
        }

    },
    computed: {
        ...mapGetters('createTicket', [
            'options',
            'infrastructureObjects',
            'serviceOptions',
            'services',
            'serviceOnDemand',
            'channelOnDemand',
            'infrastructureObjectsOnDemand',
            'fieldPermissions',
            'scenario'
        ]),

        fieldPermissionsService() {
            if (this.fieldPermissions) {
                return new TicketFieldPermissions(this.fieldPermissions)
            }

            return null;
        },

        user() {
            if (this.options) {
                return new User(this.options.userRole)
            }

            return null;
        },

        servicesReady() {
            return !!this.services;
        },

        manualServiceReady() {
            return !!this.serviceOptions;
        },

        infrastructureObjectsReady() {
            return !!this.infrastructureObjects;
        },

        createTicketReady() {
            return !!this.options;
        },

        isManualServiceSubject() {
            return this.manualService && this.manualService.service;
        },

        isManualInfrastructureSubject() {
            return this.manualInfrastructure && this.manualInfrastructure.name;
        },

        isServiceSubject() {
            return this.chosenService && this.chosenService.id && !this.isServiceChannelSubject;
        },

        isServiceChannelSubject() {
            return this.chosenChannel && this.chosenChannel.id;
        },

        isInfrastructureObjectSubject() {
            return this.chosenInfrastructureObject && this.chosenInfrastructureObject.id;
        },

        draft() {
            let data = this.savedDraft || {};

            if (this.isServiceSubject) {
                data.service = this.chosenService.id;
            }

            if (this.isServiceChannelSubject) {
                data.channel = this.chosenChannel.id;
            }

            if (this.isInfrastructureObjectSubject) {
                data.infrastructureObject = this.chosenInfrastructureObject.id;
            }

            let draft = new TicketDraft(data);
            if (this.isManualServiceSubject) {
                draft.setManualService(
                    this.manualService.customer ? this.manualService.customer.id : null,
                    this.manualService.supplier ? this.manualService.supplier.id : null,
                    this.manualService.service,
                    this.manualService.contract
                );
            }
            if (this.isManualInfrastructureSubject) {
                draft.setManualInfrastructure(
                    this.manualInfrastructure.name,
                    this.manualInfrastructure.linkNode,
                    this.manualInfrastructure.type
                );
            }

            return draft;
        },
    }
});
</script>
