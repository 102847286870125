<template>
    <a href="javascript:void(0)" @click="show">
        История изменений
    </a>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ShowHistoryButton',
    props: {},
    data() {
        return {}
    },
    methods: {
        show() {
            if (typeof window.ServiceConsumedHistoryPopup !== 'undefined') {
                window.ServiceConsumedHistoryPopup.run();
            }
        }
    }
});
</script>
