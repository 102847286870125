export default class User {

    static CUSTOMER = 'CUSTOMER';
    static MARKET = 'MARKET';
    static SUPPLIER = 'SUPPLIER';

    role;

    constructor(role) {
        this.role = role;
    }

    isCustomer() {
        return this.role === User.CUSTOMER
    }

}
