const getObjectByCode = (list, code, fieldName = 'code') => {
    if (code && list && list.length) {
        for (let index in list) {
            if (!Object.prototype.hasOwnProperty.call(list, index)) {
                continue;
            }

            if (list[index][fieldName] === code) {
                return list[index];
            }
        }
    }

    return null;
}

export {getObjectByCode}
