const padStart = (value) => {
    if(value === 0) {
        return '00';
    }
    return ('0' + value).slice(-2)
};

const secondsToTimeFormat = (totalSeconds) => {
    totalSeconds = Math.abs(totalSeconds);

    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor(totalSeconds / 3600) - days * 24;
    const minutes = Math.floor(totalSeconds / 60) - days * 1440 - hours * 60;
    const seconds = totalSeconds - (days * 86400) - (hours * 3600) - (minutes * 60);

    return (days ? `${days}д. ` : '') + `${padStart(hours)}:${padStart(minutes)}:${padStart(seconds)}`;
};

const calculateTimeDuration = (from, to) => {
    const totalSeconds = Math.floor((Date.parse(to) - Date.parse(from))/1000);

    return secondsToTimeFormat(totalSeconds);
};

const formatDate = (stringDate) => {
    let format = `${stringDate.substring(8, 10)}.${stringDate.substring(5, 7)}.${stringDate.substring(0, 4)} ${stringDate.substring(11, 13)}:${stringDate.substring(14, 16)}`;
    if (stringDate.substring(17, 19)) {
        format += ':' + stringDate.substring(17, 19)
    }

    return format;
};

export {secondsToTimeFormat, calculateTimeDuration, padStart, formatDate};
