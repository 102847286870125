<template>
    <ModalPopup :id="popupsId.FUNCTION_CONFIRM_RECOVERY"
                :is-forced="true"
                size="xl"
                title="Подтверждение состояния услуги">
        <div class="row mb-4 mt-3">
            <div class="col d-flex justify-content-center">
                <div>Комментарий к текущему состоянию услуги</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <textarea v-model="comment" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div class="btn" @click="confirmSubjectState(true)">Услуга ВОССТАНОВЛЕНА. Инцидент устранён</div>
                    <div class="btn" @click="confirmSubjectState(false)">Инцидент НЕ устранён</div>
                    <div class="btn" @click="finish" data-bs-dismiss="modal">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_CONFIRM_RECOVERY" popup-title="Подтверждение состояния услуги">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="confirmSubjectState(confirmed)">
                Всё равно {{ confirmed ? 'подтвердить' : 'отклонить' }} восстановление услуги
            </div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>

</template>

<script>
import {defineComponent} from 'vue';
import Notification from "@/utils/Notification";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as popupsId from '../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import {mapGetters} from "vuex";

export default defineComponent({
    name: 'FunctionConfirmRecovery',
    components: {ConfirmPopup, ModalPopup},
    props: {
    },
    emits: ['confirmSubjectState'],
    data() {
        return {
            comment: "",
            confirmed: null,
            popupsId: popupsId,
            confirmPopupText: '',
            lastTryDate: null, // Относится к обоим попапам
        };
    },
    computed: {
        ...mapGetters('detailTicketPage', [
            'lastPopup',
        ]),
    },
    mounted() {
        // Если в прошлый раз один из попапов остался незакрытым, открывает его
        if (this.lastPopup.getId() === popupsId.FUNCTION_CONFIRM_RECOVERY) {
            this.showFunction(
                this.lastPopup.getData().comment,
                this.lastPopup.getData().date);
        } else if (this.lastPopup.getId() === popupsId.CONFIRM_CONFIRM_RECOVERY) {
            this.showConfirm(
                this.lastPopup.getData().text,
                this.lastPopup.getData().date,
                this.lastPopup.getData().confirmed,
                this.lastPopup.getData().comment
            );
        }
    },
    methods: {
        confirmSubjectState(confirmed) {
            this.confirmed = confirmed;

            if (this.validate()) {
                const data = {
                    confirmed: this.confirmed,
                    comment: this.comment,
                    date: this.lastTryDate
                };

                // Закрываем все попапы и очищаем данные
                this.hideFunction();
                this.hideConfirm();

                this.$emit('confirmSubjectState', data);
            }
        },

        validate() {
            if (!this.confirmed && !this.comment) {
                Notification.error('Заполните причину отклонения восстановления услуги');
                return false;
            }

            return true;
        },

        showFunction(comment, date) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideConfirm();

            // Данные для этого попапа
            this.comment = comment;
            this.lastTryDate = date;

            PopupUtils.show(popupsId.FUNCTION_CONFIRM_RECOVERY);

            // Запомним последний открытый попап и его данные
            this.saveFunctionPopupState();
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_CONFIRM_RECOVERY);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.FUNCTION_CONFIRM_RECOVERY) {
                this.lastPopup.close();
            }

            // Очистить его данные (но всё, кроме даты, нужно для попапа с подверждением, поэтому не трогаем)
            this.lastTryDate = null;
        },
        showConfirm(message, date, confirmed = null, comment = '') {
            this.hideFunction();

            // Данные для попапа
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе. Если не переданы - не затираем текущие
            this.lastTryDate = date;

            if (confirmed !== null) {
                this.confirmed = confirmed;
            }
            if (comment) {
                this.comment = comment;
            }

            PopupUtils.show(popupsId.CONFIRM_CONFIRM_RECOVERY);

            // Запомним последний открытый попап и его данные
            this.saveConfirmPopupState();
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_CONFIRM_RECOVERY);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.CONFIRM_CONFIRM_RECOVERY) {
                this.lastPopup.close();
            }

            // Очистить его данные
            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideFunction();
        },
        saveFunctionPopupState() {
            this.lastPopup.open(popupsId.FUNCTION_CONFIRM_RECOVERY, {
                comment: this.comment,
                date: this.lastTryDate
            });
        },
        saveConfirmPopupState() {
            this.lastPopup.open(popupsId.CONFIRM_CONFIRM_RECOVERY, {
                text: this.confirmPopupText,
                date: this.lastTryDate,
                confirmed: this.confirmed,
                comment: this.comment,
            });
        }
    },
    watch: {
        comment: function () {
            this.saveFunctionPopupState();
        }
    }
});
</script>
