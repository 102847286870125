<template>
    <ModalPopup :id="popupsId.FUNCTION_CANCEL_INCIDENT" :is-forced="true" title="Отмена ТТ">
        <div class="row">
            <div class="col">
                <label class="form-label required">Причина отмены</label>
                <textarea v-model="reason" class="form-control" rows="10"></textarea>
            </div>
        </div>
        <div class="row mt-3">
            <div class="d-flex justify-content-center">
                <div class="btn-list">
                    <div class="btn" @click.prevent="doCancel">Отменить ТТ и выйти</div>
                    <div class="btn" @click.prevent="finish">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_CANCEL_INCIDENT" popup-title="Подтверждение отмены">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="doCancel">Всё равно отменить тикет</div>
            <div class="btn" @click.prevent="finish">Выйти без изменений</div>
        </template>
    </ConfirmPopup>
</template>

<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {formatDate} from "../../utils/utilsMethods/dateMethods";
import * as popupsId from '../../utils/constants/popupsId';
import Notification from "@/utils/Notification";
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";

export default {
    name: 'FunctionCancelIncident',
    components: {ConfirmPopup, ModalPopup},
    data() {
        return {
            popupsId: popupsId,
            reason: "",
            confirmPopupText: '',
            lastTryDate: null, // Относится к обоим попапам
        }
    },
    emits: ['doCancel'],
    methods: {
        formatDate: formatDate,
        doCancel() {
            if (this.validate()) {
                const date = {comment: this.reason, date: this.lastTryDate};

                this.hideFunction();
                this.hideConfirm();
                this.$emit('doCancel', date);
            }
        },
        validate() {
            if (this.reason.length === 0) {
                Notification.error('Заполните причину отмены');
                return false;
            }

            return true;
        },
        showFunction() {
            this.hideConfirm();

            PopupUtils.show(popupsId.FUNCTION_CANCEL_INCIDENT);
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_CANCEL_INCIDENT);

            // Очистить его данные (но всё, кроме даты, нужно для попапа с подверждением, поэтому не трогаем)
            this.lastTryDate = null;
        },
        showConfirm(message, date, reason = '') {
            this.hideFunction();

            // Данные для попапа
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе. Если не переданы - не затираем текущие
            this.lastTryDate = date;
            if (reason) {
                this.reason = reason;
            }

            PopupUtils.show(popupsId.CONFIRM_CANCEL_INCIDENT);
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_CANCEL_INCIDENT);

            // Очистить его данные
            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideFunction();
        },
    }
}
</script>
