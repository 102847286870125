import TicketFilterApi from "@/modules/api/TicketFilter";

export default {
    namespaced: true,
    state: {
        filterInit: null
    },
    getters: {
        filterInit: (state) => {
            return state.filterInit;
        }
    },
    mutations: {
        setFilterInit: (state, payload) => {
            state.filterInit = payload;
        },
    },
    actions: {
        getFilterInit: async (context) => {
            const result = await TicketFilterApi.getFilterInit();
            if (result.isSuccess()) {
                context.commit('setFilterInit', result.getData());
            }
        },
        applyTicketFilter: async (context, payload) => {
            return await TicketFilterApi.applyTicketFilter(payload);
        },
    }
}
