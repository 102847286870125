export default class PopupUtils {

    static show(id) {
        document.dispatchEvent(new CustomEvent('ShowPopup', {detail: id}));
    }

    static hide(id) {
        document.dispatchEvent(new CustomEvent('HidePopup', {detail: id}));
    }

    static isAnyPopupShown() {
        return document.querySelectorAll('.modal.show').length > 0;
    }

}
