import {createApp} from 'vue';
import {store} from "@/store";
import CreateTicket from "@/components/ticket/create/CreateTicket.vue";
import TicketFromTicketsBtn from "@/components/ticket/create/StartButton/FromTickets.vue";
import TicketFromServiceBtn from "@/components/ticket/create/StartButton/FromService.vue";
import TicketFromTvChannelBtn from "@/components/ticket/create/StartButton/FromTvChannel.vue";
import TicketFromDraftBtn from "@/components/ticket/create/StartButton/FromDraft.vue";
import TicketFilter from "@/components/filters/TicketFilter.vue";
import CustomReportFilter from "@/components/filters/CustomReportFilter.vue";
import OperationalReportFilter from "@/components/filters/OperationalReportFilter.vue";
import FromUserFilter from "@/components/filters/StartButton/FromUserFilter.vue";
import TicketHeader from "@/components/ticket/detailTicket/TicketHeader.vue";
import DetailTicketPage from "@/components/ticket/detailTicket/detailTicketPage.vue";
import UserCustomReport from "@/components/filters/StartButton/UserCustomReport.vue";
import UserOperationalReport from "@/components/filters/StartButton/UserOperationalReport.vue";
import ServiceConsumedHistoryPopup from "@/components/services/history/HistoryPopup.vue";
import ShowServiceConsumedHistoryPopupButton from "@/components/services/history/ShowHistoryButton.vue";

export default {
    init() {
        this.initCreateTicketApp();
        this.initCreateTicketButtons();

        this.initTicketFilterApp();
        this.initFilterTicketButtons();

        this.initCustomReportFilterApp();
        this.initCustomReportFilterButtons();

        this.initOperationalReportFilterApp();
        this.initOperationalReportFilterButtons();

        this.initTicketHeaderApp();
        this.initDetailTicketPageApp();

        this.initServiceConsumedHistoryPopupApp();
        this.initShowServiceConsumedHistoryPopupButtonApp();
    },
    initCreateTicketApp() {
        window.CreateTicketApp = this.createApp('create-ticket', CreateTicket);
    },
    initTicketFilterApp() {
        window.TicketFilterApp = this.createApp('ticket-filter', TicketFilter);
    },
    initCustomReportFilterApp() {
        window.CustomReportFilterApp = this.createApp('custom-report-filter', CustomReportFilter);
    },
    initOperationalReportFilterApp() {
        window.OperationalReportFilterApp = this.createApp('operational-report-filter', OperationalReportFilter);
    },
    initTicketHeaderApp() {
        window.ticketHeaderApp = this.createApp('ticket-header', TicketHeader);
    },
    initDetailTicketPageApp() {
        window.DetailTicketPageApp = this.createApp(
            'detail-ticket-page',
            DetailTicketPage,
            {header: window.ticketHeaderApp}
        );
    },
    initCreateTicketButtons() {
        this.createApp('create-ticket-from-tickets', TicketFromTicketsBtn);
        this.createApp('create-ticket-from-service', TicketFromServiceBtn);
        this.createApp('create-ticket-from-channel', TicketFromTvChannelBtn);
        this.createApps('create-ticket-from-draft_', TicketFromDraftBtn);
    },
    initFilterTicketButtons() {
        this.createApp('ticket-filter-from-user-filter', FromUserFilter);
    },
    initCustomReportFilterButtons() {
        this.createApp('custom-report-filter-btn', UserCustomReport);
    },
    initOperationalReportFilterButtons() {
        this.createApp('operational-report-filter-btn', UserOperationalReport);
    },
    initServiceConsumedHistoryPopupApp() {
        window.ServiceConsumedHistoryPopup = this.createApp('service-consumed-history', ServiceConsumedHistoryPopup);
    },
    initShowServiceConsumedHistoryPopupButtonApp() {
        window.ShowServiceConsumedHistoryPopupButton = this.createApp('show-service-consumed-history', ShowServiceConsumedHistoryPopupButton);
    },
    createApp(containerId, component, additionalParams = {}) {
        const container = document.querySelector('#' + containerId);
        let params;

        if (!container) {
            return null;
        }

        try {
            if (container.dataset.params) {
                params = JSON.parse(container.dataset.params);
            } else {
                params = {};
            }
        } catch (e) {
            console.error('Ошибка входных данных ' + component.name, e);
            return null;
        }

        return createApp(component, {...params, ...additionalParams}).use(store).mount('#' + containerId);
    },
    createApps(containerIdPrefix, component) {
        const containers = document.querySelectorAll("[id^='" + containerIdPrefix + "']");

        for (let i in containers) {
            if (!Object.prototype.hasOwnProperty.call(containers, i)) {
                continue;
            }

            this.createApp(containers[i].id, component);
        }
    }
};
