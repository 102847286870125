/**
 * Wait until document is ready to run method
 * @param  {Function} fn Method to run
 */
export default function documentReady(fn) {
    // Sanity check
    if (typeof fn !== 'function') return;

    // If document is already loaded, run method
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
        fn();
        return;
    }

    // Otherwise, wait until document is loaded
    document.addEventListener('DOMContentLoaded', fn, false);
}
