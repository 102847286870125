import CustomReportFilterApi from "@/modules/api/CustomReportFilter";

export default {
    namespaced: true,
    state: {
        customReportFilterInit: null
    },
    getters: {
        customReportFilterInit: (state) => {
            return state.customReportFilterInit;
        }
    },
    mutations: {
        setCustomReportFilterInit: (state, payload) => {
            state.customReportFilterInit = payload;
        },
    },
    actions: {
        getCustomReportFilterInit: async (context) => {
            const result = await CustomReportFilterApi.getCustomReportFilterInit();
            if (result.isSuccess()) {
                context.commit('setCustomReportFilterInit', result.getData());
            }
        },
        applyCustomReportFilter: async (context, payload) => {
            return await CustomReportFilterApi.applyCustomReportFilter(payload);
        },
    }
}
