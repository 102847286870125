import OperationalReportFilter from "@/modules/api/OperationalReportFilter";
export default {
    namespaced: true,
    state: {
        operationalReportFilterInit: null
    },
    getters: {
        operationalReportFilterInit: (state) => {
            return state.operationalReportFilterInit;
        }
    },
    mutations: {
        setOperationalReportFilterInit: (state, payload) => {
            state.operationalReportFilterInit = payload;
        },
    },
    actions: {
        getOperationalReportFilterInit: async (context) => {
            const result = await OperationalReportFilter.getOperationalReportFilterInit();
            if (result.isSuccess()) {
                context.commit('setOperationalReportFilterInit', result.getData());
            }
        },
        applyOperationalReportFilter: async (context, payload) => {
            return await OperationalReportFilter.applyOperationalReportFilter(payload);
        },
    }
}
