<template>
    <div class="row divide-x">
        <div class="col">
            <div class="card overflow-auto heigth-500 d-print-height-auto d-print-absolute">
                <div class="card-body divide-y fst-italic fs-5">
                    <div v-for="(comment, index) in commentsList" :key="index"
                         class="row"
                         :class="{'bg-primary-lt': comment.type==='for_customer'}"
                    >
                        <div class="col-5 fw-bold">
                            {{ comment.author.name }}
                        </div>
                        <div class="col-4 fw-bold">
                            {{ comment.title }}
                        </div>
                        <div class="col-3 fw-bold text-end">
                            {{ formatDate(comment.date) }}
                        </div>
                        <div v-html="comment.text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3 d-print-none">
        <div class="col d-flex justify-content-center">
            <div class="btn-list">
                <template v-if="isFilterButtonsShown">
                    <div class="btn" @click="filterForCustomer">С заказчиком</div>
                    <div class="btn" @click="filterForAll">Все сообщения</div>
                </template>
                <div class="btn" @click="print">Распечатать</div>
                <AddCommentButtons
                    :state="state"
                    :permissions="permissions"
                    @show-add-comment-for-all="$emit('showAddCommentForAll')"
                    @show-add-comment-for-customer="$emit('showAddCommentForCustomer')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import {compareCommentsDates} from "@/components/utils/utilsMethods/compareCommentsByDate";
import {formatDate} from "@/components/utils/utilsMethods/dateMethods";
import AddCommentButtons from "@/components/ticket/detailTicket/AddCommentButtons.vue";
import PrintMixin from "@/components/ticket/detailTicket/tabs/PrintMixin";

export default {
    name: 'UserCommentsTab',
    components: {AddCommentButtons},
    mixins: [PrintMixin],
    props: {
        state: {
            type: Object,
            default: () => ({}),
        },
        permissions: {
            type: Object,
            default: () => ({}),
        },
        ticketNumber: {
            type: String,
            default: '',
        },
        addComment: {
            type: Object,
            default: () => ({})
        },
    },
    emits: ['reloadPageData', 'showAddCommentForAll', 'showAddCommentForCustomer'],
    data() {
        return {
            activeFilter: ''
        }
    },
    computed: {
        allComments() {
            const separate = this.state.comments?.separate || [];
            const builtIn = this.state.comments?.builtIn || [];

            return separate.concat(builtIn);
        },
        isFilterButtonsShown() {
            return !!this.allComments.find(comment => comment.type === 'for_customer') && !!this.allComments.find(comment => comment.type === 'for_all');
        },
        commentsList() {
            let comments = _.cloneDeep(this.allComments).sort(this.compareCommentsDates);
            if (this.isFilterButtonsShown && this.activeFilter) {
                comments = comments.filter(comment => comment.type === this.activeFilter);
            }

            return comments;
        }
    },
    methods: {
        formatDate,
        compareCommentsDates,
        filterForCustomer() {
            this.activeFilter = 'for_customer';
        },
        filterForAll() {
            this.activeFilter = '';
        },
    }
}
</script>
