import Tickets from "@/modules/api/Tickets";

export default {
    namespaced: true,
    state: {
        fieldPermissions: null
    },
    getters: {
        fieldPermissions: (state) => {
            return state.fieldPermissions;
        }
    },
    mutations: {
        setFieldPermissions: (state, payload) => {
            state.fieldPermissions = payload;
        },
    },
    actions: {
        getFieldPermissions: async (context) => {
            const result = await Tickets.getFieldPermissions();
            if (result.isSuccess()) {
                context.commit('setFieldPermissions', result.getData());
            }
        }
    }
}
