export default class TicketFieldPermissions {

    static READ = 'READ';
    static ADD = 'ADD';
    static UPDATE = 'UPDATE';
    static REQUIRED = 'REQUIRED';

    constructor(permissions) {
        this.permissions = {};
        for (let i in permissions){
            this.permissions[permissions[i].field] = permissions[i].allowedActions;
        }
    }

    canRead(field) {
        return this.permissions[field] && this.permissions[field].includes(TicketFieldPermissions.READ);
    }

    canAdd(field) {
        return this.permissions[field] && this.permissions[field].includes(TicketFieldPermissions.ADD);
    }

    canUpdate(field) {
        return this.permissions[field] && this.permissions[field].includes(TicketFieldPermissions.UPDATE);
    }

    isRequired(field) {
        return this.permissions[field] && this.permissions[field].includes(TicketFieldPermissions.REQUIRED);
    }

}
