<template>
    <div class="row divide-x">
        <div class="col">
            <div class="card overflow-auto heigth-300 d-print-height-auto">
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-vcenter card-table">
                            <thead>
                            <tr>
                                <th v-for="field in Object.keys(fields)" :key="field" class="text-center">{{ fields[field] }}</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" :key="item">
                                    <template v-for="field in Object.keys(fields)" :key="field">
                                        <td v-if="field === fieldsNames.HISTORY_TAB_FROM || field === fieldsNames.HISTORY_TAB_TO" class="text-center">{{ formatDate(item[field]) }}</td>
                                        <td v-else-if="field === fieldsNames.HISTORY_TAB_DURATION" class="text-center">{{ calculateTimeDuration(item[fieldsNames.HISTORY_TAB_FROM], item[fieldsNames.HISTORY_TAB_TO]) }}</td>
                                        <td v-else :class="{'fw-bold': (item.emphasise && item.emphasise.includes(field))}" class="text-center">{{ item[field]?.title? item[field].title : item[field]?.name }}</td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col">
            <div class="row">
                <div class="col text-end">Общая продолжительность:</div>
                <div class="col"><a href="#">{{ secondsToTimeFormat(time.allSeconds) }}</a></div>
            </div>
            <div class="row">
                <div class="col text-end">Время недоступности услуги:</div>
                <div class="col"><a href="#">{{ secondsToTimeFormat(time.unavailableSeconds) }}</a></div>
            </div>
            <div class="row">
                <div class="col text-end">В зоне ответственности клиента:</div>
                <div class="col"><a href="#">{{ secondsToTimeFormat(time.customerSeconds) }}</a></div>
            </div>
        </div>
    </div>

    <div class="row mt-4 d-print-none">
        <div class="col d-flex justify-content-center">
            <div class="btn" @click="print">Распечатать</div>
        </div>
    </div>
</template>

<script>
import {calculateTimeDuration, secondsToTimeFormat, padStart, formatDate} from '@/components/utils/utilsMethods/dateMethods';
import * as fieldsNames from "../../../utils/constants/fieldNames";
import PrintMixin from "@/components/ticket/detailTicket/tabs/PrintMixin";
export default {
    name: 'HistoryTab',
    mixins: [PrintMixin],
    props: {
        permissions: {
            type: Object,
            default: () => ({}),
        },
        state: {
            type: Object,
            default: () => ({}),
        },
        ticketNumber: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            fields: {
                subjectState: 'Состояние услуги',
                state: 'Состояние TT',
                assignee: 'Ответственный специалист',
                assigneeGroup: 'Группа специалиста',
                from: 'Начало',
                to: 'Окончание',
                duration: 'Продолжительность',
            },

            fieldsNames: fieldsNames,
        }
    },
    emits: ['reloadPageData'],
    methods: {
        secondsToTimeFormat,
        calculateTimeDuration,
        padStart,
        formatDate
    },
    computed: {
        items() {
            return this.state.history.items;
        },
        time() {
            return this.state.history.time;
        }
    }
}
</script>
