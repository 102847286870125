import {toFormData} from "@/components/utils/utilsMethods/toFormData";

export default class TicketDraft {

    constructor(fields) {
        this.subjectState = fields.subjectState;
        this.priority = fields.priority;
        this.informationSource = fields.informationSource;
        this.informationSourceContact = fields.informationSourceContact;
        this.coordinatorGroup = fields.coordinatorGroup;
        this.assigneeGroup = fields.assigneeGroup;
        this.coordinator = fields.coordinator;
        this.assignee = fields.assignee;
        this.previewText = fields.previewText;
        this.detailText = fields.detailText;
        this.files = fields.files;
        this.storedFiles = fields.storedFiles;
        this.service = fields.service;
        this.channel = fields.channel;
        this.infrastructureObject = fields.infrastructureObject;
        this.number = fields.number;

        if (fields.manualService) {
            this.setManualService(
                fields.manualService.customer, fields.manualService.supplier,
                fields.manualService.service, fields.manualService.contract
            );
        }

        if (fields.manualInfrastructure) {
            this.setManualInfrastructure(
                fields.manualInfrastructure.name, fields.manualInfrastructure.linkNode, fields.manualInfrastructure.type
            );
        }
    }

    setManualService(customer, supplier, service, contract) {
        this.manualService = {
            customer,
            supplier,
            service,
            contract
        }

        return this;
    }

    setManualInfrastructure(name, linkNode, type) {
        this.manualInfrastructure = {
            name,
            linkNode,
            type
        }

        return this;
    }

    setSubjectState(value) {
        this.subjectState = value;
    }

    setPriority(value) {
        this.priority = value;
    }

    setInformationSource(value) {
        this.informationSource = value;
    }

    setInformationSourceContact(value) {
        this.informationSourceContact = value;
    }

    setCoordinatorGroup(value) {
        this.coordinatorGroup = value;
    }

    setAssigneeGroup(value) {
        this.assigneeGroup = value;
    }

    setCoordinator(value) {
        this.coordinator = value;
    }

    setAssignee(value) {
        this.assignee = value;
    }

    setPreviewText(value) {
        this.previewText = value;
    }

    setDetailText(value) {
        this.detailText = value;
    }

    setFiles(value) {
        this.files = value;
    }

    setStoredFiles(value) {
        this.storedFiles = value;
    }

    toFormData() {
        return toFormData(this, 'draft', {'storedFiles': ['url']}, ['files']);
    }
}
