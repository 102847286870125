<template>
    <div @click="run" class="btn" :class="classes" id="ticket-filter-btn">Условия отбора учётных записей</div>
</template>

<script>
import {defineComponent} from 'vue';
import TicketFilter from "@/modules/TicketFilter";

export default defineComponent({
    name: 'FromUserFilter',
    props: {
        classes: {
            type: String
        },
    },
    data() {
        return {}
    },
    methods: {
        run() {
            TicketFilter.runFromUserFilter();
        }
    },
});
</script>
