const compareCommentsDates = (comment1, comment2) => {
    const date1 = new Date(comment1.date);
    const date2= new Date(comment2.date);

    if (date1 > date2) {
        return -1;
    } else if (date1 < date2) {
        return 1;
    }
    return 0;
}

export { compareCommentsDates }
