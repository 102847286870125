<template>
    <div class="row">
        <div class="col">
            <div class="mb-3">В подписке на данный инцидент принимают участие:</div>
            <div class="mb-3">
                <h3 class="mb-0">Фиксированная подписка:</h3>
                <div
                    v-for="fixedSubscriber in state.subscription.fixed"
                    :key="fixedSubscriber.id"
                >
                    {{ fixedSubscriber.name }} - {{ fixedSubscriber.description }}
                </div>
            </div>

            <div>
                <h3 class="mb-0">Дополнительная подписка:</h3>
                <div
                    v-for="additionalSubscriber in state.subscription.additional"
                    :key="additionalSubscriber.id"
                >
                    {{ additionalSubscriber.name }}
                    -
                    {{ additionalSubscriber.description }}
                    <a :href="`mailto:${additionalSubscriber.email}`">{{ additionalSubscriber.email }}</a>
                    <template v-if="canDeleteSubscriber.includes(additionalSubscriber.id)">
                        (<a @click.prevent="$refs.deleteSubscriber.show(additionalSubscriber)" href="javascript:void(0)">удалить</a>)
                    </template>
                </div>
                <div class="row mt-3" v-if="canAddSubscriber">
                    <div class="col d-flex justify-content-center">
                        <div class="btn-list">
                            <div class="btn" @click="$refs.addUserSubscriber.showAdd()">Добавить пользователя</div>
                            <div class="btn" @click="$refs.addGroupSubscriber.showAdd()">Добавить группу</div>
                            <div class="btn" @click="$refs.addManualSubscriber.showAdd()">Добавить вручную</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <AddManualSubscriber
        v-if="canAddSubscriber"
        ref="addManualSubscriber"
        @add-subscriber="(data) => sendAddSubscriber(data,'addManualSubscriber')"
    />
    <AddGroupSubscriber
        v-if="canAddSubscriber"
        ref="addGroupSubscriber"
        :groups="state.functions?.addSubscriber.groupVariants"
        @add-subscriber="(data) => sendAddSubscriber(data,'addGroupSubscriber')"
    />
    <AddUserSubscriber
        v-if="canAddSubscriber"
        ref="addUserSubscriber"
        :groups="state.functions?.addSubscriber.groupVariants"
        :users="state.functions?.addSubscriber.userVariants"
        @add-subscriber="(data) => sendAddSubscriber(data,'addUserSubscriber')"
    />
    <DeleteSubscriber
        ref="deleteSubscriber"
        @delete-subscriber="sendDeleteSubscriber"
    />

</template>

<script>
import * as popupsId from '../../../utils/constants/popupsId';
import {mapActions} from "vuex";
import AddManualSubscriber from "@/components/ticket/detailTicket/popups/AddSubscriber/AddManualSubscriber.vue";
import AddGroupSubscriber from "@/components/ticket/detailTicket/popups/AddSubscriber/AddGroupSubscriber.vue";
import AddUserSubscriber from "@/components/ticket/detailTicket/popups/AddSubscriber/AddUserSubscriber.vue";
import DeleteSubscriber from "@/components/ticket/detailTicket/popups/DeleteSubscriber.vue";

export default {
    name: 'SubscriptionTab',
    components: {DeleteSubscriber, AddUserSubscriber, AddGroupSubscriber, AddManualSubscriber},
    props: {
        state: {
            type: Object,
            default: () => ({}),
        },
        permissions: {
            type: Object,
            default: () => ({}),
        },
        ticketNumber: {
            type: String,
            default: '',
        }
    },
    data() {
      return {
          subscriberDeletingInfo: {},
          popupsId: popupsId
      }
    },
    emits: ['reloadPageData'],
    methods: {
        ...mapActions('detailTicketPage', [
            'deleteSubscriber',
            'addSubscriber'
        ]),
        async sendDeleteSubscriber(subscriber) {
            const result = await this.deleteSubscriber({id: subscriber.id, ticketNumber: this.ticketNumber});

            if (!result.isSuccess()) {
                this.$refs.deleteSubscriber.finish();
                return;
            }

            this.$emit('reloadPageData');
        },
        async sendAddSubscriber(data, componentName) {
            const result = await this.addSubscriber({
                ...data,
                ticketNumber: this.ticketNumber
            });

            if (!result.isSuccess()) {
                this.$refs[componentName].finish();
            }

            if (result.data['needConfirmation']) {
                this.$refs[componentName].showConfirm();
            } else {
                this.$refs[componentName].finish();
                this.$emit('reloadPageData');
            }
        },
    },
    computed: {
        canAddSubscriber() {
            return this.permissions.actions?.includes('addSubscriber');
        },
        canDeleteSubscriber() {
            return this.permissions.options?.subscription.canDelete || [];
        },
    }
}
</script>
