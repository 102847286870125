export const CUSTOMER='CUSTOMER';
export const CONTRACT_NUMBER='CONTRACT_NUMBER';
export const REQUEST='REQUEST';
export const SUPPLIER_COMPANY='SUPPLIER_COMPANY';
export const RESOURCES='RESOURCES';
export const CHARACTERISTICS='CHARACTERISTICS';
export const SUBJECT_STATE='SUBJECT_STATE';
export const PRIORITY='PRIORITY';
export const INFORMATION_SOURCE='INFORMATION_SOURCE';
export const SOURCE_USER='SOURCE_USER';
export const COORDINATOR_GROUP='COORDINATOR_GROUP';
export const COORDINATOR='COORDINATOR';
export const ASSIGNEE_GROUP='ASSIGNEE_GROUP';
export const ASSIGNEE='ASSIGNEE';
export const CURATOR_GROUP='CURATOR_GROUP';
export const CURATOR='CURATOR';
export const PREVIEW_TEXT='PREVIEW_TEXT';
export const DETAIL_TEXT='DETAIL_TEXT';
export const CONSUMED_SERVICE='CONSUMED_SERVICE';
export const CONSUMED_SERVICE_CHANNEL='CONSUMED_SERVICE_CHANNEL';
