<template>
    <div @click="run" class="btn" id="operational-report-filter-btn">Параметры формирования оперативного отчёта</div>
</template>

<script>
import {defineComponent} from 'vue';
import OperationalReportFilter from "@/modules/OperationalReportFilter";

export default defineComponent({
    name: 'UserOperationalReport',
    props: {},
    data() {
        return {}
    },
    methods: {
        run() {
            OperationalReportFilter.runOperationalReportFilter();
        }
    },
});
</script>
