<template>
    <ModalPopup :id="popupId"
                title="История изменений карточки SDB"
                size="xl">
        <div class="divide-y">
            <div class="row" v-for="(item,index) in historyItems" :key="index">
                <div class="col-4 h3">
                    <span>{{ item.author.name }} ({{ item.author.email }})</span>
                </div>
                <div class="col-4 h3 text-center">
                    <span>{{ item.title }}</span>
                </div>
                <div class="col-4 h3 text-end">
                    <span>{{ formatDate(item.date) }}</span>
                </div>
                <div class="col-8 d-flex flex-column" v-html="item.text"></div>
            </div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from "vue";
import {mapActions, mapGetters} from 'vuex';
import PopupUtils from "@/utils/PopupUtils";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {formatDate} from "@/components/utils/utilsMethods/dateMethods";

export default defineComponent({
    name: 'HistoryPopup',
    components: {ModalPopup},
    props: {
        serviceConsumedId: {
            type: Number
        },
        serviceConsumedTvChannelId: {
            type: Number
        }
    },
    data() {
        return {
            popupId: 'used-services-card-history-modal'
        };
    },
    methods: {
        ...mapActions('serviceHistory', [
            'getServiceConsumedHistory',
            'getServiceConsumedTvChannelHistory',
        ]),
        formatDate,

        async run() {
            if (!this.serviceConsumedId) {
                return;
            }

            if (this.isTvChannelHistory && !this.serviceConsumedTvChannelReady) {
                await this.getServiceConsumedTvChannelHistory({
                    serviceId: this.serviceConsumedId,
                    tvChannelId: this.serviceConsumedTvChannelId
                });
            }
            if (!this.isTvChannelHistory && !this.serviceConsumedReady) {
                await this.getServiceConsumedHistory(this.serviceConsumedId);
            }

            PopupUtils.show(this.popupId);
        },
    },
    computed: {
        ...mapGetters('serviceHistory', [
            'serviceConsumedHistory',
            'serviceConsumedTvChannelHistory',
        ]),

        isTvChannelHistory() {
            return !!this.serviceConsumedTvChannelId;
        },

        serviceConsumedReady(){
            return !!this.serviceConsumedHistory;
        },

        serviceConsumedTvChannelReady() {
            return !!this.serviceConsumedTvChannelHistory;
        },

        historyItems() {
            return (this.isTvChannelHistory ? this.serviceConsumedTvChannelHistory : this.serviceConsumedHistory)
                || [];
        }
    }
});
</script>
