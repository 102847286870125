<template>
    <a @click="run" href="javascript:void(0)">{{ draftNumber }}</a>
</template>

<script>
import {defineComponent} from 'vue';
import CreateTicket from "@/modules/CreateTicket";

export default defineComponent({
    name: 'FromDraft',
    props: {
        draftNumber: {
            type: String
        },
    },
    data() {
        return {}
    },
    mounted() {
        document.addEventListener('OpenDraft', (e) => {
          if (this.draftNumber === e.detail) {
            this.run();
          }
        }, false);
    },
    methods: {
        run() {
            if (this.draftNumber) {
                CreateTicket.runWithDraft(this.draftNumber);
            }
        }
    }
});
</script>
