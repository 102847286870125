<template>
    <ModalPopup :id="id"
                title="Регистрация ТТ. Выбор объекта влияния"
                size="xl"
                :is-forced="true">
        <div class="row">
            <div class="col">
                <div class="input-icon">
                            <span class="input-icon-addon">
                                <i class="ti ti-search"></i>
                            </span>
                    <frontend-search
                        :items="infrastructureObjects"
                        :searchable-fields="searchableInfrastructureObjectsFields"
                        @filtered="filteredInfrastructureObjects=$event"
                    />
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="overflow-y-auto popup-inner-container">
                    <div class="accordion">

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#equipment"
                                        aria-expanded="false">
                                    <h3>Оборудование</h3>
                                </button>
                            </h2>
                            <div id="equipment" class="accordion-collapse collapse"
                                 data-bs-parent="#accordion-example">
                                <div class="accordion-body p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter text-nowrap datatable table-bordered">
                                            <thead>
                                            <tr>
                                                <th class="col text-center">Наименование оборудования (ID)
                                                </th>
                                                <th class="col text-center">Расположение (на каком узле)
                                                </th>
                                                <th class="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in filteredEquipment" :key="item.id">
                                                <td class="text-center">{{ item.name }}</td>
                                                <td class="text-center">{{ item.linkNode }}</td>
                                                <td class="w-1">
                                                    <button
                                                        @click="chooseInfrastructureObject(item)"
                                                        class="btn">Выбрать
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mb-3 mt-3">
                                        <div class="col d-flex justify-content-center">
                                            <div class="btn-list">
                                                <button v-if="manualAllowed"
                                                        @click="chooseManual(infrastructureObjectTypes.EQUIPMENT)"
                                                        class="btn">
                                                    Ручной ввод объекта влияния (оборудование)
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#link-channel"
                                        aria-expanded="false">
                                    <h3>Каналы связи</h3>
                                </button>
                            </h2>
                            <div id="link-channel" class="accordion-collapse collapse"
                                 data-bs-parent="#accordion-example">
                                <div class="accordion-body p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter text-nowrap datatable table-bordered">
                                            <thead>
                                            <tr>
                                                <th class="col text-center">Наименование канала связи (ID)
                                                </th>
                                                <th class="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in filteredLinkedChannels" :key="item.id">
                                                <td class="text-center">{{ item.name }}</td>
                                                <td class="w-1">
                                                    <button
                                                        @click="chooseInfrastructureObject(item)"
                                                        class="btn">Выбрать
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mb-3 mt-3">
                                        <div class="col d-flex justify-content-center">
                                            <div class="btn-list">
                                                <button v-if="manualAllowed"
                                                        @click="chooseManual(infrastructureObjectTypes.LINK_CHANNEL)"
                                                        class="btn">
                                                    Ручной ввод объекта влияния (канал связи)
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#link-node"
                                        aria-expanded="false">
                                    <h3>Узлы связи</h3>
                                </button>
                            </h2>
                            <div id="link-node" class="accordion-collapse collapse"
                                 data-bs-parent="#accordion-example">
                                <div class="accordion-body p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter text-nowrap datatable table-bordered">
                                            <thead>
                                            <tr>
                                                <th class="col text-center">Наименование узла связи (ID)
                                                </th>
                                                <th class="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in filteredLinkedNodes" :key="item.id">
                                                <td class="text-center">{{ item.name }}</td>
                                                <td class="w-1">
                                                    <button
                                                        @click="chooseInfrastructureObject(item)"
                                                        class="btn">Выбрать
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mb-3 mt-3">
                                        <div class="col d-flex justify-content-center">
                                            <div class="btn-list">
                                                <button v-if="manualAllowed"
                                                        @click="chooseManual(infrastructureObjectTypes.LINK_NODE)"
                                                        class="btn">
                                                    Ручной ввод объекта влияния (узел связи)
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <button class="btn" data-bs-dismiss="modal">Отменить регистрацию</button>
                </div>
            </div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import FrontendSearch from "@/components/utils/FrontendSearch.vue";
import SwitchStepMixin from "@/components/ticket/create/steps/SwitchStepMixin";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as InfrastructureObjectTypes from "@/modules/constants/TicketInfrastructureObjectTypes"

export default defineComponent({
    name: 'SubjectInfrastructure',
    components: {ModalPopup, FrontendSearch},
    props: {
        infrastructureObjects: {
            type: Array
        },
        manualAllowed: {
            type: Boolean
        }
    },
    emits: ['chooseInfrastructureObject', 'chooseManual'],
    mixins: [SwitchStepMixin],
    mounted() {
        this.filteredInfrastructureObjects = this.infrastructureObjects;
    },
    data() {
        return {
            id: 'ticket-create-popup-infrastructure-step-choose-infrastructure',
            searchableInfrastructureObjectsFields: {
                'name': 'name',
                'linkNode': 'linkNode',
            },
            filteredInfrastructureObjects: [],
            infrastructureObjectTypes: InfrastructureObjectTypes
        };
    },
    methods: {

        chooseInfrastructureObject(infrastructureObjects) {
            this.$emit('chooseInfrastructureObject', infrastructureObjects);
        },

        chooseManual(type) {
            this.$emit('chooseManual', type);
        },
    },
    computed: {
        filteredEquipment() {
            return this.filteredInfrastructureObjects.filter((item) =>
                item.type.code === this.infrastructureObjectTypes.EQUIPMENT);
        },
        filteredLinkedChannels() {
            return this.filteredInfrastructureObjects.filter((item) =>
                item.type.code === this.infrastructureObjectTypes.LINK_CHANNEL);
        },
        filteredLinkedNodes() {
            return this.filteredInfrastructureObjects.filter((item) =>
                item.type.code === this.infrastructureObjectTypes.LINK_NODE);
        }
    }
});
</script>
