import axios from "axios";
import defaultConfig from "@/modules/api/ApiDefaultConfig";
import ApiResult from "@/utils/ApiResult";

export default class {

    static apiPrefix = '/api/ServiceConsumed/'

    static async getServiceConsumedHistory(id) {
        const {data} = await axios.get(this.apiPrefix + `ServiceConsumedHistory?id=${id}`, defaultConfig());
        return ApiResult.process(data);
    }

    static async getServiceConsumedTvChannelHistory(serviceId, tvChannelId) {
        const {data} = await axios.get(
            this.apiPrefix + `ServiceConsumedTvChannelHistory?serviceId=${serviceId}&tvChannelId=${tvChannelId}`,
            defaultConfig()
        );
        return ApiResult.process(data);
    }
}
