<template>
    <input @change="addFile($event)"
           type="file" :id="id" :key="reRender" class="form-control hidden-file-input">
    <label :for="id">
        <a href="javascript:void(0)">{{ label }}</a>
    </label>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'AddFile',
    props: {
        id: {
            type: String
        },
        label: {
            type: String,
            default: "Вложить файл"
        }
    },
    emits: ['addFile'],
    data() {
        return {
            reRender: 0
        };
    },
    methods: {
        addFile(event) {
            const files = event.target.files;

            if (files && files.length && typeof files[0] === 'object') {
                this.$emit('addFile', files[0]);
            }
        },
        clear() {
            this.reRender++;
        }
    }
});
</script>
<style scoped>
.hidden-file-input {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.hidden-file-input:focus + label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.hidden-file-input + label * {
    pointer-events: none;
}

</style>
