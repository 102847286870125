import PopupUtils from "@/utils/PopupUtils";

export default {
    mounted() {
        this.show();
    },
    beforeUnmount() {
        this.hide();
    },
    data() {
        return {
            id: ''
        }
    },
    methods: {
        show() {
            PopupUtils.show(this.id);
        },
        hide() {
            PopupUtils.hide(this.id);
        },
    }
}
