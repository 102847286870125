// попап, который открывается при добавилении нового подписчика из вкладки Подписки на детальной странице тикета
export const CONFIRM_ADDING_SUBSCRIBER_POPUP = 'confirm-adding-subscriber-popup';

// попап, который открывается при удалении подписчика из вкладки Подписки на детальной странице тикета
export const CONFIRM_DELETING_SUBSCRIBER_POPUP = 'confirm-deleting-subscriber-popup';

// попап, который открывается при добавлении подписчика из вкладки Подписки на детальной странице тикета
export const ADDING_USER_SUBSCRIBER_POPUP = 'adding-user-subscriber-popup';
export const ADDING_GROUP_SUBSCRIBER_POPUP = 'adding-group-subscriber-popup';
export const ADDING_MANUAL_SUBSCRIBER_POPUP = 'adding-manual-subscriber-popup';

//
export const CONFIRM_ADDING_COMMENT = 'confirm-adding-comment';

// попап, который открывается при вызове функции добавления нового комментария на вкладке Инцидент на детальной странице Тикета
export const FUNCTION_ADD_COMMENT = 'function-add-comment';
//
export const CONFIRM_TAKING_ASSIGMENT = 'confirm-taking-assigment';

//
export const SUMMARY_TAKING_ASSIGMENT = 'summary-taking-assigment';
export const FUNCTION_CHANGING_PRIORITY = 'function-changing-priority';
export const CONFIRM_CHANGING_PRIORITY = 'confirm-changing-priority';

// Попап отмены инцидента
export const FUNCTION_CANCEL_INCIDENT = 'function-cancel-incident';
export const CONFIRM_CANCEL_INCIDENT = 'confirm-cancel-incident';

export const FUNCTION_SET_ASSIGNEE = 'function-set-assignee';
export const CONFIRM_SET_ASSIGNEE = 'confirm-set-assignee';

export const FUNCTION_ASSIGN_BACK = 'function-assign-back';
export const CONFIRM_ASSIGN_BACK = 'confirm-assign-back';

export const FUNCTION_CONFIRM_RECOVERY = 'function-confirm-recovery';
export const CONFIRM_CONFIRM_RECOVERY = 'confirm-confirm-recovery';

export const FUNCTION_CLOSE = 'function-close';
export const FUNCTION_CLOSE_ASK = 'function-close-ask';
export const CONFIRM_CLOSE = 'confirm-close';

export const FUNCTION_CHANGE_FIELDS = 'function-change-fields';
export const CONFIRM_CHANGE_FIELDS = 'confirm-change-fields';
export const CONFIRM_UNSET_MASTER = 'confirm-unset-master';

// Попапы, доступ к которым зависит от доступа к функциям.
// Для механизма, который заново открывает попап, который был открыт на прошлом хите
export const FUNCTION_POPUP_PERMISSIONS = {
    'takeAssigned': [
        CONFIRM_TAKING_ASSIGMENT, // SUMMARY_TAKING_ASSIGMENT не блокируем, потому что это результат действия, изменившиеся права не влияют
    ],
    'changePriority': [
        FUNCTION_CHANGING_PRIORITY,
        CONFIRM_CHANGING_PRIORITY
    ],
    'commentForAll': [
        FUNCTION_ADD_COMMENT,
        CONFIRM_ADDING_COMMENT
    ],
    'commentForCustomer': [
        FUNCTION_ADD_COMMENT,
        CONFIRM_ADDING_COMMENT
    ],
    'setAssignee': [
        FUNCTION_SET_ASSIGNEE,
        CONFIRM_SET_ASSIGNEE
    ],
    'assignBack': [
        FUNCTION_ASSIGN_BACK,
        CONFIRM_ASSIGN_BACK
    ],
    'confirmRecovery':[
        FUNCTION_CONFIRM_RECOVERY,
        CONFIRM_CONFIRM_RECOVERY
    ]
};
