<template>
    <span>
    Карточка TT [#{{ ticketNumber }}]
        <span v-if="isMaster" class="text-decoration-underline text-red ms-2">master</span>
        <span v-else-if="isSlave" class="text-decoration-underline text-red ms-2">slave</span>
    </span>
</template>
<script>

export default {
    name: 'TicketHeader',
    components: {},
    props: {
        ticketNumber: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isMaster: false,
            isSlave: false
        }
    },
    methods: {
        setMaster() {
            this.isMaster = true;
        },
        setSlave() {
            this.isSlave = true;
        },
        unsetMaster() {
            this.isMaster = false;
        },
        unsetSlave() {
            this.isSlave = false;
        },
    }
}
</script>
