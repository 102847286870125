<template>
    <ModalPopup :id="popupsId.SUMMARY_TAKING_ASSIGMENT" :is-forced="true"
                title="Уведомление о взятии Инцидента в работу">
        <div v-if="summary && Object.keys(summary).length !== 0">
            <div class="mb-2">Инцидент <b>№{{ ticketNumber }}</b> принят в работу <a
                href="javascript:void(0)">{{ formatDate(summary.date) }}</a></div>
            <div class="mb-2">Специалист: <b>{{ summary.assignee.name }}</b></div>
            <div class="mb-2">Группа: <b>{{ summary.assigneeGroup.title }}</b></div>
            <div class="mb-2">ЦВР: <a href="javascript:void(0)">
                {{ summary.solutionControlTime?.date ? formatDate(summary.solutionControlTime.date) : '' }}
            </a>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn" @click="finish">Принять</div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_TAKING_ASSIGMENT" popup-title="Подтверждение взятия Инцидента в работу">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="acceptConfirm">Всё равно взять в работу</div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>
</template>

<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {formatDate} from "../../utils/utilsMethods/dateMethods";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import * as popupsId from '../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import {mapGetters} from "vuex";

export default {
    name: 'FunctionTakeIncidentAssign',
    components: {ConfirmPopup, ModalPopup},
    props: {
        ticketNumber: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            popupsId: popupsId,
            summary: null,
            confirmPopupText: '',
            confirmTryDate: null,
        }
    },
    emits: ['acceptConfirm'],
    mounted() {
        // Если в прошлый раз один из попапов остался незакрытым, открывает его
        if (this.lastPopup.getId() === popupsId.SUMMARY_TAKING_ASSIGMENT) {
            this.showSummary(this.lastPopup.getData().summary);
        } else if (this.lastPopup.getId() === popupsId.CONFIRM_TAKING_ASSIGMENT) {
            this.showConfirm(this.lastPopup.getData().text, this.lastPopup.getData().date);
        }
    },
    methods: {
        formatDate: formatDate,
        acceptConfirm() {
            const date = this.confirmTryDate;

            // Закрываем все попапы и очищаем данные - дальше все сценарии зависят от обработки события acceptConfirm
            this.hideConfirm();
            this.hideSummary();

            this.$emit('acceptConfirm', date);
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideSummary();
        },
        showSummary(summary) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideConfirm();

            // Данные для этого попапа
            this.summary = summary;

            // Открываем попап
            PopupUtils.show(popupsId.SUMMARY_TAKING_ASSIGMENT);

            // Запомним последний открытый попап и его данные
            this.lastPopup.open(popupsId.SUMMARY_TAKING_ASSIGMENT, {summary: this.summary});
        },
        hideSummary() {
            // Закрыть попап
            PopupUtils.hide(popupsId.SUMMARY_TAKING_ASSIGMENT);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.SUMMARY_TAKING_ASSIGMENT) {
                this.lastPopup.close();
            }

            // Очистить его данные
            this.summary = null;
        },
        showConfirm(message, date) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideSummary();

            // Данные для этого попапа
            this.confirmPopupText = message;
            this.confirmTryDate = date;

            PopupUtils.show(popupsId.CONFIRM_TAKING_ASSIGMENT);

            // Запомним последний открытый попап и его данные
            this.lastPopup.open(popupsId.CONFIRM_TAKING_ASSIGMENT, {
                text: this.confirmPopupText,
                date: this.confirmTryDate
            });
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_TAKING_ASSIGMENT);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.CONFIRM_TAKING_ASSIGMENT) {
                this.lastPopup.close();
            }

            // Очистить его данные
            this.confirmTryDate = null;
            this.confirmPopupText = '';
        }
    },
    computed: {
        ...mapGetters('detailTicketPage', [
            'lastPopup',
        ]),
    }
}
</script>
