<template>
    <ModalPopup :id="popupsId.FUNCTION_ADD_COMMENT"
                :is-forced="true"
                size="lg"
                :title="popupTitle">
        <div class="row mb-3">
            <div class="col">
                <div v-if="showWarning" class="text-center text-red">Внимание! Текст сообщения будет доступен
                    Заказчику!
                </div>
            </div>
        </div>
        <div class="row mb-3" v-if="manageReceiversAllowed">
            <div class="col text-red">
                <template v-if="allNormalReceivers.length > 0">
                    <h3 class="mb-0">Данное сообщение получат:</h3>
                    <div v-for="receiver in allNormalReceivers" :key="receiver">{{ receiver }}</div>
                </template>
                <template v-if="allCopyReceivers.length > 0">
                    <h3 class="mt-2 mb-0">Копию получат:</h3>
                    <div v-for="receiver in allCopyReceivers" :key="receiver">{{ receiver }}</div>
                </template>
            </div>
            <div class="col">
                <label class="form-label">Добавить адресата</label>
                <input type="text" class="form-control" v-model="currentReceiverEmail">

                <label class="mt-2 form-check">
                    <input class="form-check-input" type="checkbox" v-model="isReceiverOfCopy">
                    <span class="form-check-label">Копия</span>
                </label>

                <button class="btn mt-2" @click="addReceiver">Добавить</button>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <label class="form-label">Текст сообщения</label>
                <textarea class="form-control" rows="5" v-model="commentText"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div class="btn" @click="sendMessage">Отправить сообщение</div>
                    <div class="btn" @click="finish">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_ADDING_COMMENT" popup-title="Подтверждение публикации комментария">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="sendMessage">Всё равно опубликовать комментарий</div>
            <div class="btn" @click.prevent="finish">Комментарий потерял актуальность, отменить</div>
        </template>
    </ConfirmPopup>
</template>
<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {defineComponent} from "vue";
import ComponentErrors from "@/utils/ComponentErrors";
import * as regExpressions from "@/components/utils/constants/regularExpressions";
import * as popupsId from '../../utils/constants/popupsId';
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import {mapGetters} from "vuex";
import PopupUtils from "@/utils/PopupUtils";

export default defineComponent({
    name: 'FunctionAddComment',
    components: {ConfirmPopup, ModalPopup},
    props: {
        permissions: {
            type: Object,
            default: () => ({}),
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            // добавление получателя
            currentReceiverEmail: '',
            isReceiverOfCopy: false,

            // данные для функции
            commentText: '',
            receiversNormal: [],
            receiversCopy: [],
            isForCustomer: false,

            // данные для подтверждения
            confirmPopupText: '',
            lastTryDate: null,
            hash: '',

            regExpressions: regExpressions,
            popupsId: popupsId,
        }
    },
    emits: ['sendComment'],
    mounted() {
        const data = this.lastPopup.getData();

        // Если в прошлый раз один из попапов остался незакрытым, открываем его
        if (this.lastPopup.getId() === popupsId.FUNCTION_ADD_COMMENT) {
            this.showFunction(
                data.isForCustomer,
                {
                    commentText: data.commentText,
                    receiversNormal: this.manageReceiversAllowed ? data.receiversNormal : [],
                    receiversCopy: this.manageReceiversAllowed ? data.receiversCopy : [],
                    date: data.date,
                    hash: data.hash,
                },
                {
                    email: data.currentEmail,
                    isReceiverOfCopy: data.isReceiverOfCopy
                }
            );
        } else if (this.lastPopup.getId() === popupsId.CONFIRM_ADDING_COMMENT) {
            this.showConfirm(
                data.isForCustomer,
                data.confirmText,
                {
                    commentText: data.commentText,
                    receiversNormal: this.manageReceiversAllowed ? data.receiversNormal : [],
                    receiversCopy: this.manageReceiversAllowed ? data.receiversCopy : [],
                    date: data.date,
                    hash: data.hash,
                }
            );
        }
    },
    methods: {
        validateEmail() {
            return ComponentErrors.execute([
                [!(this.regExpressions.REG_EXP_EMAIL.test(this.currentReceiverEmail)), 'Заполните email корректно'],
            ]);
        },
        validateForm() {
            return ComponentErrors.execute([
                [!(this.commentText), 'Заполните текст комментария'],
            ]);
        },
        clearServiceFields() {
            this.currentReceiverEmail = '';
            this.isReceiverOfCopy = false;
        },
        addReceiver() {
            if (this.manageReceiversAllowed && this.validateEmail()) {
                // Проверка, что уже есть такой получатель в списке
                // Добавляем в список получателя, если такого еще нет
                // Не допускаем одного получателя в обоих списках
                if (this.isReceiverOfCopy) {
                    if (!this.receiversCopy.includes(this.currentReceiverEmail) &&
                        !this.receiversNormal.includes(this.currentReceiverEmail)) {
                        this.receiversCopy.push(this.currentReceiverEmail);
                    }
                } else {
                    if (!this.receiversNormal.includes(this.currentReceiverEmail)) {
                        this.receiversNormal.push(this.currentReceiverEmail);
                    }
                }
                //записываем данные комментария в localStorage
                this.saveFunctionPopupState();
                this.clearServiceFields();
            }
        },
        formatData() {
            const receiversList = [];
            this.receiversNormal.forEach(receiverEmail => {
                receiversList.push({email: receiverEmail, copy: false})
            });

            this.receiversCopy.forEach(receiverEmail => {
                receiversList.push({email: receiverEmail, copy: true})
            });

            return {
                text: this.commentText,
                additionalReceivers: receiversList,
            }
        },
        sendMessage() {
            if (this.validateForm()) {
                const data = {
                    hash: this.hash,
                    date: this.lastTryDate,
                    ...this.formatData()
                };

                this.hideFunction();
                this.hideConfirm();
                this.$emit('sendComment', this.isForCustomer, data);
            }
        },
        showFunctionForCustomer(functionData = {}, currentEmailData = {}) {
            this.showFunction(true, functionData, currentEmailData);
        },
        showFunctionForAll(functionData = {}, currentEmailData = {}) {
            this.showFunction(false, functionData, currentEmailData);
        },
        showFunction(isForCustomer, functionData = {}, currentEmailData = {}) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideConfirm();

            // Данные для этого попапа
            this.isForCustomer = isForCustomer;
            this.commentText = functionData.commentText || '';
            this.receiversNormal = functionData.receiversNormal || [];
            this.receiversCopy = functionData.receiversCopy || [];
            this.lastTryDate = functionData.date;
            this.hash = functionData.hash || '';

            this.currentReceiverEmail = currentEmailData.email || '';
            this.isReceiverOfCopy = currentEmailData.isReceiverOfCopy;

            PopupUtils.show(popupsId.FUNCTION_ADD_COMMENT);

            // Запомним последний открытый попап и его данные
            this.saveFunctionPopupState();
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_ADD_COMMENT);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.FUNCTION_ADD_COMMENT) {
                this.lastPopup.close();
            }

            // Очистить его данные (но всё, кроме даты, нужно для попапа с подверждением, поэтому не трогаем)
            this.lastTryDate = null;
            this.clearServiceFields();
        },
        showConfirm(isForCustomer, message, functionData = {}) {
            this.hideFunction();

            // Данные для попапа
            this.isForCustomer = isForCustomer;
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе. Если не переданы - не затираем текущие
            this.lastTryDate = functionData.date;
            this.hash = functionData.hash || '';

            if (functionData.commentText) {
                this.commentText = functionData.commentText;
            }
            if (functionData.receiversNormal) {
                this.receiversNormal = functionData.receiversNormal;
            }
            if (functionData.receiversCopy) {
                this.receiversCopy = functionData.receiversCopy;
            }

            PopupUtils.show(popupsId.CONFIRM_ADDING_COMMENT);

            // Запомним последний открытый попап и его данные
            this.saveConfirmPopupState();
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_ADDING_COMMENT);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.CONFIRM_ADDING_COMMENT) {
                this.lastPopup.close();
            }

            // Очистить его данные
            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideFunction();
        },
        saveFunctionPopupState() {
            this.lastPopup.open(popupsId.FUNCTION_ADD_COMMENT, {
                isForCustomer: this.isForCustomer,
                commentText: this.commentText,
                date: this.lastTryDate,
                receiversNormal: this.receiversNormal,
                receiversCopy: this.receiversCopy,
                hash: this.hash,
                currentEmail: this.currentReceiverEmail,
                isReceiverOfCopy: this.isReceiverOfCopy,
            });
        },
        saveConfirmPopupState() {
            this.lastPopup.open(popupsId.CONFIRM_ADDING_COMMENT, {
                isForCustomer: this.isForCustomer,
                confirmText: this.confirmPopupText,
                commentText: this.commentText,
                date: this.lastTryDate,
                receiversNormal: this.receiversNormal,
                receiversCopy: this.receiversCopy,
                hash: this.hash,
            });
        }
    },
    computed: {
        ...mapGetters('detailTicketPage', [
            'lastPopup',
        ]),
        staticReceivers() {
            const receivers = this.options[this.optionsKey]?.receivers || [];
            let result = {'normal': [], 'copy': []};

            receivers.forEach(receiver => {
                if (receiver.copy) {
                    result.copy.push(receiver.name);
                } else {
                    result.normal.push(receiver.name);
                }
            });

            return result;
        },
        popupTitle(){
            return this.options[this.optionsKey]?.popupTitle;
        },
        showWarning(){
            return this.options[this.optionsKey]?.showWarning;
        },
        allNormalReceivers() {
            return this.staticReceivers.normal.concat(this.receiversNormal);
        },
        allCopyReceivers() {
            return this.staticReceivers.copy.concat(this.receiversCopy);
        },
        manageReceiversAllowed() {
            return this.permissions?.actions.includes('manageCommentReceivers');
        },
        optionsKey() {
            return this.isForCustomer ? 'commentForCustomer' : 'commentForAll';
        },
    },
    watch: {
        commentText: function () {
            this.saveFunctionPopupState();
        },
        currentReceiverEmail: function () {
            this.saveFunctionPopupState();
        },
        isReceiverOfCopy: function () {
            this.saveFunctionPopupState();
        }
    }
});
</script>
