import ComponentErrors from "@/utils/ComponentErrors";

export default class ApiResult {

    success = true;
    data = null;
    errors = null;

    static process(result) {
        const instance = new ApiResult(result);

        if (instance.isSuccess() && instance.getData()?.redirectTo) {
            window.location.href = instance.getData().redirectTo;
        }

        if (instance.isSuccess() && instance.getData()?.needReload) {
            window.location.reload();
        }

        if (!instance.isSuccess()) {
            instance.showErrors();
        }

        return instance;
    }

    constructor(result) {
        if (!result) {
            this.success = false;
        } else {
            this.data = result.data || null;
            this.errors = result.errors || null;
            this.success = result.success || false;
        }
    }

    showErrors() {
        let errorMessages = [];
        if (this.errors) {
            this.errors.forEach((error) => {
                errorMessages.push([!!error.message, error.message])
            });
        } else {
            errorMessages.push([true, 'Что-то пошло не так. Перезагрузите страницу']);
        }

        return ComponentErrors.execute(errorMessages);
    }

    getData() {
        return this.data;
    }

    isSuccess() {
        return this.success;
    }
}
