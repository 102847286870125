import DetailTicketPageApi from "@/modules/api/DetailTicketPage";
import DetailTicketLastPopup from "@/modules/appState/DetailTicketLastPopup";
import DetailTicketLastTab from "@/modules/appState/DetailTicketLastTab";

export default {
    namespaced: true,
    state: {
        detailTicketPageInfo: null,
        detailTicketPermissionsList: null,
        detailTicketLastPopup: null,
        detailTicketLastTab: null,
    },
    getters: {
        detailTicketPageInfo: (state) => {
            return state.detailTicketPageInfo;
        },
        detailTicketPermissionsList: (state) => {
            return state.detailTicketPermissionsList;
        },
        lastPopup: (state) => {
            return state.detailTicketLastPopup;
        },
        lastTab: (state) => {
            return state.detailTicketLastTab;
        }
    },
    mutations: {
        setDetailTicketPageInfo: (state, payload) => {
            state.detailTicketPageInfo = payload;
        },
        setDetailTicketPermissionsList: (state, payload) => {
            state.detailTicketPermissionsList = payload;
        },
        setDetailTicketLastPopup: (state, payload) => {
            state.detailTicketLastPopup = state.detailTicketLastPopup || payload;
        },
        setDetailTicketLastTab: (state, payload) => {
            state.detailTicketLastTab = state.detailTicketLastTab || payload;
        },
    },
    actions: {
        getDetailTicketPageInfo: async (context, payload) => {
            const result = await DetailTicketPageApi.getDetailTicketInfo(payload);
            if (result.isSuccess()) {
                context.commit('setDetailTicketPageInfo', result.getData());
            }
        },
        getDetailTicketPermissionsList: async (context, payload) => {
            const result = await DetailTicketPageApi.getPermissionsListTicket(payload);
            if (result.isSuccess()) {
                context.commit('setDetailTicketPermissionsList', result.getData());
            }
        },
        deleteSubscriber: async (context, payload) => {
            return await DetailTicketPageApi.deleteSubscriber(payload);
        },
        addSubscriber: async (context, payload) => {
            return await DetailTicketPageApi.addSubscriber(payload);
        },
        initDetailTicketLastPopup: async (context, payload) => {
            context.commit('setDetailTicketLastPopup', new DetailTicketLastPopup(payload));
        },
        initDetailTicketLastTab: async (context, payload) => {
            context.commit('setDetailTicketLastTab', new DetailTicketLastTab(payload));
        },
    }
}
