// Название полей нижней и верхней границ периода
export const HISTORY_TAB_FROM = 'from';
export const HISTORY_TAB_TO = 'to';

// Название поля продолжительности
export const HISTORY_TAB_DURATION = 'duration';

export const CHANGE_FIELDS_COMMENT = 'COMMENT';
export const CHANGE_FIELDS_SUBJECT_STATE = 'SUBJECT_STATE';
export const CHANGE_FIELDS_SOLUTION_CONTROL_TIME = 'SOLUTION_CONTROL_TIME';
export const CHANGE_FIELDS_COORDINATOR_GROUP = 'COORDINATOR_GROUP';
export const CHANGE_FIELDS_COORDINATOR = 'COORDINATOR';
export const CHANGE_FIELDS_ASSIGNEE_GROUP = 'ASSIGNEE_GROUP';
export const CHANGE_FIELDS_ASSIGNEE = 'ASSIGNEE';
export const CHANGE_FIELDS_TAKING_ASSIGNED_CONFIRMED = 'TAKING_ASSIGNED_CONFIRMED';
export const CHANGE_FIELDS_CURATOR_GROUP = 'CURATOR_GROUP';
export const CHANGE_FIELDS_CURATOR = 'CURATOR';
export const CHANGE_FIELDS_EXTERNAL_NUMBER = 'EXTERNAL_NUMBER';
export const CHANGE_FIELDS_RESTORING_CONFIRMED = 'RESTORING_CONFIRMED';
export const CHANGE_FIELDS_AGENT = 'AGENT';
export const CHANGE_FIELDS_IS_MASTER = 'IS_MASTER';
export const CHANGE_FIELDS_MASTER_TICKET = 'MASTER_TICKET';
