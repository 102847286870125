import axios from "axios";
import defaultConfig from "@/modules/api/ApiDefaultConfig";
import ApiResult from "@/utils/ApiResult";

export default class {

    static apiPrefix = '/api/CustomReport/'

    static async getCustomReportFilterInit() {
        const {data} = await axios.get(this.apiPrefix + 'FilterInitials', defaultConfig());
        return ApiResult.process(data);
    }

    static async applyCustomReportFilter(fields) {
        const {data} = await axios.post(this.apiPrefix + 'ApplyFilter', fields, defaultConfig());
        return ApiResult.process(data);
    }
}
