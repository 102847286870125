import PopupUtils from "@/utils/PopupUtils";

export default class PageReloadLoop {
    timeoutSeconds;
    timer;
    leftSeconds;
    delaySeconds;

    constructor(timeoutSeconds) {
        if (!timeoutSeconds) {
            return;
        }

        this.timeoutSeconds = timeoutSeconds;

        document.addEventListener('ShowPopup', () => {
            this.delaySeconds = 0;
        }, false);

        document.addEventListener('HidePopup', () => {
            this.delaySeconds = 3;
        }, false);
    }

    run() {
        this.startTimer();
    }

    startTimer() {
        this.stopTimer();
        this.timer = setInterval(() => {
            if (this.leftSeconds) {
                this.leftSeconds--;
            } else if (this.delaySeconds) {
                this.delaySeconds--;
            }
            this.tryReload();
        }, 1000)
    }

    stopTimer() {
        clearTimeout(this.timer);
        this.resetTimer();
    }

    tryReload() {
        if (!this.leftSeconds && !this.delaySeconds) {
            if (document.hidden) {
                return;
            }

            if (PopupUtils.isAnyPopupShown()) {
                this.resetTimer();
                return;
            }

            this.stopTimer();
            window.location.reload();
        }
    }

    resetTimer() {
        this.leftSeconds = this.timeoutSeconds;
        this.delaySeconds = 0;
    }
}
