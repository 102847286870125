<template>
    <ModalPopup :id="id"
                title="Регистрация ТТ. Выбор объекта влияния"
                :is-forced="true">
        <div class="row">
            <div class="col-auto d-flex align-items-center">
                <label class="form-label m-0">Объект влияния:</label>
            </div>
            <div class="col-5">
                <select type="text" class="form-select js-select-search" v-model="currentSubjectTypeCode">
                    <option value="">Не выбрано</option>
                    <option v-for="subjectType in subjectTypes"
                            :key="subjectType.code"
                            :value="subjectType.code">
                        {{ subjectType.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <button class="btn" @click="choose">Выбрать</button>
                    <button class="btn" data-bs-dismiss="modal">Отменить регистрацию</button>
                </div>
            </div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import ComponentErrors from "@/utils/ComponentErrors";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import SwitchStepMixin from "@/components/ticket/create/steps/SwitchStepMixin";
import ComponentHelper from "@/utils/ComponentHelper";

export default defineComponent({
    name: 'SubjectType',
    components: {ModalPopup},
    props: {},
    emits: ['chooseSubjectType'],
    mixins: [SwitchStepMixin],
    data() {
        return {
            id: 'ticket-create-popup-step-choose-type',
            subjectTypes: {
                service: {
                    code: 'service',
                    name: 'Услуга'
                },
                infrastructure: {
                    code: 'infrastructure',
                    name: 'Инфраструктура'
                },
            },
            currentSubjectTypeCode: ''
        };
    },
    mounted() {
        ComponentHelper.reInitSelects();
    },
    methods: {
        choose() {
            if (this.validate()) {
                this.$emit('chooseSubjectType', this.currentSubjectTypeCode);
            }
        },
        validate() {
            return ComponentErrors.execute([
                [!this.currentSubjectTypeCode, 'Выберите объект влияния']
            ]);
        }
    },
});
</script>
<style>

</style>
