import axios from "axios";
import defaultConfig from "@/modules/api/ApiDefaultConfig";
import ApiResult from "@/utils/ApiResult";
import {toFormData} from "@/components/utils/utilsMethods/toFormData";

export default class {

    static apiPrefix = '/api/TicketFunctions/';

    static async addCommentForCustomer(fields) {
        const {data} = await axios.post(this.apiPrefix + 'CommentForCustomer', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async addCommentForAll(fields) {
        const {data} = await axios.post(this.apiPrefix + 'CommentForAll', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async takeIncidentAssigned(fields) {
        const {data} = await axios.post(this.apiPrefix + 'TakeAssigned', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async changeTicketPriority(fields) {
        const {data} = await axios.post(this.apiPrefix + 'ChangePriority', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async cancelIncident(fields) {
        const {data} = await axios.post(this.apiPrefix + 'Cancel', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async setAssignee(fields) {
        const {data} = await axios.post(this.apiPrefix + 'SetAssignee', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async assignBack(fields) {
        const {data} = await axios.post(this.apiPrefix + 'AssignBack', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async confirmRecovery(fields) {
        const {data} = await axios.post(this.apiPrefix + 'ConfirmRecovery', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async close(fields) {
        let config = defaultConfig(), formData = toFormData(fields, '', {}, ['files']);
        config['Content-Type'] = 'multipart/form-data';

        const {data} = await axios.post(this.apiPrefix + 'Close', formData, config);
        return ApiResult.process(data);
    }

    static async changeFields(fields) {
        let config = defaultConfig(), formData = toFormData(fields, '', {}, ['files']);
        config['Content-Type'] = 'multipart/form-data';

        const {data} = await axios.post(this.apiPrefix + 'ChangeFields', formData, config);
        return ApiResult.process(data);
    }
}
