<template>
    <ModalPopup :id="id"
                title="Регистрация ТТ. Ручной ввод объекта влияния (инфраструктура)"
                :is-forced="true"
                size="xl">
        <div class="mb-3">
            <label class="form-label">Наименование объекта влияния</label>
            <input type="text" class="form-control" v-model="name">
        </div>
        <div class="mb-3" v-if="type===infrastructureObjectTypes.EQUIPMENT">
            <label class="form-label">Узел связи</label>
            <input type="text" class="form-control" v-model="linkNode">
        </div>

        <template v-slot:footer>
            <div class="btn-list">
                <div
                    @click="submitInfrastructure"
                    class="btn">Ввести
                </div>
                <div class="btn" data-bs-dismiss="modal">Отменить регистрацию</div>
            </div>
        </template>

    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import ComponentErrors from "@/utils/ComponentErrors";
import SwitchStepMixin from "@/components/ticket/create/steps/SwitchStepMixin";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as InfrastructureObjectTypes from "@/modules/constants/TicketInfrastructureObjectTypes"

export default defineComponent({
    name: 'ManualInfrastructure',
    components: {ModalPopup},
    props: {
        type:{
            type: String
        }
    },
    emits: ['submitInfrastructure'],
    mixins: [SwitchStepMixin],
    data() {
        return {
            id: 'ticket-create-popup-infrastructure-step-choose-infrastructure-manual',
            name: '',
            linkNode: '',
            infrastructureObjectTypes: InfrastructureObjectTypes
        };
    },
    methods: {
        submitInfrastructure() {
            if (this.validate()) {
                this.$emit('submitInfrastructure', this.name, this.linkNode, this.type);
            }
        },
        validate() {
            return ComponentErrors.execute([
                [!this.name, 'Заполните наименование объекта влияния'],
                [this.type === this.infrastructureObjectTypes.EQUIPMENT && !this.linkNode, 'Заполните узел связи'],
            ]);
        }
    }
});
</script>
