<template>
    <ModalPopup :id="popupsId.ADDING_USER_SUBSCRIBER_POPUP"
                :is-forced="true"
                title="Добавить пользователя в подписку"
                size="lg"
    >
        <form>
            <div class="row">
                <div class="col-4">Группа подписчика:</div>
                <div class="col-4 fw-bold"><a href="javascript:void(0)"
                                              data-modal="#add-user-subscriber-group-popup">
                    {{ currentGroup.title }}
                </a></div>
            </div>

            <div class="row">
                <div class="col-4">Подписчик:</div>
                <div class="col-4 fw-bold"><a href="javascript:void(0)"
                                              data-modal="#add-user-subscriber-popup">
                    {{ currentUser.name }}
                </a></div>
            </div>

            <div class="row">
                <label class="form-label fw-bold">Описание</label>
                <textarea class="form-control" rows="2" v-model="description"></textarea>
            </div>

            <FunctionGroup v-if="groups && groups.length"
                           id="add-user-subscriber-group-popup"
                           :groups="groups"
                           @choose-group="setGroup"
            />

            <ContactFromList
                v-if="users && users.length"
                :contacts="usersForCurrentGroup"
                id="add-user-subscriber-popup"
                @choose-contact="(user) => currentUser=user"
            />
        </form>

        <template v-slot:footer>
            <div class="btn-list">
                <div class="btn" @click.prevent="addSubscriber">Ввести</div>
                <div class="btn" @click.prevent="finish">Отменить регистрацию</div>
            </div>
        </template>
    </ModalPopup>
</template>

<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";
import ComponentErrors from "@/utils/ComponentErrors";
import * as popupsId from '../../../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import FunctionGroup from "@/components/ticket/simpleFieldSetter/FunctionGroup.vue";
import ContactFromList from "@/components/ticket/simpleFieldSetter/ContactFromList.vue";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";

export default {
    name: 'AddUserSubscriber',
    components: {ContactFromList, FunctionGroup, ModalPopup},
    props: {
        groups: {
            type: Array
        },
        users: {
            type: Array
        },
    },
    data() {
        return {
            popupsId: popupsId,
            currentGroup: null,
            currentUser: null,
            description: ''
        }
    },
    emits: ['addSubscriber'],
    created() {
        this.clearData();
    },
    methods: {
        showAdd() {
            this.clearData();
            PopupUtils.show(this.popupsId.ADDING_USER_SUBSCRIBER_POPUP);
        },
        hideAdd() {
            PopupUtils.hide(this.popupsId.ADDING_USER_SUBSCRIBER_POPUP);
            this.clearData();
        },
        clearData() {
            this.resetUser();
            this.resetGroup();
            this.description = '';
        },
        resetGroup() {
            this.currentGroup = {
                code: "",
                title: "Не выбрано"
            };
        },
        resetUser() {
            this.currentUser = {
                code: "",
                name: "Не выбрано"
            }
        },
        finish() {
            this.hideAdd();
        },
        addSubscriber() {
            if (this.validate()) {
                const data = {user: this.currentUser.code, description: this.description};

                this.hideAdd();

                this.$emit('addSubscriber', data);
            }
        },
        validate() {
            return ComponentErrors.execute([
                [!(this.currentUser.code), 'Выберите пользователя'],
            ]);
        },
        getUsersForGroup(groupCode, users) {
            if (!groupCode || !users || !users.length) {
                return [];
            }

            let result = [], user;

            for (let i in users) {
                user = users[i];
                if (user.groups && getObjectByCode(user.groups, groupCode)) {
                    result.push(user);
                }
            }

            return result;
        },
        setGroup(group) {
            this.currentGroup = group;
            this.resetUser();
        }
    },
    computed: {
        usersForCurrentGroup() {
            return this.getUsersForGroup(
                this.currentGroup?.code,
                this.users
            );
        },
    }
}
</script>
