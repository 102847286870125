<template>
    <a @click="run" href="javascript:void(0)" class="btn">Зарегистрировать инцидент</a>
</template>

<script>
import {defineComponent} from 'vue';
import CreateTicket from "@/modules/CreateTicket";

export default defineComponent({
    name: 'FromTvChannel',
    props: {
        id: {
            type: Number
        }
    },
    data() {
        return {}
    },
    methods: {
        run() {
            if (this.id) {
                CreateTicket.runWithChannel(this.id);
            }
        }
    },
});
</script>
