<template>
    <ModalPopup :id="id"
                :title="title"
                :is-forced="true"
                ref="modal">

        <div class="row g-2">
            <div class="col">
                <DateSelect
                    :id-date-picker="id+'-select'"
                    :date-string="initialTime"
                    :date-limit-string="timeFrom"
                    :is-disabled-field="false"
                    type-of-date-range="max"
                    @change-date="setTime"
                />
            </div>
        </div>
        <div class="mt-3 d-flex justify-content-center">
            <div class="btn-list">
                <div class="btn" @click="chooseTime" data-bs-dismiss="modal">Применить и выйти</div>
                <div class="btn" data-bs-dismiss="modal">Выйти без выбора</div>
            </div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import DateSelect from "@/components/utils/DateSelect.vue";

export default defineComponent({
    name: 'SolutionTime',
    components: {DateSelect, ModalPopup},
    props: {
        initialTime: {
            type: String
        },
        timeFrom: {
            type: String
        },
        id: {
            type: String
        },
        title: {
            type: String
        }
    },
    emits: ['chooseTime'],
    data() {
        return {
            currentTime: null
        };
    },
    methods: {
        setTime(time) {
            if (time.length === 16) {
                this.currentTime = time;
            }
        },
        chooseTime() {
            this.$emit('chooseTime', this.currentTime);
        }
    }
});
</script>
