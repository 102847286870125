import CreateTicketApi from "@/modules/api/CreateTicket";

export default {
    namespaced: true,
    state: {
        options: null,
        serviceOptions: null,
        infrastructureObjects: null,
        services: null,

        servicesOnDemand: {},
        channelsOnDemand: {},
        infrastructureObjectsOnDemand: {},


        fieldPermissions: null,
        scenario: null
    },
    getters: {
        options: (state) => {
            return state.options;
        },
        infrastructureObjects: (state) => {
            return state.infrastructureObjects;
        },
        serviceOptions: (state) => {
            return state.serviceOptions;
        },
        services: (state) => {
            return state.services;
        },
        serviceOnDemand: (state) => (id) => {
            return state.servicesOnDemand[id] || null;
        },
        channelOnDemand: (state) => (id) => {
            return state.channelsOnDemand[id] || null;
        },
        infrastructureObjectsOnDemand: (state) => (id) => {
            return state.infrastructureObjectsOnDemand[id] || null;
        },
        fieldPermissions: (state) => {
            return state.fieldPermissions;
        },
        scenario: (state) => {
            return state.scenario;
        }
    },
    mutations: {
        setOptions: (state, payload) => {
            state.options = payload;
        },
        setInfrastructureObjects: (state, payload) => {
            state.infrastructureObjects = payload;
        },
        setServiceOptions: (state, payload) => {
            state.serviceOptions = payload;
        },
        setServices: (state, payload) => {
            state.services = payload;
        },
        setServiceOnDemand: (state, payload) => {
            state.servicesOnDemand[payload.id] = payload;
        },
        setChannelOnDemand: (state, payload) => {
            state.channelsOnDemand[payload.id] = payload;
        },
        setInfrastructureObjectsOnDemand: (state, payload) => {
            state.infrastructureObjectsOnDemand[payload.id] = payload;
        },
        setFieldPermissions: (state, payload) => {
            state.fieldPermissions = payload;
        },
        setScenario: (state, payload) => {
            state.scenario = {
                serviceAllowed: payload.serviceAllowed,
                manualServiceAllowed: payload.manualServiceAllowed,
                infrastructureAllowed: payload.infrastructureAllowed,
                manualInfrastructureAllowed: payload.manualInfrastructureAllowed
            };
        },
    },
    actions: {
        getOptions: async (context, payload) => {
            const result = await CreateTicketApi.getOptions(payload);
            if (result.isSuccess()) {
                context.commit('setOptions', result.getData());
            }
        },
        getInfrastructureObjects: async (context) => {
            const result = await CreateTicketApi.getInfrastructureObjects();
            if (result.isSuccess()) {
                context.commit('setInfrastructureObjects', result.getData());
            }
        },
        getServiceOptions: async (context) => {
            const result = await CreateTicketApi.getServiceOptions();
            if (result.isSuccess()) {
                context.commit('setServiceOptions', result.getData());
            }
        },
        getServices: async (context) => {
            const result = await CreateTicketApi.getServices();
            if (result.isSuccess()) {
                context.commit('setServices', result.getData());
            }
        },
        saveDraft: async (context, payload) => {
            return await CreateTicketApi.saveDraft(payload);
        },
        register: async (context, payload) => {
            return await CreateTicketApi.register(payload);
        },
        saveInfrastructureObject: async (context, payload) => {
            const result = (await CreateTicketApi.saveInfrastructureObject(payload));

            if (result.isSuccess() && result.getData() && result.getData()['id']) {
                return result.getData()['id'];
            }
        },
        demandService: async (context, payload) => {
            const result = await CreateTicketApi.getServiceById(payload);
            if (result.isSuccess() && result.getData() && result.getData().length) {
                context.commit('setServiceOnDemand', result.getData()[0]);
                return result.getData()[0];
            }

            return null;
        },
        demandChannel: async (context, payload) => {
            const result = await CreateTicketApi.getChannelById(payload);
            if (result.isSuccess() && result.getData() && result.getData().length) {
                context.commit('setChannelOnDemand', result.getData()[0]);
                return result.getData()[0];
            }

            return null;
        },
        demandInfrastructureObject: async (context, payload) => {
            const result = await CreateTicketApi.getInfrastructureObjectById(payload);
            if (result.isSuccess() && result.getData() && result.getData().length) {
                context.commit('setInfrastructureObjectsOnDemand', result.getData()[0]);
                return result.getData()[0];
            }

            return null;
        },
        getFieldPermissions: async (context) => {
            const result = await CreateTicketApi.getFieldPermissions();
            if (result.isSuccess()) {
                context.commit('setFieldPermissions', result.getData());
            }
        },
        getScenario: async (context) => {
            const result = await CreateTicketApi.getScenario();
            if (result.isSuccess()) {
                context.commit('setScenario', result.getData());
            }
        },
        getDraft: async (context, payload) => {
            return await CreateTicketApi.getDraft(payload);
        },
    }
}
