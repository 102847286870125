<template>
    <div>
        <div class="row divide-x">
            <div class="col ticket-initials-zone">
                <div class="row mb-3">
                    <div v-if="fieldPermissions.canRead(fields.INFORMATION_SOURCE)" class="col-6">
                        Источник информации: <b>{{ state.informationSource?.title ?? 'Не выбрано' }}</b>
                    </div>
                    <div v-if="fieldPermissions.canRead(fields.SOURCE_USER)" class="col-6">
                        Обратился: <b>{{ state.informationSourceContact ?? 'Не выбрано' }}</b>
                    </div>
                    <div v-if="fieldPermissions.canRead(fields.SUBJECT_TEXT)" class="col-12">
                        Объект влияния: <span v-html="state.subjectText ?? 'Не выбрано'"></span>
                    </div>
                </div>

                <div class="row mb-2">
                    <div v-if="fieldPermissions.canRead(fields.STATUS) && state.isVip" class="col">
                        Статус: <b>VIP</b>
                    </div>
                    <div v-if="fieldPermissions.canRead(fields.SUPPLIER_COMPANY)" class="col">
                        Поставщик: <b>{{ state.supplierName ?? 'Не выбрано' }}</b>
                    </div>
                </div>

                <div class="row mb-3" v-if="state.subjectParams && state.subjectParams.length > 0">
                    <div class="col">
                        <div class="card overflow-auto ticket-initials-zone" style="height: 200px">
                            <div class="card-body p-3">
                                <div v-for="(subject, index) in filteredSubjectParams" :key="index" class="row fs-5">
                                    <div v-if="filteredSubjectParams.length > 1" class="h2 justify-content-center">{{ subject.title }}</div>
                                    <div v-if="fieldPermissions.canRead(fields.RESOURCES) && subject.accessParams.length > 0" class="col-4">
                                        <h3>Параметры доступа:</h3>
                                        <div v-for="(accessParam, index) in subject.accessParams" :key="index"
                                             class="me-auto">{{ accessParam.title }}: <b>{{ accessParam.value }}</b></div>
                                    </div>
                                    <div class="col-8" v-if="fieldPermissions.canRead(fields.CHARACTERISTICS) && subject.specifications.length > 0">
                                        <h3>Характеристики:</h3>
                                        <div v-for="(specification, index) in subject.specifications" :key="index"
                                             class="me-auto">{{ specification.title }} : <b>{{ specification.value }}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3" v-if="fieldPermissions.canRead(fields.PREVIEW_TEXT)">
                    <div class="col">
                        <div class="me-auto">Краткое описание проблемы:</div>
                        <div class="me-auto">
                            <div class="me-auto fw-bold multiline">{{ state.previewText.text ?? 'Не выбрано' }}</div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3" v-if="fieldPermissions.canRead(fields.DETAIL_TEXT)">
                    <div class="col">
                        <div class="row mb-3">
                            <div class="me-auto">Полное описание проблемы:</div>
                            <div class="me-auto">
                                <div class="me-auto fw-bold multiline" v-html="state.detailText.text"></div>
                            </div>
                        </div>

                        <template v-if="state.detailText.attachments?.length">
                            <div class="me-auto">Файлы</div>
                            <div class="me-auto">
                                <div v-for="(attachment, index) in state.detailText.attachments" :key="index"
                                     class="me-auto fw-bold">
                                    <a :href="attachment.url" target=”_blank”>{{ attachment.title }}</a>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

<!--            Ширина прибита гвоздями, чтобы отступы между колонок выглядели одинаково на разных разрешениях-->
            <div style="min-width: 635px" class="col ticket-dynamic-zone">

                <div class="ticket-fixed-zone ticket-fixed-zone-body">
                    <div class="row ps-3">
                        <template v-if="fieldPermissions.canRead(fields.SUBJECT_STATE)">
                            <div :style="{width: colWidths[0] + '%'}" class="px-0">Состояние услуги:</div>
                            <div :style="{width: colWidths[1] + '%'}" class="px-0 fw-bold">
                                {{ state.subjectState.title ?? 'Не выбрано' }}
                            </div>
                        </template>

                        <template v-if="fieldPermissions.canRead(fields.STATE)">
                            <div :style="{width: colWidths[2] + '%'}" class="px-0">Состояние ТТ:</div>
                            <div :style="{width: colWidths[3] + '%'}" class="px-0 fw-bold">
                                {{ state.ticketState.title ?? 'Не выбрано' }}
                            </div>
                        </template>
                    </div>

                    <div v-if="fieldPermissions.canRead(fields.SOLUTION_TARGET_DATE)" class="row ps-3">
                        <div :style="{width: colWidths[0] + '%'}" class="px-0"
                             :class="{'text-red': isSolutionTargetTimeStrongExpired}">Целевое время:
                        </div>
                        <div :style="{width: colWidths[1] + colWidths[2] + '%'}" class="px-0 fw-bold">
                            <a href="#">{{ solutionTargetDate }}</a>
                        </div>
                        <div
                            :style="{width: colWidths[3] + '%'}"
                            class="px-0 fw-bold"
                            :class="{
                        'opacity-50': isSolutionTargetTimePause,
                        'text-green': !isSolutionTargetTimePause && currentTime.solutionTarget > 0,
                        'text-red': !isSolutionTargetTimePause && currentTime.solutionTarget <= 0}"
                        >
                            <span v-if="currentTime.solutionTarget>0">-</span> {{ secondsToTimeFormat(currentTime.solutionTarget) }}
                        </div>
                    </div>
                    <div v-if="fieldPermissions.canRead(fields.SOLUTION_CONTROL_DATE) &&
                    (state.solutionControlTime?.date || state.master?.name)" class="row ps-3">
                        <div :style="{width: colWidths[0] + '%'}" class="px-0">Контрольное время:</div>

                        <template v-if="state.master?.name">
                            <div :style="{width: colWidths[1] + colWidths[2] + '%'}" class="px-0 fw-bold">
                                {{ state.master?.name }}
                            </div>
                        </template>
                        <template v-else>

                            <div :style="{width: colWidths[1] + colWidths[2] + '%'}" class="px-0 fw-bold">
                                <a href="#">{{ formatDate(state.solutionControlTime?.date) }}</a>
                            </div>
                            <div
                                :style="{width: colWidths[3] + '%'}"
                                class="px-0 fw-bold"
                                :class="{
                            'opacity-50': isSolutionControlTimePause,
                            'text-green': !isSolutionControlTimePause && currentTime.solutionControl > 0,
                            'text-red': !isSolutionControlTimePause && currentTime.solutionControl <= 0
                        }"
                            >
                                - {{ secondsToTimeFormat(currentTime.solutionControl) }}
                            </div>
                        </template>
                    </div>
                </div>

                <div class="row ps-3 mt-2" v-if="fieldPermissions.canRead(fields.COORDINATOR_GROUP) || fieldPermissions.canRead(fields.COORDINATOR)">
                    <template v-if="fieldPermissions.canRead(fields.COORDINATOR_GROUP)">
                        <div :style="{width: colWidths[0] + '%'}" class="px-0">Группа координатора:</div>
                        <div :style="{width: colWidths[1] + '%'}" class="px-0 fw-bold">{{ state.coordinatorGroup?.title ?? 'Не назначен' }}</div>
                    </template>
                    <template v-if="fieldPermissions.canRead(fields.COORDINATOR)">
                        <div :style="{width: colWidths[2] + '%'}" class="px-0">Координатор:</div>
                        <div :style="{width: colWidths[3] + '%'}" class="px-0 fw-bold">{{ state.coordinator?.name ?? 'Не назначен' }}</div>
                    </template>
                </div>
                <div v-if="fieldPermissions.canRead(fields.ASSIGNEE_GROUP) || fieldPermissions.canRead(fields.ASSIGNEE)" class="row ps-3 mb-2">
                    <template v-if="fieldPermissions.canRead(fields.ASSIGNEE_GROUP)">
                        <div :style="{width: colWidths[0] + '%'}" class="px-0">Группа ответственного:</div>
                        <div :style="{width: colWidths[1] + '%'}" class="px-0 fw-bold">{{
                                state.master?.name ? 'IMS' : (state.assigneeGroup?.title ?? 'Не назначен')
                            }}</div>
                    </template>
                    <template v-if="fieldPermissions.canRead(fields.ASSIGNEE)">
                        <div :style="{width: colWidths[2] + '%'}" class="px-0">Ответственный:</div>
                        <div :style="{width: colWidths[3] + '%'}" class="px-0 fw-bold">{{
                                state.master?.name ?? state.assignee?.name ?? 'Не назначен'
                            }}</div>
                    </template>
                </div>

                <div v-if="fieldPermissions.canRead(fields.EXTERNAL_NUMBER)" class="row ps-3"
                     :class="{'opacity-50': !state.externalNumber}">
                    <div :style="{width: colWidths[0] + '%'}" class="px-0">Номер внешнего ТТ:</div>
                    <div :style="{width: colWidths[1] + '%'}" class="px-0">{{ state.externalNumber ?? 'Не назначен' }}</div>
                </div>
                <div v-if="fieldPermissions.canRead(fields.CURATOR_GROUP) || fieldPermissions.canRead(fields.CURATOR)"
                     class="row ps-3 mb-2">
                    <template v-if="fieldPermissions.canRead(fields.CURATOR_GROUP)">
                        <div :style="{width: colWidths[0] + '%'}" class="px-0"
                             :class="{'opacity-50': !state.curatorGroup?.title}">Группа куратора:
                        </div>
                        <div :style="{width: colWidths[1] + '%'}" class="px-0"
                             :class="{'opacity-50': !state.curatorGroup?.title}">
                            {{ state.curatorGroup?.title ?? 'Не назначен' }}
                        </div>
                    </template>

                    <template v-if="fieldPermissions.canRead(fields.CURATOR)">
                        <div :style="{width: colWidths[2] + '%'}" class="px-0"
                             :class="{'opacity-50': !state.curator?.name}">Куратор:
                        </div>
                        <div :style="{width: colWidths[3] + '%'}" class="px-0"
                             :class="{'opacity-50': !state.curator?.name}">{{ state.curator?.name ?? 'Не назначен' }}
                        </div>
                    </template>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <div class="card overflow-auto" :style="{height: isCustomer ? '360px' : '240px'}">
                            <div class="card-body p-3 divide-y" v-if="permissions.tabs.includes('allMessages') || permissions.tabs.includes('userComments')">
                                <AllMessages :state="state" :is-show-on-main="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div
                        v-if="permissions.functions.includes('changePriority')"
                        class="btn"
                        @click="$refs.changePriority.showFunction(state.priority.code)"
                    >
                        Изменить приоритет
                    </div>
                    <AddCommentButtons
                        :state="state"
                        :permissions="permissions"
                        @show-add-comment-for-all="$emit('showAddCommentForAll')"
                        @show-add-comment-for-customer="$emit('showAddCommentForCustomer')"
                    />
                    <div v-if="permissions.functions.includes('changeFields')"
                         class="btn" @click="showChangeFields">
                        Изменить состояния
                    </div>
                    <div v-if="permissions.functions.includes('takeAssigned')"
                         class="btn" @click="takeAssigned()">Взять в работу
                    </div>
                    <div v-if="permissions.functions.includes('cancel')"
                        class="btn" @click="$refs.cancel.showFunction()">
                        Отменить
                    </div>
                    <div v-if="permissions.functions.includes('close')"
                         class="btn" @click="showCloseTicket">
                        Закрыть
                    </div>
                    <div v-if="permissions.functions.includes('setAssignee')"
                         class="btn" @click="$refs.setAssignee.showFunction(state.assigneeGroup)">
                        Назначить ответственного
                    </div>
                    <div v-if="permissions.functions.includes('assignBack')"
                         class="btn" @click="$refs.assignBack.showFunction()">
                        Вернуть поставщику
                    </div>
                    <div v-if="permissions.functions.includes('confirmRecovery')"
                         class="btn" @click="$refs.confirmRecovery.showFunction()">
                        Подтвердить состояние услуги
                    </div>
                </div>
            </div>
        </div>
    </div>

    <FunctionTakeIncidentAssign
        ref="takeAssigned"
        :ticket-number="ticketNumber"
        @accept-confirm="takeAssigned"
    />

    <FunctionChangePriority
        ref="changePriority"
        :priorities="state.functions.changePriority?.priorityVariants"
        @choose-priority="sendChangePriority"
    />

    <FunctionCancelIncident
        ref="cancel"
        @do-cancel="sendCancelIncident"
    />

    <FunctionSetAssignee
        ref="setAssignee"
        :users="state.functions.setAssignee?.userVariants"
        :groups="state.functions.setAssignee?.groupVariants"
        @set-assignee="sendSetAssignee"
    />

    <FunctionAssignBack
        ref="assignBack"
        @assign-back="sendAssignBack"
    />

    <FunctionConfirmRecovery
        ref="confirmRecovery"
        @confirm-subject-state="sendSubjectStateConfirmation"
    />

    <FunctionClose
        ref="closeTicket"
        :options="state.functions.close"
        :subject-params="state.functions.close?.subjectParams"
        @close-ticket="sendCloseTicket"
    />

    <FunctionChangeFields
        ref="changeFields"
        :options="state.functions.changeFields"
        :field-permissions="permissions.changeFields"
        @reload-permissions="reloadPermissions"
        @change-fields="sendChangeFields"
    />

</template>

<script>
import {mapActions} from "vuex";
import AllMessages from "@/components/ticket/detailTicket/utilsComponents/AllMessages.vue";
import _ from "lodash";
import FunctionTakeIncidentAssign from "@/components/ticket/functions/FunctionTakeIncidentAssign.vue";
import TicketFieldPermissions from "@/modules/TicketFieldPermissions";
import * as fields from "@/modules/constants/DetailTicketMainTabFields";
import {formatDate, secondsToTimeFormat} from "@/components/utils/utilsMethods/dateMethods";
import FunctionChangePriority from "@/components/ticket/functions/FunctionChangePriority.vue";
import FunctionCancelIncident from "@/components/ticket/functions/FunctionCancelIncident.vue";
import AddCommentButtons from "@/components/ticket/detailTicket/AddCommentButtons.vue";
import FunctionSetAssignee from "@/components/ticket/functions/FunctionSetAssignee.vue";
import FunctionAssignBack from "@/components/ticket/functions/FunctionAssignBack.vue";
import FunctionConfirmRecovery from "@/components/ticket/functions/FunctionConfirmRecovery.vue";
import FunctionClose from "@/components/ticket/functions/FunctionClose.vue";
import FunctionChangeFields from "@/components/ticket/functions/FunctionChangeFields.vue";
import Notification from "@/utils/Notification";

export default {
    name: 'MainTab',
    components: {
        FunctionChangeFields,
        FunctionClose,
        FunctionConfirmRecovery,
        FunctionAssignBack,
        FunctionSetAssignee,
        AddCommentButtons, FunctionCancelIncident, FunctionChangePriority, FunctionTakeIncidentAssign, AllMessages
    },
    props: {
        permissions: {
            type: Object,
            default: () => ({}),
        },
        state: {
            type: Object,
            default: () => ({})
        },
        ticketNumber: {
            type: String,
            default: '',
        },
        addComment: {
            type: Object,
            default: () => ({})
        },
    },
    emits: ['reloadPageData', 'showAddCommentForAll', 'showAddCommentForCustomer', 'reloadPermissions'],
    data() {
        return {
            isCustomer: true, //TODO должно зависеть от наличия данных, а не от роли
            colWidths: [26, 29, 18, 27],
            fields: fields,
            timers: {
                solutionTarget: null,
                solutionControl: null,
            },
            currentTime: {
                solutionTarget: this.getSolutionTargetSeconds(),
                solutionControl: this.getSolutionControlSeconds(),
            }
        }
    },
    mounted() {
        if (!this.isSolutionTargetTimePause) {
            this.startTimer('solutionTarget');
        }
        if (!this.isSolutionControlTimePause && this.state.solutionControlTime) {
            this.startTimer('solutionControl');
        }
    },
    methods: {
        formatDate,
        secondsToTimeFormat,
        ...mapActions('ticketFunctions', [
            'takeIncidentAssigned',
            'changeTicketPriority',
            'cancelIncident',
            'setAssignee',
            'assignBack',
            'confirmRecovery',
            'close',
            'changeFields',
        ]),
        startTimer(timerName) {
            this.stopTimer(timerName);
            this.timers[timerName] = setInterval(() => {
                this.currentTime[timerName]--
            }, 1000)
        },
        stopTimer(timerName) {
            clearTimeout(this.timers[timerName])
        },

        showCloseTicket() {
            // Для этого попапа нужны свежие данные
            this.$emit('reloadPageData');
            this.$refs.closeTicket.showAsk(this.state.assigneeGroup);
        },

        showChangeFields() {
            this.$refs.changeFields.showFunction({
                subjectState: this.state.subjectState,
                assignee: this.state.assignee,
                coordinator: this.state.coordinator,
                curator: this.state.curator,
                assigneeGroup: this.state.assigneeGroup,
                coordinatorGroup: this.state.coordinatorGroup,
                curatorGroup: this.state.curatorGroup,
                externalNumber: this.state.externalNumber,
                informationSourceContact: this.state.informationSourceContact,
                solutionControlTime: this.state.solutionControlTime?.date,
                isMaster: this.state.isMaster,
                master: this.state.master
            })
        },

        async takeAssigned(date) {
            let result = await this.takeIncidentAssigned({ticketNumber: this.ticketNumber, date: date});

            if (!result.isSuccess()) {
                this.$refs.takeAssigned.finish();
                return;
            }

            const data = result.getData();

            if (data['needConfirmation']) {
                this.$refs.takeAssigned.showConfirm(data['message'], data['date']);
                return;
            }

            this.$refs.takeAssigned.showSummary(_.cloneDeep(data));
            this.$emit('reloadPageData');
        },
        processFunctionResult(result, component) {
            if (!result.isSuccess()) {
                component.finish();
                return;
            }

            const response = result.getData();

            if (response['needConfirmation']) {
                component.showConfirm(response['message'], response['date']);
                return;
            }

            if (response['needMasterFlagUnsetConfirmation']) {
                component.showConfirmUnsetMaster(response['message']);
                return;
            }

            if (response['message']) {
                Notification.success(response['message']);
            }

            component.finish();
            this.$emit('reloadPageData');
        },
        reloadPermissions(changedFields) {
            this.$emit('reloadPermissions', changedFields);
        },
        getSolutionTargetSeconds(){
            return typeof this.state.solutionTargetTime?.leftSeconds !== 'undefined'
                ? this.state.solutionTargetTime?.leftSeconds : 0
        },
        getSolutionControlSeconds(){
            return typeof this.state.solutionControlTime?.leftSeconds !== 'undefined'
                ? this.state.solutionControlTime?.leftSeconds : 0
        },
        async sendChangePriority(data) {
            let result = await this.changeTicketPriority({ticketNumber: this.ticketNumber, ...data});

            this.processFunctionResult(result, this.$refs.changePriority);
        },
        async sendSetAssignee(data) {
            let result = await this.setAssignee({ticketNumber: this.ticketNumber, ...data});

            this.processFunctionResult(result, this.$refs.setAssignee);
        },
        async sendCancelIncident(data) {
            let result = await this.cancelIncident({ticketNumber: this.ticketNumber, ...data});

            this.processFunctionResult(result, this.$refs.cancel);
        },
        async sendAssignBack(data) {
            let result = await this.assignBack({ticketNumber: this.ticketNumber, ...data});

            this.processFunctionResult(result, this.$refs.assignBack);
        },
        async sendSubjectStateConfirmation(data) {
            let result = await this.confirmRecovery({ticketNumber: this.ticketNumber, ...data});

            this.processFunctionResult(result, this.$refs.confirmRecovery);
        },
        async sendCloseTicket(data) {
            let result = await this.close({ticketNumber: this.ticketNumber, ...data});

            this.processFunctionResult(result, this.$refs.closeTicket);
        },
        async sendChangeFields(data) {
            let result = await this.changeFields({ticketNumber: this.ticketNumber, ...data});

            this.processFunctionResult(result, this.$refs.changeFields);
        }
    },
    computed: {
        fieldPermissions() {
            if (this.permissions.fields) {
                return new TicketFieldPermissions(this.permissions.fields);
            }

            return null;
        },
        filteredSubjectParams() {
            let result = [], subject;

            for (let i in this.state.subjectParams) {
                if (!Object.prototype.hasOwnProperty.call(this.state.subjectParams, i)) {
                    continue;
                }
                subject = _.cloneDeep(this.state.subjectParams[i]);

                subject.accessParams = subject.accessParams.filter(accessParam => accessParam.value);
                subject.specifications = subject.specifications.filter(specification => specification.value);

                result.push(subject);
            }

            return result;
        },
        solutionTargetDate() {
            return !this.isSolutionTargetTimePause && this.state.solutionTargetTime?.date
                ? formatDate(this.state.solutionTargetTime?.date) : '';
        },
        isSolutionTargetTimePause() {
            return this.state.solutionTargetTime?.pause;
        },
        isSolutionControlTimePause() {
            return this.state.solutionControlTime?.pause;
        },
        isSolutionTargetTimeStrongExpired() {
            return this.state.solutionTargetTime?.leftSeconds <= 0;
        },
    },
    watch: {
        'state.solutionTargetTime': function () {
            this.currentTime.solutionTarget = this.getSolutionTargetSeconds();

            if (this.isSolutionTargetTimePause) {
                this.stopTimer('solutionTarget');
            } else {
                this.startTimer('solutionTarget');
            }
        },
        'state.solutionControlTime': function () {
            this.currentTime.solutionControl = this.getSolutionControlSeconds();
            if (!this.isSolutionControlTimePause && this.currentTime.solutionControl) {
                this.startTimer('solutionControl');
            } else {
                this.stopTimer('solutionControl');
            }
        },
        'currentTime.solutionTarget': function (time) {
            if (time === 0) {
                this.$emit('reloadPageData', {requireFresh: true});
            }
        },
        'currentTime.solutionControl': function(time) {
            if (time === 0 || time === -1) { // -1 как страховка от порченных данных
                this.$emit('reloadPageData', {requireFresh: true});
            }
            if (time <= 0) {
                this.stopTimer('solutionControl')
            }
        },
    }
}
</script>
<style scoped>
.multiline {
    white-space: pre-line;
}
.ticket-fixed-zone-body{
    margin: 0 -10px 0 -16px;
    padding: 0 10px 0 16px
}
</style>
