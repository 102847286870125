<template>
    <ModalPopup :id="id"
                title="Параметры формирования оперативного отчёта"
                size="lg">
        <div v-if="operationalReportFilterInit">
            <div class="mb-3" v-if="isFieldAllowed('period')">
                <label class="form-label">Отчёт за период</label>
                <div class="row g-2">
                    <div class="col">
                        <date-select
                            :date-string="selectedFilters.period.min"
                            id-date-picker="custom-report-date-min"
                            type-of-date-range="min"
                            :date-limit-string="selectedFilters.period.max"
                            @change-date="value => selectedFilters.period.min = value"
                        />
                    </div>
                    <div class="col-auto align-items-center d-flex">
                        -
                    </div>
                    <div class="col">
                        <date-select
                            :date-string="selectedFilters.period.max"
                            id-date-picker="custom-report-date-max"
                            type-of-date-range="max"
                            :date-limit-string="selectedFilters.period.min"
                            @change-date="value => selectedFilters.period.max = value"
                        />
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="isFieldAllowed('subjectType')">
                <div class="form-label">Объект влияния</div>
                <div>
                    <label
                        v-for="subject in operationalReportFilterInit.lists.subjectType"
                        :key="subject.code"
                        class="form-check form-check-inline"
                    >
                        <input class="form-check-input" :value="subject.code" v-model="selectedFilters.subjectType" type="checkbox">
                        <span class="form-check-label">{{ subject.title }}</span>
                    </label>
                </div>
            </div>
            <div v-if="isFieldAllowed('priority')">
                <div class="form-label">Приоритет</div>
                <div>
                    <label
                        v-for="priorityValue in operationalReportFilterInit.lists.priority"
                        :key="priorityValue.code"
                        class="form-check form-check-inline"
                    >
                        <input class="form-check-input" type="checkbox" :value="priorityValue.code" v-model="selectedFilters.priority">
                        <span class="form-check-label">{{ priorityValue.title }}</span>
                    </label>
                </div>
            </div>
        </div>

        <template v-slot:footer>
            <div class="btn-list">
                <div class="btn" @click="submitOperationalReportFilterForm">Применить и выгрузить отчёт</div>
                <div class="btn" @click="cancelReportForm">Отменить</div>
            </div>
        </template>
    </ModalPopup>
</template>

<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {mapActions, mapGetters} from "vuex";
import PopupUtils from "@/utils/PopupUtils";
import DateSelect from "@/components/utils/DateSelect.vue";
import _ from "lodash";

export default {
    name: 'OperationalReportFilter',
    components: {DateSelect, ModalPopup},
    data() {
        return {
            id: 'operational-report-filter-popup',
            selectedFilters: {
                period: {
                    min: '',
                    max: '',
                },
                priority: [],
                subjectType: [],
            }
        }
    },
    methods: {
        ...mapActions('operationalReportFilter', [
            'getOperationalReportFilterInit',
            'applyOperationalReportFilter',
        ]),

        run() {
            PopupUtils.show(this.id);
            this.getOperationalReportFilterInit();
        },

        setDefaultSelectedData(dataObj) {
            this.selectedFilters = Object.assign(this.selectedFilters, _.cloneDeep(dataObj));
        },

        cancelReportForm() {
            PopupUtils.hide(this.id);
        },

        isFieldAllowed(code) {
            return this.operationalReportFilterInit.fields.includes(code);
        },

        async applyOperationalReportFilterForm(data) {
            const result = await this.applyOperationalReportFilter(data);
            if (result.isSuccess()) {
                PopupUtils.hide(this.id);
                //редирект на другую страницу при успешной отправке формы
                window.location.href = result.getData()["redirectTo"];
            }
        },

        async submitOperationalReportFilterForm() {
            await this.applyOperationalReportFilterForm(this.selectedFilters);
        },
    },
    computed: {
        ...mapGetters('operationalReportFilter', [
            'operationalReportFilterInit',
        ]),
    },
    watch: {
        "operationalReportFilterInit": function() {
            this.setDefaultSelectedData(this.operationalReportFilterInit.default);
        },
    }
}
</script>
