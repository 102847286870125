<template>
    <div @click="run" class="btn" id="custom-report-filter-btn">Параметры формирования пользовательского отчета</div>
</template>

<script>
import {defineComponent} from 'vue';
import CustomReportFilter from "@/modules/CustomReportFilter";

export default defineComponent({
    name: 'UserCustomReport',
    props: {},
    data() {
        return {}
    },
    methods: {
        run() {
            CustomReportFilter.runCustomReportFilter();
        }
    },
});
</script>
