<template>
    <div @click="run" class="btn" id="create-ticket-btn">Зарегистрировать инцидент</div>
</template>

<script>
import {defineComponent} from 'vue';
import CreateTicket from "@/modules/CreateTicket";

export default defineComponent({
    name: 'FromTickets',
    props: {},
    data() {
        return {}
    },
    methods: {
        run() {
            CreateTicket.runFromScratch();
        }
    },
});
</script>
