<template>
    <ModalPopup :id="id"
                title="Выбор пользователя"
                :is-forced="true">
        <div class="input-icon">
                    <span class="input-icon-addon">
                        <i class="ti ti-search"></i>
                    </span>
            <frontend-search
                :items="contacts"
                :searchable-fields="{'name':'name'}"
                placeholder="Поиск по имени"
                @filtered="filteredContacts=$event"
            />
        </div>
        <div
            class="list-group list-group-flush list-group-hoverable card mt-3 overflow-y-auto overflow-x-hidden popup-inner-container">
            <div v-for="contact in filteredContacts" :key="contact.code"
                 class="list-group-item p-2">
                <div class="row align-items-center">
                    <div class="col text-truncate ps-3">
                        {{ contact.name }}
                    </div>
                    <div class="col-auto">
                        <div class="btn" @click="chooseContact(contact)"
                             data-bs-dismiss="modal">Выбрать
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 d-flex justify-content-center">
            <div class="btn" data-bs-dismiss="modal">Выйти без выбора</div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import FrontendSearch from "@/components/utils/FrontendSearch.vue";
import ModalPopup from "@/components/utils/ModalPopup.vue";

export default defineComponent({
    name: 'ContactFromList',
    components: {ModalPopup, FrontendSearch},
    props: {
        contacts: {
            type: Array
        },
        id: {
            type: String
        }
    },
    emits: ['chooseContact'],
    mounted() {
        this.filteredContacts = this.contacts;
    },
    data() {
        return {
            filteredContacts: []
        };
    },
    methods: {
        chooseContact(contact) {
            this.$emit('chooseContact', contact);
        },
    },
    watch: {
        contacts: function () {
            this.filteredContacts = this.contacts;
        }
    }
});
</script>
