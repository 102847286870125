<template>
    <ModalPopup :id="popupsId.FUNCTION_SET_ASSIGNEE"
                :is-forced="true"
                title="Назначение ответственного">
        <div class="row mb-3">
            <div class="col">
                <label class="form-label required">Группа ответственного:</label>
            </div>
            <div class="col">
                <select type="text" class="form-select js-select-search"
                        @change="setAssigneeGroup({code:$event.target.value})">
                    <option v-for="group in groups" :key="group.code"
                            :value="group.code"
                            :selected="group.code===currentGroup?.code">
                        {{ group.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <label class="form-label required">Ответственный:</label>
            </div>
            <div class="col" v-if="boundSelects">
                <select type="text" class="form-select js-select-search"
                        @change="setAssignee({code:$event.target.value})">
                    <option :value="emptyUser.code" :key="emptyUser.code"
                            :selected="!(currentUser?.code)">
                        {{ emptyUser.name }}
                    </option>
                    <option v-for="user in assigneeVariants" :key="user.code"
                            :value="user.code"
                            :selected="user.code===currentUser?.code">
                        {{ user.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <label class="form-label required">Комментарий</label>
                <textarea v-model="comment" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div @click="saveAssignee" class="btn">Применить и выйти</div>
                    <div class="btn" @click="finish" data-bs-dismiss="modal">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_SET_ASSIGNEE" popup-title="Подтверждение назначения ответственного">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="saveAssignee">Всё равно назначить ответственного</div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>

</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as popupsId from '../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import {mapGetters} from "vuex";
import ComponentErrors from "@/utils/ComponentErrors";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";
import ComponentHelper from "@/utils/ComponentHelper";

export default defineComponent({
    name: 'FunctionSetAssignee',
    components: {ConfirmPopup, ModalPopup},
    props: {
        groups: {
            type: Array
        },
        users: {
            type: Array
        }
    },
    emits: ['setAssignee'],
    data() {
        const emptyUser = {
            code: "",
            name: "Не выбрано"
        };

        return {
            emptyUser: emptyUser,
            currentGroup: {
                code: "",
                title: "Не выбрано"
            },
            currentUser: emptyUser,
            comment: "",
            popupsId: popupsId,
            initialGroup: null,
            confirmPopupText: '',
            lastTryDate: null, // Относится к обоим попапам

            boundSelects: true
        };
    },
    computed: {
        ...mapGetters('detailTicketPage', [
            'lastPopup',
        ]),
        assigneeVariants() {
            return this.getUsersForGroup(this.currentGroup?.code);
        },
    },
    mounted() {
        // Если в прошлый раз один из попапов остался незакрытым, открывает его
        if (this.lastPopup.getId() === popupsId.FUNCTION_SET_ASSIGNEE) {
            this.showFunction(
                this.lastPopup.getData().group,
                this.lastPopup.getData().user,
                this.lastPopup.getData().comment,
                this.lastPopup.getData().date
            );
        } else if (this.lastPopup.getId() === popupsId.CONFIRM_SET_ASSIGNEE) {
            this.showConfirm(
                this.lastPopup.getData().text,
                this.lastPopup.getData().date,
                this.lastPopup.getData().group,
                this.lastPopup.getData().user,
                this.lastPopup.getData().comment
            );
        }
    },
    methods: {
        setAssigneeGroup(group) {
            const foundGroup = getObjectByCode(this.groups, group.code);
            if (foundGroup) {
                this.currentGroup = foundGroup;

                // Если пользователь не из этой группы, сбросим его
                if (!this.currentUser.groups || !getObjectByCode(this.currentUser.groups, group.code)) {
                    this.currentUser = {
                        code: "",
                        name: "Не выбрано"
                    };
                }
            }
        },
        setAssignee(contact) {
            const user = getObjectByCode(this.users, contact?.code);
            if (user) {
                this.currentUser = user;
            }
        },
        saveAssignee() {
            if (this.validate()) {
                const data = {
                    group: this.currentGroup.code,
                    user: this.currentUser.code,
                    comment: this.comment,
                    date: this.lastTryDate
                };

                // Закрываем все попапы и очищаем данные
                this.hideFunction();
                this.hideConfirm();
                this.$emit('setAssignee', data);
            }
        },

        validate() {
            return ComponentErrors.execute([
                [!(this.currentGroup?.code), 'Заполните группу ответственного'],
                [!(this.currentUser?.code), 'Заполните ответственного'],
                [!(this.comment), 'Заполните текст комментария'],
            ]);
        },

        setDefaultGroup(group) {
            const foundGroup = getObjectByCode(this.groups, group?.code);
            if (foundGroup) {
                this.currentGroup = foundGroup;
                return;
            }

            this.group = this.groups[0];
        },

        showFunction(group, user, comment, date) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideConfirm();

            // Данные для этого попапа
            this.setDefaultGroup(group);
            this.setAssignee(user);
            this.comment = comment;
            this.lastTryDate = date;

            PopupUtils.show(popupsId.FUNCTION_SET_ASSIGNEE);

            // Запомним последний открытый попап и его данные
            this.saveFunctionPopupState();
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_SET_ASSIGNEE);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.FUNCTION_SET_ASSIGNEE) {
                this.lastPopup.close();
            }

            // Очистить его данные (но всё, кроме даты, нужно для попапа с подверждением, поэтому не трогаем)
            this.lastTryDate = null;
        },
        showConfirm(message, date, group, user, comment) {
            this.hideFunction();

            // Данные для попапа
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе. Если не переданы - не затираем текущие
            this.lastTryDate = date;

            if (group) {
                this.setDefaultGroup(group);
            }
            if (user) {
                this.setAssignee(user);
            }
            if (comment) {
                this.comment = comment;
            }

            PopupUtils.show(popupsId.CONFIRM_SET_ASSIGNEE);

            // Запомним последний открытый попап и его данные
            this.saveConfirmPopupState();
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_SET_ASSIGNEE);

            // Если попап был открыт - запомнить что мы его закрыли
            if (this.lastPopup.getId() === popupsId.CONFIRM_SET_ASSIGNEE) {
                this.lastPopup.close();
            }

            // Очистить его данные
            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideFunction();
        },
        saveFunctionPopupState() {
            this.lastPopup.open(popupsId.FUNCTION_SET_ASSIGNEE, {
                group: this.currentGroup,
                user: this.currentUser,
                comment: this.comment,
                date: this.lastTryDate
            });
        },
        saveConfirmPopupState() {
            this.lastPopup.open(popupsId.CONFIRM_SET_ASSIGNEE, {
                text: this.confirmPopupText,
                date: this.lastTryDate,
                group: this.currentGroup,
                user: this.currentUser,
                comment: this.comment,
            });
        },
        getUsersForGroup(groupCode) {
            if (!groupCode || !this.users || !this.users.length) {
                return [];
            }

            let result = [], user;

            for (let i in this.users) {
                user = this.users[i];
                if (user.groups && getObjectByCode(user.groups, groupCode)) {
                    result.push(user);
                }
            }

            return result;
        },
        async forceReinitSelects() {
            this.boundSelects = false;
            await this.$nextTick();
            this.boundSelects = true;
            await this.$nextTick();
            ComponentHelper.reInitSelects();
        },
    },
    watch: {
        currentGroup: function () {
            this.saveFunctionPopupState();
        },
        currentUser: function () {
            this.saveFunctionPopupState();
        },
        comment: function () {
            this.saveFunctionPopupState();
        },
        assigneeVariants: function () {
            this.forceReinitSelects();
        }
    }
});
</script>
