<template>
    <ModalPopup :id="id"
                title="Выбор группы"
                :is-forced="true">
        <template v-for="companyType in groupedGroups" :key="companyType.code">
            <div class="p-2">{{ companyType.title }}</div>
            <div class="list-group list-group-flush list-group-hoverable card">
                <div v-for="group in companyType.items" :key="group.code"
                     class="list-group-item p-2">
                    <div class="row align-items-center">
                        <div class="col text-truncate ps-3">
                            {{ group.title }}
                        </div>
                        <div class="col-auto">
                            <div @click="chooseGroup(group)" class="btn" data-bs-dismiss="modal">Выбрать</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="mt-3 d-flex justify-content-center">
            <div class="btn btn-danger" data-bs-dismiss="modal">Выйти без выбора</div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";

export default defineComponent({
    name: 'FunctionGroup',
    components: {ModalPopup},
    props: {
        groups: {
            type: Array
        },
        id: {
            type: String
        }
    },
    emits: ['chooseGroup'],
    data() {
        return {};
    },
    methods: {
        chooseGroup(group) {
            this.$emit('chooseGroup', group);
        },
    },
    computed: {
        groupedGroups() {
            let result = {};

            this.groups.forEach((group) => {
                if (!result[group.companyType.code]) {
                    result[group.companyType.code] = {
                        title: group.companyType.title,
                        code: group.companyType.code,
                        items: []
                    };
                }

                result[group.companyType.code].items.push(group);
            });

            return result;
        }
    }
});
</script>
