<template>
    <div class="fs-5 fst-italic" v-for="(comment, index) in allMessages" :key="index"
         :class="{'bg-primary-lt': comment.type==='for_customer'}"
    >
        <div class="row">
            <div class="col-5 fw-bold">
                {{ comment.author.name }}
            </div>
            <div class="col-4 fw-bold">
                {{ comment.title }}
            </div>
            <div class="col-3 fw-bold text-end">
                {{ formatDate(comment.date) }}
            </div>
            <div v-html="comment.text"></div>
        </div>
    </div>
</template>

<script>
import {compareCommentsDates} from "@/components/utils/utilsMethods/compareCommentsByDate";
import _ from "lodash";
import {formatDate} from "@/components/utils/utilsMethods/dateMethods";

export default {
    name: 'AllMessages',
    props: {
        state: {
            type: Object,
            default: () => ({}),
        },
        isShowOnMain: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        formatDate,
        compareCommentsDates,
    },
    computed: {
        allMessages() {
            const comments = this.state.comments?.separate || [], eventMessages = this.state.eventMessages || [];
            let allMessages = _.cloneDeep([...comments, ...eventMessages]).sort(this.compareCommentsDates);

            if (this.isShowOnMain) {
                allMessages = allMessages.filter(message => message.showOnMain)
            }

            return allMessages;
        }
    }
}
</script>

<style scoped>
.fs-5.fst-italic:not(:last-child) {
    padding-bottom: 1rem !important;
}
</style>
