export const INFORMATION_SOURCE = 'INFORMATION_SOURCE'
export const SOURCE_USER = 'SOURCE_USER'
export const SUBJECT_TEXT = 'SUBJECT_TEXT'
export const STATUS = 'STATUS'
export const SUPPLIER_COMPANY = 'SUPPLIER_COMPANY'
export const RESOURCES = 'RESOURCES'
export const CHARACTERISTICS = 'CHARACTERISTICS'
export const PREVIEW_TEXT = 'PREVIEW_TEXT'
export const DETAIL_TEXT = 'DETAIL_TEXT'
export const SUBJECT_STATE = 'SUBJECT_STATE'
export const STATE = 'STATE'
export const COORDINATOR_GROUP = 'COORDINATOR_GROUP'
export const COORDINATOR = 'COORDINATOR'
export const ASSIGNEE_GROUP = 'ASSIGNEE_GROUP'
export const ASSIGNEE = 'ASSIGNEE'
export const EXTERNAL_NUMBER = 'EXTERNAL_NUMBER'
export const CURATOR_GROUP = 'CURATOR_GROUP'
export const CURATOR = 'CURATOR'
export const SOLUTION_TARGET_DATE = 'SOLUTION_TARGET_DATE'
export const SOLUTION_CONTROL_DATE = 'SOLUTION_CONTROL_DATE'

export const NAME = 'NAME'
export const PRIORITY = 'PRIORITY'
export const REGISTER_DATE = 'REGISTER_DATE'
export const WORK_STATE = 'WORK_STATE'
