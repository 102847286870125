import {createStore} from 'vuex'
import createTicket from './modules/createTicket'
import ticket from './modules/ticket'
import ticketFilter from './modules/ticketFilter'
import customReportFilter from './modules/customReportFilter'
import operationalReportFilter from "./modules/operationalReportFilter";
import detailTicketPage from "./modules/detailTicketPage";
import ticketFunctions from "@/store/modules/ticketFunctions";
import serviceHistory from "@/store/modules/serviceHistory";

export const store = createStore({
    modules: {
        ticket,
        createTicket,
        ticketFilter,
        customReportFilter,
        operationalReportFilter,
        detailTicketPage,
        ticketFunctions,
        serviceHistory
    }
});
