import CreateApps from "@/modules/CreateApps";
import documentReady from "@/utils/documentReady";
import PageReloadLoop from "@/modules/PageReloadLoop";
import DetailTicketLastPopup from "@/modules/appState/DetailTicketLastPopup";
import DetailTicketLastTab from "@/modules/appState/DetailTicketLastTab";
import {LOCAL_STORAGE_LAST_TAB_POSTFIX, LOCAL_STORAGE_LAST_POPUP_POSTFIX} from "@/modules/constants/LocalStorage";

documentReady(() => {
    CreateApps.init();
    initPageReloadLoop();
    clearOldLocalStorage();
});

function initPageReloadLoop() {
    const params = JSON.parse(document.getElementById('page-reload-loop')?.dataset?.params || '{}');

    if (params?.timeoutSeconds) {
        (new PageReloadLoop(params.timeoutSeconds)).run();
    }
}

function clearOldLocalStorage() {
    let ticketPopupData, ticketTabData,  key;

    for (key in localStorage) {
        if (key.endsWith(LOCAL_STORAGE_LAST_POPUP_POSTFIX)) {
            ticketPopupData = new DetailTicketLastPopup(key.replace(LOCAL_STORAGE_LAST_POPUP_POSTFIX, ''));
            if (ticketPopupData.isExpired()) {
                ticketPopupData.delete();
            }
        }

        if (key.endsWith(LOCAL_STORAGE_LAST_TAB_POSTFIX)) {
            ticketTabData = new DetailTicketLastTab(key.replace(LOCAL_STORAGE_LAST_TAB_POSTFIX, ''));
            if (ticketTabData.isExpired()) {
                ticketTabData.delete();
            }
        }
    }
}
