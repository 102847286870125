<template>
    <ModalPopup :id="id"
                title="Регистрация ТТ. Выбор объекта влияния (услуга)"
                size="xl"
                :is-forced="true">
        <div class="row">
            <div class="col">
                <div class="input-icon">
                            <span class="input-icon-addon">
                                <i class="ti ti-search"></i>
                            </span>
                    <frontend-search
                        :items="services"
                        :searchable-fields="searchableServiceFields"
                        @filtered="filteredServices=$event"
                    />
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="table-responsive overflow-y-scroll popup-inner-container">
                            <table
                                class="table card-table table-vcenter text-nowrap datatable table-bordered">
                                <thead>
                                <tr>
                                    <th class="col text-center">Заказчик</th>
                                    <th class="col text-center">Договор</th>
                                    <th class="col text-center">Дата договора</th>
                                    <th class="col text-center">Услуга</th>
                                    <th class="col text-center">Статус</th>
                                    <th class="col text-center">Поставщик</th>
                                    <th class="col text-center">Заявка (OMS)</th>
                                    <th class="col text-center">Дата исполнения</th>
                                    <th class="col text-center"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="service in filteredServices" :key="service.id">
                                    <tr @click="selectService(service, $event)"
                                        :class="{'bg-blue-lt':currentService?.id===service.id}"
                                    >
                                        <td class="text-center text-black">{{ service.customer }}</td>
                                        <td class="text-center text-black">{{ service.contract }}</td>
                                        <td class="text-center text-black">{{ service.contractDate }}</td>
                                        <td class="text-center text-black">
                                            <div class="d-flex align-items-center justify-content-center">
                                                {{ service.service }}
                                                <i v-if="service.description"
                                                   class="ti ti-info-circle text-blue fs-3 ms-1"
                                                   data-bs-toggle="tooltip"
                                                   data-bs-placement="bottom"
                                                   data-bs-html="true"
                                                   :title="service.description">
                                                </i>
                                            </div>
                                        </td>
                                        <td class="text-center text-black">{{ service.status }}</td>
                                        <td class="text-center text-black">{{ service.supplier }}</td>
                                        <td class="text-center text-black">{{ service.request }}</td>
                                        <td class="text-center text-black">{{ service.executionDate }}</td>
                                        <td>
                                            <i v-if="service.channels && service.channels.length"
                                               class="ti ti-circle-plus text-red cursor-pointer toggle-channels"
                                               @click="toggleServiceChannels(service)"
                                            ></i>
                                        </td>
                                    </tr>
                                    <tr v-if="service.channels && service.channels.length"
                                        style="transition: 0s" class="collapse"
                                        :class="{show: servicesWithOpenedChannels[service.id]}">
                                        <td colspan="10">
                                            <div v-for="(channel,index) in service.channels"
                                                 :key="channel.id"
                                                 class="row mb-2"
                                                 :class="{'bg-blue-lt':currentChannel?.id===channel.id}"
                                                 @click="selectChannel(channel, service)"
                                            >
                                                <div
                                                    class="col d-flex align-items-center cursor-pointer">{{ index + 1 }}
                                                    . {{ channel.title }}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <button class="btn" @click="choose">Выбрать</button>
                    <button class="btn" data-bs-dismiss="modal">Отменить регистрацию</button>
                    <button v-if="manualAllowed" class="btn" @click="chooseManual">
                        Ручной ввод объекта влияния (услуга)
                    </button>
                </div>
            </div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import Notification from "@/utils/Notification";
import FrontendSearch from "@/components/utils/FrontendSearch.vue";
import SwitchStepMixin from "@/components/ticket/create/steps/SwitchStepMixin";
import ModalPopup from "@/components/utils/ModalPopup.vue";

export default defineComponent({
    name: 'SubjectService',
    components: {ModalPopup, FrontendSearch},
    props: {
        services: {
            type: Array
        },
        manualAllowed: {
            type: Boolean
        }
    },
    emits: ['chooseService', 'chooseChannel', 'chooseManual'],
    mixins: [SwitchStepMixin],
    mounted() {
        this.filteredServices = this.services;
    },
    data() {
        return {
            id: 'ticket-create-popup-service-step-choose-service',
            currentService: null,
            servicesWithOpenedChannels: {},

            currentChannel: null,
            currentServiceForChannel: null,

            searchableServiceFields: {
                'customer': 'customer',
                'contract': 'contract',
                'contractDate': 'contractDate',
                'service': 'service',
                'description': 'description',
                'status': 'status',
                'supplier': 'supplier',
                'request': 'request',
                'executionDate': 'executionDate',
                'channels': {
                    'name': 'name'
                }
            },
            filteredServices: []
        };
    },
    methods: {
        choose() {
            if (this.currentService) {
                this.$emit('chooseService', this.currentService);
                return;
            }

            if (this.currentChannel) {
                this.$emit('chooseChannel', this.currentServiceForChannel, this.currentChannel);
                return;
            }

            Notification.error('Выберите услугу');
        },

        chooseManual() {
            this.$emit('chooseManual');
        },

        selectService(service, event) {
            if (event?.target && !event.target.classList.contains('toggle-channels')) {
                this.toggleServiceChannels(service, this.currentService?.id !== service.id);
            }

            this.currentService = service;
            this.currentChannel = null;
            this.currentServiceForChannel = null;
        },

        selectChannel(channel, service) {
            this.currentChannel = channel;
            this.currentServiceForChannel = service;
            this.currentService = null;
        },

        toggleServiceChannels(service, force = false) {
            if(force){
                this.servicesWithOpenedChannels[service.id] = true;
                return false;
            }

            this.servicesWithOpenedChannels[service.id] = !this.servicesWithOpenedChannels[service.id];

            return false;
        }

    }
});
</script>
