import axios from "axios";
import defaultConfig from "@/modules/api/ApiDefaultConfig";
import ApiResult from "@/utils/ApiResult";

export default class {

    static apiPrefix = '/api/Tickets/';
    static apiActionsPrefix = '/api/TicketActions/';

    static async getDetailTicketInfo(params) {
        const {data} = await axios.get(
            `${this.apiPrefix}GetTicket?ticketNumber=${params.ticketNumber}&requireFresh=${params.requireFresh}`,
            defaultConfig()
        );
        return ApiResult.process(data);
    }

    static async getPermissionsListTicket(fields) {
        const {data} = await axios.post(this.apiPrefix + 'TicketPermissions', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async deleteSubscriber(fields) {
        const {data} = await axios.post(this.apiActionsPrefix + 'DeleteSubscriber', fields, defaultConfig());
        return ApiResult.process(data);
    }

    static async addSubscriber(fields) {
        const {data} = await axios.post(this.apiActionsPrefix + 'TryAddSubscriber', fields, defaultConfig());
        return ApiResult.process(data);
    }
}
