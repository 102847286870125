<template>
    <ModalPopup :id="popupId"
                :title="popupTitle"
                size="lg"
    >
        <div class="row mb-4 mt-3">
            <div class="col d-flex justify-content-center">
                <div>
                    <slot></slot>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <slot name="buttons"></slot>
                </div>
            </div>
        </div>
    </ModalPopup>
</template>

<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";

export default {
    name: 'ConfirmPopup',
    components: {ModalPopup},
    props: {
        popupId: {
            type: String,
            default: '',
        },
        popupTitle: {
            type: String,
            default: '',
        },
    },
}
</script>
