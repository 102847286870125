<template>
    <FunctionAddComment
        ref="popups"
        :permissions="permissions"
        :options="state.functions"
        @send-comment="sendComment"
    />
</template>

<script>
import {defineComponent} from 'vue';
import {mapActions} from "vuex";
import FunctionAddComment from "@/components/ticket/functions/FunctionAddComment.vue";

export default defineComponent({
    name: 'AddCommentController',
    components: {FunctionAddComment},
    props: {
        ticketNumber: {
            type: String,
            default: '',
        },
        state: {
            type: Object,
            default: () => ({})
        },
        permissions: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['reloadPageData'],
    methods: {
        ...mapActions('ticketFunctions', [
            'addCommentForCustomer',
            'addCommentForAll',
        ]),
        showAddCommentForAll(){
            this.$refs.popups.showFunctionForAll();
        },
        showAddCommentForCustomer(){
            this.$refs.popups.showFunctionForCustomer();
        },
        async sendComment(isForCustomer, data) {
            const requestData = {ticketNumber: this.ticketNumber, ...data};

            let result = isForCustomer
                ? await this.addCommentForCustomer(requestData)
                : await this.addCommentForAll(requestData);

            if (!result.isSuccess()) {
                this.$refs.popups.finish();
                return;
            }

            const response = result.getData();

            if (response['needConfirmation']) {
                this.$refs.popups.showConfirm(
                    isForCustomer,
                    response.message,
                    {
                        date: response.date,
                        hash: response.hash,
                    });
                return;
            }

            this.$refs.popups.finish();
            this.$emit('reloadPageData');
        },
    }
});
</script>
