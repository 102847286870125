<template>
    <ModalPopup :id="popupsId.FUNCTION_CHANGE_FIELDS"
                :is-forced="true"
                size="xl"
                title="Смена состояний">

        <div class="row">
            <div class="col">

                <div v-if="boundSelects && fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_SUBJECT_STATE)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_SUBJECT_STATE)}">
                            Состояние услуги:
                        </label>
                    </div>
                    <div class="col" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_SUBJECT_STATE)">
                        <select type="text" class="form-select js-select-search"
                                @change="setSubjectState({code:$event.target.value})">
                            <option v-for="state in options.subjectStateVariants" :key="state.code" :value="state.code"
                                    :selected="state.code===currentSubjectState?.code">
                                {{ state.title }}
                            </option>
                        </select>

                        <div v-if="initialSubjectState?.code && initialSubjectState.code !== currentSubjectState?.code"
                             class="fs-6 text-warning">Исходное значение: {{ initialSubjectState.title }}
                        </div>
                    </div>
                    <div v-else-if="initialSubjectState" class="col fw-bold">{{ initialSubjectState.title }}</div>
                </div>
                <div v-if="fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_SOLUTION_CONTROL_TIME)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_SOLUTION_CONTROL_TIME)}">
                            КВР:
                        </label>
                    </div>
                    <div class="col" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_SOLUTION_CONTROL_TIME)">
                        <a href="javascript:void(0)" data-modal="#control-date-select-popup">{{ solutionControlTimeFormatted }}</a>
                        <SolutionTime
                            id="control-date-select-popup"
                            title="Выбор КВР"
                            :initial-time="currentSolutionControlTime || options.solutionControlTimeFrom"
                            :time-from="options.solutionControlTimeFrom"
                            @choose-time="setControlTime"
                        />
                        <div
                            v-if="initialSolutionControlTime && initialSolutionControlTime !== currentSolutionControlTime"
                            class="fs-6 text-warning">Исходное значение: {{ formatDate(initialSolutionControlTime) }}
                        </div>
                    </div>
                    <div v-else-if="initialSolutionControlTime" class="col fw-bold">{{ formatDate(initialSolutionControlTime) }}</div>
                </div>
                <div v-if="boundSelects && fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_COORDINATOR_GROUP)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_COORDINATOR_GROUP)}">
                            Группа координатора:
                        </label>
                    </div>
                    <div class="col"
                         v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_COORDINATOR_GROUP)">
                        <select type="text" class="form-select js-select-search"
                                @change="setCoordinatorGroup({code:$event.target.value})">
                            <option v-for="group in options.coordinatorGroupVariants" :key="group.code"
                                    :value="group.code"
                                    :selected="group.code===currentCoordinatorGroup?.code">
                                {{ group.title }}
                            </option>
                        </select>

                        <div
                            v-if="initialCoordinatorGroup && initialCoordinatorGroup.code !== currentCoordinatorGroup?.code"
                            class="fs-6 text-warning">Исходное значение: {{ initialCoordinatorGroup.title }}
                        </div>
                    </div>
                    <div v-else-if="initialCoordinatorGroup" class="col fw-bold">{{
                            initialCoordinatorGroup.title
                        }}
                    </div>
                </div>
                <div v-if="boundSelects && fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_COORDINATOR)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_COORDINATOR)}">
                            Координатор:
                        </label>
                    </div>
                    <div class="col" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_COORDINATOR)">
                        <select type="text" class="form-select js-select-search"
                                @change="setCoordinator({code:$event.target.value})">
                            <option :value="emptyUser.code" :key="emptyUser.code"
                                    :selected="!(currentCoordinator?.code)">
                                {{ emptyUser.name }}
                            </option>
                            <option v-for="user in filteredCoordinators" :key="user.code"
                                    :value="user.code"
                                    :selected="user.code===currentCoordinator?.code">
                                {{ user.name }}
                            </option>
                        </select>

                        <div v-if="initialCoordinator && initialCoordinator.code !== currentCoordinator?.code"
                             class="fs-6 text-warning">Исходное значение: {{ initialCoordinator.name }}
                        </div>
                    </div>
                    <div v-else-if="initialCoordinator" class="col fw-bold">{{ initialCoordinator.name }}</div>
                </div>
                <div v-if="boundSelects && fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_ASSIGNEE_GROUP)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_ASSIGNEE_GROUP)}">
                            Группа ответственного:
                        </label>
                    </div>
                    <div class="col" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_ASSIGNEE_GROUP)">
                        <select type="text" class="form-select js-select-search"
                                @change="setAssigneeGroup({code:$event.target.value})">
                            <option v-for="group in assigneeGroupVariants" :key="group.code"
                                    :value="group.code"
                                    :selected="group.code===currentAssigneeGroup?.code">
                                {{ group.title }}
                            </option>
                        </select>

                        <div v-if="initialAssigneeGroup && initialAssigneeGroup.code !== currentAssigneeGroup?.code"
                             class="fs-6 text-warning">Исходное значение: {{ initialAssigneeGroup.title }}
                        </div>
                    </div>
                    <div v-else-if="initialAssigneeGroup" class="col fw-bold">{{ initialAssigneeGroup.title }}</div>
                </div>
                <div v-if="boundSelects && fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_ASSIGNEE)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_ASSIGNEE)}">
                            Ответственный:
                        </label>
                    </div>
                    <div class="col" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_ASSIGNEE)">
                        <select type="text" class="form-select js-select-search"
                                @change="setAssignee({code:$event.target.value})">
                            <option :value="emptyUser.code" :key="emptyUser.code"
                                    :selected="!(currentAssignee?.code)">
                                {{ emptyUser.name }}
                            </option>
                            <option v-for="user in filteredAssignees" :key="user.code"
                                    :value="user.code"
                                    :selected="user.code===currentAssignee?.code">
                                {{ user.name }}
                            </option>
                        </select>

                        <div v-if="initialAssignee && initialAssignee.code !== currentAssignee?.code"
                             class="fs-6 text-warning">Исходное значение: {{ initialAssignee.name }}
                        </div>
                    </div>
                    <div v-else-if="initialAssignee" class="col fw-bold">{{ initialAssignee.name }}</div>
                </div>
                <div v-if="fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_EXTERNAL_NUMBER)" class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_EXTERNAL_NUMBER)}">
                            Номер внешнего ТТ:
                        </label>
                    </div>
                    <div class="col">
                        <TextUnderLink
                            v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_EXTERNAL_NUMBER)"
                            v-model:text="currentExternalNumber"
                            description="номер внешнего ТТ"
                        />
                        <div v-else-if="currentExternalNumber" class="col fw-bold">{{ currentExternalNumber }}</div>

                        <div v-if="initialExternalNumber && initialExternalNumber !== currentExternalNumber"
                             class="fs-6 text-warning">Исходное значение: {{ initialExternalNumber }}
                        </div>
                    </div>
                </div>
                <div v-if="boundSelects && fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_CURATOR_GROUP)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_COORDINATOR)}">
                            Группа куратора:
                        </label>
                    </div>
                    <div class="col" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_CURATOR_GROUP)">
                        <select type="text" class="form-select js-select-search"
                                @change="setCuratorGroup({code:$event.target.value})">
                            <option :key="emptyGroup.code" :value="emptyGroup.code"
                                    :selected="!(currentCuratorGroup?.code)">
                                {{ emptyGroup.title }}
                            </option>
                            <option v-for="group in options.curatorGroupVariants" :key="group.code"
                                    :value="group.code"
                                    :selected="group.code===currentCuratorGroup?.code">
                                {{ group.title }}
                            </option>
                        </select>

                        <div v-if="initialCuratorGroup && initialCuratorGroup.code !== currentCuratorGroup?.code"
                             class="fs-6 text-warning">Исходное значение: {{ initialCuratorGroup.title }}
                        </div>
                    </div>
                    <div v-else-if="initialCuratorGroup" class="col fw-bold">{{ initialCuratorGroup.title }}</div>
                </div>
                <div v-if="boundSelects && fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_CURATOR)"
                     class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_CURATOR)}">
                            Куратор:
                        </label>
                    </div>
                    <div class="col fw-bold" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_CURATOR)">
                        <select type="text" class="form-select js-select-search"
                                @change="setCurator({code:$event.target.value})">
                            <option :value="emptyUser.code" :key="emptyUser.code"
                                    :selected="!(currentCurator?.code)">
                                {{ emptyUser.name }}
                            </option>
                            <option v-for="user in filteredCurators" :key="user.code"
                                    :value="user.code"
                                    :selected="user.code===currentCurator?.code">
                                {{ user.name }}
                            </option>
                        </select>

                        <div v-if="initialCurator && initialCurator.code !== currentCurator?.code"
                             class="fs-6 text-warning">Исходное значение: {{ initialCurator.name }}
                        </div>
                    </div>
                    <div v-else-if="initialCurator" class="col fw-bold">{{ initialCurator.name }}</div>
                </div>
                <div v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_TAKING_ASSIGNED_CONFIRMED)"
                     class="row">
                    <div class="col text-red">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input border-red" type="checkbox"
                                   v-model="takingAssignedConfirmed">
                            <span class="form-check-label"
                                  :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_TAKING_ASSIGNED_CONFIRMED)}">
                                Исполнитель подтвердил принятие инцидента в работу
                            </span>
                        </label>
                    </div>
                </div>
                <div v-if="fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_IS_MASTER)" class="row">
                    <div v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_IS_MASTER)"
                         class="col text-red">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input border-red" type="checkbox"
                                   v-model="currentIsMaster">
                            <span class="form-check-label">Master</span>
                        </label>
                    </div>
                    <div v-else class="col">
                        Master: <span v-text="currentIsMaster?'Да':'Нет'"></span>
                    </div>
                    <div v-if="initialIsMaster !== currentIsMaster"
                         class="fs-6 text-warning">Исходное значение: <span v-text="initialIsMaster?'Да':'Нет'"></span>
                    </div>
                </div>
                <div v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_RESTORING_CONFIRMED)" class="row">
                    <div class="col text-red">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input border-red" type="checkbox"
                                   v-model="serviceRestoringConfirmed">
                            <span class="form-check-label"
                                  :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_RESTORING_CONFIRMED)}">
                                Заказчик подтвердил восстановление услуги
                            </span>
                        </label>
                    </div>
                </div>
                <div v-if="fieldPermissionsService.canRead(fieldNames.CHANGE_FIELDS_AGENT)" class="row mb-2">
                    <div class="col">
                        <label class="form-label"
                               :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_AGENT)}">
                            Представитель:
                        </label>
                    </div>
                    <div class="col">
                        <template v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_AGENT)">
                            <template v-if="initialAgent && !currentAgent">
                                Выберите <a href="javascript:void(0)" @click="useInitialAgent">{{ initialAgent }}</a>
                                или
                            </template>
                            <TextUnderLink v-model:text="currentAgent" description="представителя"/>
                        </template>
                        <div v-else-if="currentAgent" class="col fw-bold">{{ currentAgent }}</div>

                        <div v-if="initialAgent && currentAgent && initialAgent !== currentAgent"
                             class="fs-6 text-warning">Исходное значение: {{ initialAgent }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" v-if="fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_COMMENT)">
                <div class="mb-3">
                    <label class="form-label"
                           :class="{required: fieldPermissionsService.isRequired(fieldNames.CHANGE_FIELDS_COMMENT)}">
                        Комментарий:
                    </label>
                    <textarea class="form-control" rows="10" v-model="comment"></textarea>
                </div>
                <div>
                    <label class="form-label mb-0">Файлы</label>
                    <UploadedFiles
                        ref="filesChangeFields"
                        prefix="change-fields"
                        :max-bytes="options.filesMaxBites"
                        @update-files="updateFiles"
                    />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <div class="btn" @click.prevent="changeFields">Применить изменения и выйти</div>
                    <div class="btn" @click.prevent="finish" data-bs-dismiss="modal">Выйти без изменений</div>
                </div>
            </div>
        </div>
    </ModalPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_CHANGE_FIELDS" popup-title="Подтверждение смены состояний"
                  key="changeFieldsConfirm">
        <span v-html="confirmPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="changeFields">Всё равно изменить состояние</div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>

    <ConfirmPopup :popup-id="popupsId.CONFIRM_UNSET_MASTER" popup-title="Подтверждение снятия Master"
                  key="unsetMasterConfirm">
        <span v-html="confirmUnsetMasterPopupText"></span>

        <template v-slot:buttons>
            <div class="btn" @click.prevent="changeFieldsConfirmed">Всё равно снять Master</div>
            <div class="btn" @click.prevent="finish">Отменить</div>
        </template>
    </ConfirmPopup>

</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import * as popupsId from '../../utils/constants/popupsId';
import PopupUtils from "@/utils/PopupUtils";
import ConfirmPopup from "@/components/ticket/detailTicket/popups/ConfirmPopup.vue";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";
import TicketFieldPermissions from "@/modules/TicketFieldPermissions";
import * as fieldNames from "@/components/utils/constants/fieldNames";
import ComponentErrors from "@/utils/ComponentErrors";
import {formatDate} from "@/components/utils/utilsMethods/dateMethods";
import ComponentHelper from "@/utils/ComponentHelper";
import TextUnderLink from "@/components/ticket/simpleFieldSetter/TextUnderLink.vue";
import UploadedFiles from "@/components/ticket/simpleFieldSetter/UploadedFiles.vue";
import SolutionTime from "@/components/ticket/simpleFieldSetter/SolutionTime.vue";
import _ from "lodash";

export default defineComponent({
    name: 'FunctionChangeFields',
    components: {SolutionTime, UploadedFiles, TextUnderLink, ConfirmPopup, ModalPopup},
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        fieldPermissions: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['changeFields', 'reloadPermissions'],
    data() {
        const emptyUser = {
                code: "-",
                name: "Не указан"
            },
            emptyGroup = {
                code: "-",
                title: "Не указана"
            };

        return {
            initialSubjectState: null,
            currentSubjectState: null,
            initialAssignee: null,
            currentAssignee: emptyUser,
            initialAssigneeGroup: null,
            currentAssigneeGroup: emptyGroup,
            initialCoordinator: null,
            currentCoordinator: emptyUser,
            initialCoordinatorGroup: null,
            currentCoordinatorGroup: emptyGroup,
            initialCurator: null,
            currentCurator: emptyUser,
            initialCuratorGroup: null,
            currentCuratorGroup: emptyGroup,
            takingAssignedConfirmed: false,
            serviceRestoringConfirmed: false,
            initialAgent: "",
            currentAgent: "",
            initialExternalNumber: "",
            currentExternalNumber: "",
            initialSolutionControlTime: "",
            currentSolutionControlTime: "",
            currentIsMaster: false,
            initialIsMaster: false,

            initialMaster: null,

            comment: "",
            files: [],

            emptyUser: emptyUser,
            emptyGroup: emptyGroup,
            masterGroup: {
                code: 'master',
                title: 'IMS'
            },

            popupsId: popupsId,
            fieldNames: fieldNames,
            confirmPopupText: '',
            confirmUnsetMasterPopupText: '',
            lastTryDate: null, // Относится к обоим попапам
            notReload: 0, // счётчик чтобы правильно учитывать одновременные блокировки от разных источников

            boundSelects: true
        };
    },
    computed: {
        fieldPermissionsService() {
            if (this.fieldPermissions) {
                return new TicketFieldPermissions(this.fieldPermissions)
            }

            return null;
        },
        filteredCoordinators() {
            return this.getUsersForGroup(this.currentCoordinatorGroup?.code, this.options.coordinatorVariants);
        },
        filteredAssignees() {
            // Если можем установить тикету master, делаем это в виде ответственного в отдельной группе с тикетами-мастерами
            if (this.fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_MASTER_TICKET) && this.options.masterVariants &&
                this.masterGroup.code === this.currentAssigneeGroup?.code) {
                return this.options.masterVariants;
            }

            return this.getUsersForGroup(this.currentAssigneeGroup?.code, this.options.assigneeVariants);
        },
        filteredCurators() {
            return this.getUsersForGroup(this.currentCuratorGroup?.code, this.options.curatorVariants);
        },
        solutionControlTimeFormatted() {
            return this.currentSolutionControlTime ? formatDate(this.currentSolutionControlTime) : 'Выбрать время';
        },
        assigneeGroupVariants() {
            let groups = _.cloneDeep(this.options.assigneeGroupVariants);
            if (this.fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_MASTER_TICKET) &&
                this.options.masterVariants) {
                groups.push(this.masterGroup);
            }

            return groups;
        }
    },
    methods: {
        formatDate,
        needReload() {
            if (!this.notReload) {
                this.$emit('reloadPermissions', this.collectData(true));
            }
        },

        collectData(light = false) {
            let data = {};
            const canUpdateMaster = this.fieldPermissionsService.canUpdate(fieldNames.CHANGE_FIELDS_MASTER_TICKET);

            // Собираем данные, которые изменились, и которые не имеют предыдущей версии

            if (this.currentAssigneeGroup?.code !== this.initialAssigneeGroup?.code &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_ASSIGNEE_GROUP)
            ) {
                if (canUpdateMaster && this.currentAssigneeGroup?.code === this.masterGroup.code) {
                    data.needMaster = true; // Поставили master, группа ответственного - пустая
                    data.assigneeGroup = '';
                } else {
                    data.assigneeGroup = this.currentAssigneeGroup && this.currentAssigneeGroup.code !== this.emptyGroup.code
                        ? this.currentAssigneeGroup.code : '';
                }
            }

            if (this.currentAssignee?.code !== this.initialAssignee?.code &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_ASSIGNEE)) {

                if (canUpdateMaster && this.options.masterVariants &&
                    getObjectByCode(this.options.masterVariants, this.currentAssignee?.code)) {
                    data.master = this.currentAssignee.code; // Поставили master, ответственного не трогаем
                } else {
                    data.assignee = this.currentAssignee && this.currentAssignee.code !== this.emptyUser.code
                        ? this.currentAssignee.code : '';
                    if (this.initialMaster) {
                        data.master = '';
                    }
                }
            }

            if (this.currentSubjectState?.code !== this.initialSubjectState?.code &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_SUBJECT_STATE)) {
                data.subjectState = this.currentSubjectState?.code || '';
            }

            if (this.currentCoordinator?.code !== this.initialCoordinator?.code &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_COORDINATOR)) {
                data.coordinator = this.currentCoordinator && this.currentCoordinator.code !== this.emptyUser.code
                    ? this.currentCoordinator.code : '';
            }

            if (this.currentCoordinatorGroup?.code !== this.initialCoordinatorGroup?.code &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_COORDINATOR_GROUP)) {
                data.coordinatorGroup = this.currentCoordinatorGroup && this.currentCoordinatorGroup.code !== this.emptyGroup.code
                    ? this.currentCoordinatorGroup.code : '';
            }

            if (this.currentCurator?.code !== this.initialCurator?.code &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_CURATOR)) {
                data.curator = this.currentCurator && this.currentCurator.code !== this.emptyUser.code
                    ? this.currentCurator.code : '';
            }

            if (this.currentCuratorGroup?.code !== this.initialCuratorGroup?.code &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_CURATOR_GROUP)) {
                data.curatorGroup = this.currentCuratorGroup && this.currentCuratorGroup.code !== this.emptyGroup.code
                    ? this.currentCuratorGroup.code : '';
            }

            if (this.takingAssignedConfirmed &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_TAKING_ASSIGNED_CONFIRMED)) {
                data.takingAssignedConfirmed = this.takingAssignedConfirmed;
            }
            if (this.serviceRestoringConfirmed &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_RESTORING_CONFIRMED)) {
                data.serviceRestoringConfirmed = this.serviceRestoringConfirmed;
            }

            if (this.currentExternalNumber !== this.initialExternalNumber &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_EXTERNAL_NUMBER)) {
                data.externalNumber = this.currentExternalNumber;
            }

            if (this.currentAgent &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_AGENT)) {
                data.agent = this.currentAgent;
            }

            if (this.currentIsMaster !== this.initialIsMaster &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_IS_MASTER)) {
                data.isMaster = this.currentIsMaster;
            }

            if (this.currentSolutionControlTime !== this.initialSolutionControlTime &&
                this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_SOLUTION_CONTROL_TIME)) {
                data.solutionControlTime = this.currentSolutionControlTime;
            }

            if (this.fieldPermissionsService.canUpdate(this.fieldNames.CHANGE_FIELDS_COMMENT)) {
                if (this.comment) {
                    data.comment = this.comment;
                }
                if (!light && this.files.length) {
                    data.files = this.files;
                }
            }

            if (this.lastTryDate) {
                data.date = this.lastTryDate;
            }

            return data;
        },

        async forceReinitSelects() {
            this.boundSelects = false;
            await this.$nextTick();
            this.boundSelects = true;
            await this.$nextTick();
            ComponentHelper.reInitSelects();
        },

        changeFields() {
            this.sendData(this.collectData());
        },
        changeFieldsConfirmed() {
            let data = this.collectData();
            data.masterFlagUnsetConfirmed = true;

            this.sendData(data);
        },
        sendData(data) {
            if (this.validate(data)) {
                // Закрываем все попапы и очищаем данные
                this.hideFunction();
                this.hideConfirm();
                this.hideConfirmUnsetMaster();
                this.$emit('changeFields', data);
            }
        },

        validate(data) {
            let dataKeys = Object.keys(data).filter(key => key !== 'date' && key !== 'comment' && key !== 'files' && key !== 'needMaster');

            return ComponentErrors.execute([
                [!dataKeys.length, 'Нет изменений'], // не считаем техническое поле date и комментарий
                [!data.subjectState && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_SUBJECT_STATE),
                    'Заполните состояние услуги'],
                [!data.assignee && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_ASSIGNEE),
                    'Заполните ответственного'],
                [!data.assigneeGroup && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_ASSIGNEE_GROUP),
                    'Заполните группу ответственного'],
                [!data.coordinator && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_COORDINATOR),
                    'Заполните координатора'],
                [!data.coordinatorGroup && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_COORDINATOR_GROUP),
                    'Заполните группу координатора'],
                [!data.curator && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_CURATOR),
                    'Заполните куратора'],
                [!data.curatorGroup && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_CURATOR_GROUP),
                    'Заполните группу куратора'],
                [!data.externalNumber && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_EXTERNAL_NUMBER),
                    'Заполните внешний номер'],
                [!data.agent && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_AGENT),
                    'Заполните представителя'],
                [!data.solutionControlTime && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_SOLUTION_CONTROL_TIME),
                    'Заполните контрольное время'],
                [!data.master &&
                (data.needMaster || this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_MASTER_TICKET)),
                    'Заполните ответственного master-ТТ'],
                [!data.comment && this.fieldPermissionsService.isRequired(this.fieldNames.CHANGE_FIELDS_COMMENT) ||
                data.files?.length && !data.comment,
                    'Заполните комментарий']
            ]);
        },

        setSubjectState(state) {
            const stateVariant = getObjectByCode(this.options.subjectStateVariants, state?.code);
            this.currentSubjectState = stateVariant ? stateVariant : this.options.subjectStateVariants[0];
            this.needReload();
        },

        setAssignee(user) {
            let currentAssignee = this.getUser(user, 'masterVariants');
            if (currentAssignee?.code !== user?.code) {
                currentAssignee = this.getUser(user, 'assigneeVariants');
            }
            this.currentAssignee = currentAssignee;

            this.needReload();
        },

        setCoordinator(user) {
            this.currentCoordinator = this.getUser(user, 'coordinatorVariants');
            this.needReload();
        },

        setCurator(user) {
            this.currentCurator = this.getUser(user, 'curatorVariants');
            this.needReload();
        },

        getUser(user, optionsCode) {
            const userVariant = user ? getObjectByCode(this.options[optionsCode], user.code) : null;

            return userVariant ? userVariant : this.emptyUser;
        },

        setAssigneeGroup(group) {
            this.disableReload();

            this.currentAssigneeGroup = group?.code === this.masterGroup.code
                ? this.masterGroup : this.getGroup(group, 'assigneeGroupVariants');

            this.setAssignee(null);

            this.enableReload();
            this.needReload();
            this.forceReinitSelects();
        },

        setCoordinatorGroup(group) {
            this.disableReload();

            this.currentCoordinatorGroup = this.getGroup(
                group, 'coordinatorGroupVariants');
            this.setCoordinator(null);

            this.enableReload();
            this.needReload();
            this.forceReinitSelects();
        },

        setCuratorGroup(group) {
            this.disableReload();

            this.currentCuratorGroup = this.getGroup(
                group, 'curatorGroupVariants');
            this.setCurator(null);

            this.enableReload();
            this.needReload();
            this.forceReinitSelects();
        },

        setControlTime(date) {
            this.currentSolutionControlTime = date;
        },

        getGroup(group, optionsCode) {
            const groupVariant = group ? getObjectByCode(this.options[optionsCode], group.code) : null;

            return groupVariant ? groupVariant : this.emptyGroup;
        },

        showFunction(initials, date) {
            // Закроем остальные попапы этой фунции, если вдруг они были открыты
            this.hideConfirm();
            this.hideConfirmUnsetMaster();
            this.disableReload();

            // Данные для этого попапа
            this.initialSubjectState = initials.subjectState || null;
            this.initialAssignee = initials.assignee || this.emptyUser;
            this.initialCoordinator = initials.coordinator || this.emptyUser;
            this.initialCurator = initials.curator || this.emptyUser;
            this.initialAssigneeGroup = initials.assigneeGroup || this.emptyGroup;
            this.initialCoordinatorGroup = initials.coordinatorGroup || this.emptyGroup;
            this.initialCuratorGroup = initials.curatorGroup || this.emptyGroup;
            this.initialExternalNumber = initials.externalNumber || '';
            this.initialAgent = initials.informationSourceContact || '';
            this.initialSolutionControlTime = initials.solutionControlTime || "";
            this.initialIsMaster = initials.isMaster || false;
            this.initialMaster = initials.master || null;

            if (initials.master) {
                this.initialAssigneeGroup = this.masterGroup;
                this.initialAssignee = initials.master;
            }

            this.setSubjectState(this.initialSubjectState);
            this.setAssigneeGroup(this.initialAssigneeGroup);
            this.setCoordinatorGroup(this.initialCoordinatorGroup);
            this.setCuratorGroup(this.initialCuratorGroup);
            this.setAssignee(this.initialAssignee);
            this.setCoordinator(this.initialCoordinator);
            this.setCurator(this.initialCurator);
            this.currentExternalNumber = this.initialExternalNumber;
            this.currentSolutionControlTime = this.initialSolutionControlTime;
            this.currentIsMaster = this.initialIsMaster;

            this.takingAssignedConfirmed = false;
            this.serviceRestoringConfirmed = false;
            this.currentAgent = ''; // не заполняем из this.initialAgent

            this.lastTryDate = date;
            this.confirmPopupText = '';
            this.confirmUnsetMasterPopupText = '';

            this.comment = "";
            this.$refs.filesChangeFields.clear();

            this.forceReinitSelects();

            PopupUtils.show(popupsId.FUNCTION_CHANGE_FIELDS);
            this.$nextTick(() => this.enableReload());
        },
        hideFunction() {
            PopupUtils.hide(popupsId.FUNCTION_CHANGE_FIELDS);
            // Очистить его данные (но всё, кроме даты, нужно для попапа с подверждением, поэтому не трогаем)
            this.lastTryDate = null;
        },
        showConfirm(message, date) {
            this.hideFunction();
            this.hideConfirmUnsetMaster();

            // Данные для попапа
            this.confirmPopupText = message;

            // Данные для отправки на бек по кнопке в попапе. Если не переданы - не затираем текущие
            this.lastTryDate = date;

            PopupUtils.show(popupsId.CONFIRM_CHANGE_FIELDS);
        },
        hideConfirm() {
            PopupUtils.hide(popupsId.CONFIRM_CHANGE_FIELDS);

            // Очистить его данные
            this.lastTryDate = null;
            this.confirmPopupText = '';
        },
        showConfirmUnsetMaster(message) {
            this.hideFunction();
            this.hideConfirm();

            // Данные для попапа
            this.confirmUnsetMasterPopupText = message;

            PopupUtils.show(popupsId.CONFIRM_UNSET_MASTER);
        },
        hideConfirmUnsetMaster() {
            PopupUtils.hide(popupsId.CONFIRM_UNSET_MASTER);

            // Очистить его данные
            this.confirmUnsetMasterPopupText = '';
        },
        finish() {
            // Точка выхода из всех сценариев. Здесь должны закрыть все попапы и очистить все данные
            this.hideConfirm();
            this.hideConfirmUnsetMaster();
            this.hideFunction();
        },
        useInitialAgent() {
            this.currentAgent = this.initialAgent;
        },
        updateFiles(data) {
            this.files = data.files;
        },
        enableReload() {
            this.notReload--;
        },
        disableReload() {
            this.notReload++;
        },
        getUsersForGroup(groupCode, users) {
            if (!groupCode || !users || !users) {
                return [];
            }

            let result = [], user;

            for (let i in users) {
                user = users[i];
                if (user.groups && getObjectByCode(user.groups, groupCode)) {
                    result.push(user);
                }
            }

            return result;
        }
    },
    watch: {
        takingAssignedConfirmed: function () {
            this.needReload();
        },
        serviceRestoringConfirmed: function () {
            this.needReload();
        },
        currentAgent: function () {
            this.needReload();
        },
        currentExternalNumber: function () {
            this.needReload();
        },
        currentSolutionControlTime: function () {
            this.needReload();
        },
        currentIsMaster: function () {
            this.needReload();
        },
        assigneeGroupVariants: function () {
            this.forceReinitSelects();
        }
    }
});
</script>
