<template>

    <ModalPopup :id="id"
                title="Оформление Trouble Ticket"
                size="full-width"
                :is-forced="true">

        <div class="row mb-1 pb-1 border-bottom border-light-subtle">
            <div v-if="isReadableSubjectService || isReadableChannelSubjectService"
                 class="col fw-bold">
                Объект влияния: <a href="{{service.url}}" class="fw-normal">
                {{ isReadableSubjectService ? service.service : channel.title }}</a>
                <template v-if="fieldPermissionsService.canRead(fields.CUSTOMER)">
                    для
                    <ins>{{ service.customer }}</ins>
                </template>
                <template v-if="fieldPermissionsService.canRead(fields.CONTRACT_NUMBER)">
                    по
                    <ins>договору {{ service.contract }} от {{ service.contractDate }}</ins>
                </template>
                <template v-if="fieldPermissionsService.canRead(fields.REQUEST) && service.request">
                    (
                    <ins>{{ service.request }}</ins>
                    )
                </template>
            </div>

            <div v-if="isManualServiceSubject" class="col fw-bold">
                Объект влияния: {{ manualService.service }}
                по
                <ins>договору {{ manualService.contract }}</ins>
            </div>

            <div v-if="(isServiceSubject || isServiceChannelSubject) &&
                        service.supplier && fieldPermissionsService.canRead(fields.SUPPLIER_COMPANY)"
                 class="col-auto fw-bold">
                Поставщик: <span class="fw-normal opacity-50">{{ service.supplier }}</span>
            </div>

            <div v-if="isManualServiceSubject && manualService.supplier
            && fieldPermissionsService.canRead(fields.SUPPLIER_COMPANY)"
                 class="col-auto fw-bold">
                Поставщик: <span class="fw-normal opacity-50">{{ manualService.supplier.title }}</span>
            </div>

            <div v-if="isInfrastructureObjectSubject" class="col fw-bold">
                Объект влияния: {{ infrastructureObject.name }}
                <template v-if="infrastructureObject.linkNode">
                    , расположение: {{ infrastructureObject.linkNode }}
                </template>
            </div>
            <div v-if="isManualInfrastructureSubject" class="col fw-bold">
                Объект влияния: {{ manualInfrastructure.name }}
                <template v-if="manualInfrastructure.linkNode">
                    , расположение: {{ manualInfrastructure.linkNode }}
                </template>
            </div>
            <div v-if="isInfrastructureObjectSubject && infrastructureObject.owner" class="col-auto fw-bold">
                Поставщик: <span class="fw-normal opacity-50">{{ infrastructureObject.owner.title }}</span>
            </div>
            <div v-else-if="isManualInfrastructureSubject && createTicketOptions.default.infrastructureOwner"
                 class="col-auto fw-bold">
                Поставщик: <span class="fw-normal opacity-50">{{
                    createTicketOptions.default.infrastructureOwner.title
                }}</span>
            </div>
        </div>

        <template v-if="isServiceSubject && !isManualServiceSubject && service.channels?.length &&
(fieldPermissionsService.canRead(fields.RESOURCES) || fieldPermissionsService.canRead(fields.CHARACTERISTICS))">
            <div class="row mb-3">
                <div class="col">
                    <div class="card overflow-auto" style="height: 200px">
                        <div class="card-body p-3">
                            <div v-for="channel in service.channels" :key="channel.id" class="row fs-5">
                                <div v-if="service.channels.length > 1" class="h2 justify-content-center">
                                    {{ channel.title }}
                                </div>
                                <div
                                    v-if="fieldPermissionsService.canRead(fields.RESOURCES) && channel.accessParams.length"
                                    class="col-4">
                                    <h3>Параметры доступа:</h3>
                                    <div v-for="(accessParam, index) in channel.accessParams" :key="index"
                                         class="me-auto">{{ accessParam.title }}: <b>{{ accessParam.value }}</b></div>
                                </div>
                                <div class="col-8"
                                     v-if="fieldPermissionsService.canRead(fields.CHARACTERISTICS) && channel.specifications.length">
                                    <h3>Характеристики:</h3>
                                    <div v-for="(specification, index) in channel.specifications" :key="index"
                                         class="me-auto">{{ specification.title }} : <b>{{ specification.value }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="isServiceChannelSubject">
            <div v-if="fieldPermissionsService.canRead(fields.RESOURCES) &&
                    channel.accessParams && channel.accessParams.length"
                 class="row mb-1 pb-1 border-bottom border-light-subtle">
                <div class="col">
                    <div class="fw-bold">Параметры доступа</div>
                    <div v-for="(param,index) in channel.accessParams" :key="index"
                         class="me-auto fs-5 opacity-70"><b>{{ param.title }}:</b> {{ param.value }}
                    </div>
                </div>
            </div>

            <div v-if="fieldPermissionsService.canRead(fields.CHARACTERISTICS)
                    && channel.specifications && channel.specifications.length"
                 class="row mb-1 pb-1 border-bottom border-light-subtle">
                <div class="col">
                    <div class="fw-bold">Характеристики</div>
                    <div class="row">
                        <div v-for="(chunk,index) in getParameterColumns(channel.specifications)" :key="index"
                             class="col">
                            <div v-for="(specification, specIndex) in chunk" :key="specIndex"
                                 class="me-auto fs-5 opacity-70">
                                <b>{{ specification.title }}:</b> {{ specification.value }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div class="row mb-1 pb-1 border-bottom border-light-subtle">
            <div v-if="fieldPermissionsService.canRead(fields.SUBJECT_STATE)"
                 class="col">
                <div>
                    <label class="form-label label-inline"
                           :class="{required: fieldPermissionsService.canAdd(fields.SUBJECT_STATE)}">
                        <b>{{ subjectStateLabel }}:</b>
                    </label>

                    <a href="javascript:void(0)" data-modal="#ticket-subject-state-popup">
                        {{ subjectState.title }}
                    </a>
                </div>

                <SubjectState v-if="fieldPermissionsService.canAdd(fields.SUBJECT_STATE) &&
                            createTicketOptions.lists.subjectStateVariants &&
                            createTicketOptions.lists.subjectStateVariants.length"
                              :states="createTicketOptions.lists.subjectStateVariants"
                              id="ticket-subject-state-popup"
                              @choose-state="setSubjectState"
                />

            </div>

            <div v-if="!isCustomer && fieldPermissionsService.canRead(fields.PRIORITY)" class="col">
                <div><label class="form-label label-inline"
                            :class="{required: fieldPermissionsService.canAdd(fields.PRIORITY)}">
                    <b>Приоритет:</b>
                </label>

                    <a href="javascript:void(0)" data-modal="#ticket-change-priority-popup">
                        {{ priority.title }}
                    </a></div>

                <TicketPriority v-if="fieldPermissionsService.canAdd(fields.PRIORITY) &&
                            createTicketOptions.lists.priorityVariants &&
                            createTicketOptions.lists.priorityVariants.length"
                                :priorities="createTicketOptions.lists.priorityVariants"
                                :initial-priority="priority"
                                :need-reason="false"
                                id="ticket-change-priority-popup"
                                @choose-priority="setPriority"
                />

            </div>

            <div v-if="!isCustomer && fieldPermissionsService.canRead(fields.INFORMATION_SOURCE)"
                 class="col">
                <div><label class="form-label label-inline"
                            :class="{required: fieldPermissionsService.canAdd(fields.INFORMATION_SOURCE)}">
                    <b>Источник информации:</b></label>
                    <a href="javascript:void(0)" data-modal="#ticket-information-source-popup">
                        {{ informationSource.title }}
                    </a></div>

                <InformationSource v-if="fieldPermissionsService.canAdd(fields.INFORMATION_SOURCE) &&
                            createTicketOptions.lists.informationSourceVariants &&
                            createTicketOptions.lists.informationSourceVariants.length"
                                   :sources="createTicketOptions.lists.informationSourceVariants"
                                   id="ticket-information-source-popup"
                                   @choose-source="setInformationSource"
                />
            </div>

            <div v-if="fieldPermissionsService.canRead(fields.SOURCE_USER)"
                 class="col">
                <div><label class="form-label label-inline"
                            :class="{required: fieldPermissionsService.canAdd(fields.SOURCE_USER)}">
                    <b>Обратился:</b></label>

                    <TextUnderLink v-if="fieldPermissionsService.canAdd(fields.SOURCE_USER)"
                                   v-model:text="informationSourceContact"
                                   description="обратившегося"
                    />
                    <span v-else>{{ informationSourceContact }}</span>
                </div>
            </div>
        </div>

        <div v-if="isInfrastructureObjectSubject" class="row mb-1 pb-1 border-bottom border-light-subtle">
            <div class="col-auto fw-bold">
                Что из инфраструктуры: <span class="fw-normal">{{ infrastructureObject.type.title }}</span>
            </div>
            <div class="col-auto fw-bold">
                Название части инфраструктуры: <span class="fw-normal">{{ infrastructureObject.name }}</span>
            </div>
        </div>
        <div v-if="isManualInfrastructureSubject" class="row mb-1 pb-1 border-bottom border-light-subtle">
            <div class="col-auto fw-bold">
                Что из инфраструктуры: <span class="fw-normal">{{ manualInfrastructureTypeTitle }}</span>
            </div>
            <div class="col-auto fw-bold">
                Название части инфраструктуры: <span class="fw-normal">{{ manualInfrastructure.name }}</span>
            </div>
        </div>

        <div v-if="!isCustomer &&
                    (fieldPermissionsService.canRead(fields.COORDINATOR_GROUP) ||
                    fieldPermissionsService.canRead(fields.COORDINATOR))" class="row">

            <template v-if="fieldPermissionsService.canRead(fields.COORDINATOR_GROUP)">
                <div class="col-2">
                    <label class="form-label label-inline"
                           :class="{required: fieldPermissionsService.canAdd(fields.COORDINATOR_GROUP)}">
                        Группа координатора:
                    </label>
                </div>
                <div class="col-4 fw-bold"><a href="javascript:void(0)"
                                              data-modal="#ticket-change-coordinator-group-popup">
                    {{ coordinatorGroup.title }}
                </a></div>
            </template>

            <FunctionGroup v-if="fieldPermissionsService.canAdd(fields.COORDINATOR_GROUP) &&
                            createTicketOptions.lists.coordinatorGroupVariants &&
                            createTicketOptions.lists.coordinatorGroupVariants.length"
                           :groups="createTicketOptions.lists.coordinatorGroupVariants"
                           id="ticket-change-coordinator-group-popup"
                           @choose-group="setCoordinatorGroup"
            />

            <template v-if="fieldPermissionsService.canRead(fields.COORDINATOR)">
                <div class="col-2">Координатор:</div>
                <div class="col-4 fw-bold"><a href="javascript:void(0)"
                                              data-modal="#ticket-change-coordinator-popup">
                    {{ coordinator.name }}
                </a></div>
            </template>

            <ContactFromList
                v-if="fieldPermissionsService.canAdd(fields.COORDINATOR) &&
                !createTicketOptions.blockFields.coordinator &&
                coordinatorVariants.length"
                :contacts="coordinatorVariants"
                id="ticket-change-coordinator-popup"
                @choose-contact="setCoordinator"
            />
        </div>
        <div v-if="!isCustomer &&
                    (fieldPermissionsService.canRead(fields.ASSIGNEE_GROUP) ||
                    fieldPermissionsService.canRead(fields.ASSIGNEE))" class="row">

            <template v-if="fieldPermissionsService.canRead(fields.ASSIGNEE_GROUP)">
                <div class="col-2">
                    <label class="form-label label-inline"
                           :class="{required: fieldPermissionsService.canAdd(fields.ASSIGNEE_GROUP)}">
                        Группа ответственного:
                    </label>
                </div>
                <div class="col-4 fw-bold"><a href="javascript:void(0)"
                                              data-modal="#ticket-change-assignee-group-popup">
                    {{ assigneeGroup.title }}
                </a></div>
            </template>

            <FunctionGroup v-if="fieldPermissionsService.canAdd(fields.ASSIGNEE_GROUP) &&
                            createTicketOptions.lists.assigneeGroupVariants &&
                            createTicketOptions.lists.assigneeGroupVariants.length"
                           :groups="createTicketOptions.lists.assigneeGroupVariants"
                           id="ticket-change-assignee-group-popup"
                           @choose-group="setAssigneeGroup"
            />

            <template v-if="fieldPermissionsService.canRead(fields.ASSIGNEE)">
                <div class="col-2">Ответственный:</div>
                <div class="col-4 fw-bold"><a href="javascript:void(0)"
                                              data-modal="#ticket-change-assignee-popup">
                    {{ assignee.name }}
                </a></div>
            </template>

            <ContactFromList v-if="fieldPermissionsService.canAdd(fields.ASSIGNEE) && assigneeVariants.length"
                             :contacts="assigneeVariants"
                             id="ticket-change-assignee-popup"
                             @choose-contact="setAssignee"
            />
        </div>
        <div v-if="fieldPermissionsService.canRead(fields.PREVIEW_TEXT)" class="row mb-3">
            <div class="fw-bold">
                <label class="form-label label-inline"
                       :class="{required: fieldPermissionsService.canAdd(fields.PREVIEW_TEXT)}">
                    Краткое описание проблемы:
                </label>
            </div>

            <textarea v-if="fieldPermissionsService.canAdd(fields.PREVIEW_TEXT)"
                      v-model="previewText"
                      class="form-control" rows="2"></textarea>
            <span v-else>{{ previewText }}</span>
        </div>
        <div v-if="fieldPermissionsService.canRead(fields.DETAIL_TEXT)" class="row mb-3">
            <div class="fw-bold">Полное описание проблемы:</div>
            <textarea v-if="fieldPermissionsService.canAdd(fields.DETAIL_TEXT)"
                      v-model="detailText"
                      class="form-control" rows="7"></textarea>
            <span v-else>{{ detailText }}</span>
        </div>
        <div v-if="fieldPermissionsService.canRead(fields.DETAIL_TEXT)" class="row mb-4">
            <div class="fw-bold">Файлы:</div>
            <div v-for="(file,index) in storedFiles" :key="index" class="fw-bold">
                <IconFile /><a :href="file.url" target="_blank">{{ file.title }}</a>&nbsp;
                <a class="delete-file" @click.prevent="removeStoredFile(index)" href="javascript:void(0)">Удалить файл</a>
            </div>
            <div v-for="(file,index) in files" :key="index" class="fw-bold">
                <i class="ti ti-file"></i>{{ file.name }}
                <a class="delete-file" @click.prevent="removeFile(index)" href="javascript:void(0)">Удалить файл</a>
            </div>
            <AddFile v-if="fieldPermissionsService.canAdd(fields.DETAIL_TEXT)"
                     id="create-ticket-files"
                     @add-file="addFile($event)"
            />
        </div>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <div class="btn-list">
                    <a class="btn" @click="close">Отменить</a>
                    <a @click="saveDraft" class="btn">Сохранить черновик</a>
                    <a @click="register" class="btn">На регистрацию и исполнение</a>
                </div>
            </div>
        </div>

    </ModalPopup>

</template>

<script>
import {defineComponent} from 'vue';
import SubjectState from "@/components/ticket/simpleFieldSetter/SubjectState.vue";
import TicketPriority from "@/components/ticket/simpleFieldSetter/TicketPriority.vue";
import InformationSource from "@/components/ticket/simpleFieldSetter/InformationSource.vue";
import ContactFromList from "@/components/ticket/simpleFieldSetter/ContactFromList.vue";
import FunctionGroup from "@/components/ticket/simpleFieldSetter/FunctionGroup.vue";
import TextUnderLink from "@/components/ticket/simpleFieldSetter/TextUnderLink.vue";
import AddFile from "@/components/utils/AddFile.vue";
import SwitchStepMixin from "@/components/ticket/create/steps/SwitchStepMixin";
import ComponentErrors from "@/utils/ComponentErrors";
import Notification from "@/utils/Notification";
import ModalPopup from "@/components/utils/ModalPopup.vue";
import TicketDraft from "@/modules/TicketDraft";
import * as fields from "@/modules/constants/TicketFields";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";
import { IconFile } from '@tabler/icons-vue';

export default defineComponent({
    name: 'DraftForm',
    components: {
        TextUnderLink, IconFile,
        ModalPopup, AddFile, FunctionGroup, ContactFromList, InformationSource, TicketPriority, SubjectState
    },
    props: {
        service: {
            type: Object
        },
        channel: {
            type: Object
        },
        manualService: {
            type: Object
        },
        infrastructureObject: {
            type: Object
        },
        manualInfrastructure: {
            type: Object
        },

        fieldPermissionsService: null,
        user: null,
        draft: null,

        createTicketOptions: {
            type: Object
        }
    },
    emits: ['saveDraft', 'register', 'needReloadOptions'],
    mixins: [SwitchStepMixin],
    mounted() {
        this.initDefaults();
        this.initFromDraft();
    },
    data() {
        const emptyEntity = {
                code: "",
                title: "Не выбрано"
            },
            emptyUser = {
                code: "",
                name: "Не выбрано"
            };

        return {
            emptyEntity: emptyEntity,
            emptyUser: emptyUser,

            id: 'ticket-create-popup-service-step-form',
            subjectState: emptyEntity,
            priority: emptyEntity,
            informationSource: emptyEntity,
            coordinatorGroup: emptyEntity,
            assigneeGroup: emptyEntity,

            coordinator: emptyUser,
            assignee: emptyUser,

            informationSourceContact: "",

            previewText: "",
            detailText: "",

            files: [],
            storedFiles: [],

            isCustomer: this.user.isCustomer(),
            fields: fields,

            lastDefaults: {},

            optionsQuiteMode: false

        };
    },
    methods: {

        getParameterColumns(array) {
            const chunkSize = Math.ceil(array.length / 3), result = [];

            if (!array.length) {
                return result;
            }

            for (let i = 0; i < array.length; i += chunkSize) {
                result.push(array.slice(i, i + chunkSize));
            }

            return result;
        },

        setSubjectState(state) {
            this.subjectState = state;
            this.needReloadOptions();
        },

        setPriority(priority) {
            this.priority = priority;
        },

        setInformationSource(source) {
            this.informationSource = source;
            this.needReloadOptions();
        },

        setCoordinatorGroup(group) {
            if (getObjectByCode(
                this.createTicketOptions.lists.coordinatorGroupVariants,
                group.code
            )) {
                this.coordinatorGroup = group;

                // Если координатор не из этой группы, сбросим его
                if(!this.coordinator.groups || !getObjectByCode(
                    this.coordinator.groups,
                    group.code
                )) {
                    this.coordinator = this.emptyUser;
                }

                return true;
            }

            return false;
        },

        setAssigneeGroup(group) {
            if (getObjectByCode(this.createTicketOptions.lists.assigneeGroupVariants, group.code)) {
                this.assigneeGroup = group;

                // Если ответственный не из этой группы, сбросим его
                if(!this.assignee.groups || !getObjectByCode(this.assignee.groups, group.code)) {
                    this.setAssignee(null);
                }

                return true;
            }

            return false;
        },

        setCoordinator(contact) {
            this.coordinator = contact;
            this.needReloadOptions();
        },

        setAssignee(contact) {
            this.assignee = contact ?? this.emptyUser;
            this.needReloadOptions();
        },

        initDefaults() {
            const defaults = this.createTicketOptions.default;
            let variant;

            if (!defaults) {
                return;
            }

            // Не запрашиваем опции с бека, чтобы не зациклиться
            this.optionsQuiteMode = true;

            // Устанавливаем значение по умолчанию, если значение не было установлено пользователем,
            // или если значение по умолчанию поменялось с прошлого обращения к беку

            if (defaults.coordinatorGroup &&
                (!this.coordinatorGroup.code || defaults.coordinatorGroup.code !== this.lastDefaults.coordinatorGroup?.code)) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.coordinatorGroupVariants,
                    defaults.coordinatorGroup.code
                );
                if (variant) {
                    this.setCoordinatorGroup(variant);
                }
            }

            if (defaults.coordinator &&
                (!this.coordinator.code || defaults.coordinator.code !== this.lastDefaults.coordinator?.code)) {
                variant = getObjectByCode(this.createTicketOptions.lists.coordinatorVariants, defaults.coordinator.code);
                if (variant) {
                    this.setCoordinator(variant);
                }
            }

            if (defaults.assigneeGroup &&
                (!this.assigneeGroup.code || defaults.assigneeGroup.code !== this.lastDefaults.assigneeGroup?.code)) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.assigneeGroupVariants,
                    defaults.assigneeGroup.code
                );
                if (variant) {
                    this.setAssigneeGroup(variant);
                }
            }

            if (defaults.priority && (!this.priority.code || defaults.priority.code !== this.lastDefaults.priority?.code)) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.priorityVariants,
                    defaults.priority.code
                );
                if (variant) {
                    this.setPriority(variant);
                }
            }

            if (defaults.informationSource &&
                (!this.informationSource.code || defaults.informationSource.code !== this.lastDefaults.informationSource?.code)) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.informationSourceVariants,
                    defaults.informationSource.code
                );
                if (variant) {
                    this.setInformationSource(variant);
                }
            }

            this.informationSourceContact = this.informationSourceContact || defaults.informationSourceContact || "";

            this.lastDefaults = defaults;

            this.optionsQuiteMode = false;
        },

        initFromDraft() {
            let variant;

            if (!this.draft) {
                return;
            }

            // Не запрашиваем опции с бека, чтобы не зациклиться
            this.optionsQuiteMode = true;

            // Устанавливаем значение по умолчанию, если значение не было установлено пользователем,
            // или если значение по умолчанию поменялось с прошлого обращения к беку

            if (this.draft.coordinatorGroup) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.coordinatorGroupVariants,
                    this.draft.coordinatorGroup
                );
                if (variant) {
                    this.setCoordinatorGroup(variant);
                }
            }

            if (this.draft.coordinator) {
                variant = getObjectByCode(this.createTicketOptions.lists.coordinatorVariants, this.draft.coordinator);
                if (variant) {
                    this.setCoordinator(variant);
                }
            }

            if (this.draft.assigneeGroup) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.assigneeGroupVariants,
                    this.draft.assigneeGroup
                );
                if (variant) {
                    this.setAssigneeGroup(variant);
                }
            }

            if (this.draft.assignee) {
                variant = getObjectByCode(this.createTicketOptions.lists.assigneeVariants, this.draft.assignee);
                if (variant) {
                    this.setAssignee(variant);
                }
            }

            if (this.draft.priority) {
                variant = getObjectByCode(this.createTicketOptions.lists.priorityVariants, this.draft.priority);
                if (variant) {
                    this.setPriority(variant);
                }
            }

            if (this.draft.informationSource) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.informationSourceVariants,
                    this.draft.informationSource
                );
                if (variant) {
                    this.setInformationSource(variant);
                }
            }

            this.informationSourceContact = this.draft.informationSourceContact || '';

            if(this.draft.subjectState) {
                variant = getObjectByCode(
                    this.createTicketOptions.lists.subjectStateVariants,
                    this.draft.subjectState
                );
                if (variant) {
                    this.setSubjectState(variant);
                }
            }

            this.previewText = this.draft.previewText || '';
            this.detailText = this.draft.detailText || '';

            this.storedFiles = this.draft.storedFiles || [];
            this.files = [];

            this.optionsQuiteMode = false;
        },

        saveDraft() {
            // Черновик сохраняем в любом состоянии
            this.$emit('saveDraft', this.collectDraft());
        },

        register() {
            const data = this.collectDraft();

            if (this.validateForRegister(data)) {
                this.$emit('register', data);
            }
        },

        collectDraft() {
            let draft = new TicketDraft(this.draft);

            draft.setSubjectState(this.subjectState.code || null);
            draft.setPriority(this.priority.code || null);
            draft.setInformationSource(this.informationSource.code || null);
            draft.setInformationSourceContact(this.informationSourceContact);
            draft.setCoordinatorGroup(this.coordinatorGroup.code || null);
            draft.setCoordinator(this.coordinator.code || null);
            draft.setAssigneeGroup(this.assigneeGroup.code || null);
            draft.setAssignee(this.assignee.code || null);
            draft.setPreviewText(this.previewText);
            draft.setDetailText(this.detailText);
            draft.setFiles(this.files);
            draft.setStoredFiles(this.storedFiles);

            return draft;
        },

        validateForRegister(data) {
            return ComponentErrors.execute([
                [
                    this.fieldPermissionsService.canAdd(this.fields.SUBJECT_STATE) && !data.subjectState,
                    'Заполните ' + this.subjectStateLabel.toLowerCase()
                ],
                [
                    this.fieldPermissionsService.canAdd(this.fields.PRIORITY) && !data.priority,
                    'Заполните приоритет'
                ],
                [
                    this.fieldPermissionsService.canAdd(this.fields.INFORMATION_SOURCE) && !data.informationSource,
                    'Заполните источник информации'
                ],
                [
                    this.fieldPermissionsService.canAdd(this.fields.SOURCE_USER) && !data.informationSourceContact,
                    'Заполните обратившегося'
                ],
                [
                    this.fieldPermissionsService.canAdd(this.fields.PREVIEW_TEXT) && !data.previewText,
                    'Заполните краткое описание'
                ],
                [
                    this.fieldPermissionsService.canAdd(this.fields.COORDINATOR_GROUP) && !data.coordinatorGroup,
                    'Заполните группу координатора'
                ],
                [
                    this.fieldPermissionsService.canAdd(this.fields.ASSIGNEE_GROUP) && !data.assigneeGroup,
                    'Заполните группу ответственного'
                ]
            ]);
        },

        needReloadOptions() {
            if(!this.optionsQuiteMode) {
                this.$emit('needReloadOptions', this.collectDraft());
            }
        },

        addFile(file) {
            if (!this.checkFileSize(file)) {
                Notification.error('Нельзя приложить более ' + this.filesMaxBites() / 1048576 + ' Мб');
                return;
            }

            this.files.push(file);
        },

        removeFile(index) {
            this.files.splice(index, 1);
        },

        removeStoredFile(index) {
            this.storedFiles.splice(index, 1);
        },

        checkFileSize(file) {
            let sizeLeft = this.filesMaxBites(), i;

            for (i in this.files) {
                sizeLeft -= this.files[i].size;
            }

            for (i in this.storedFiles) {
                sizeLeft -= this.storedFiles[i].size;
            }

            return file.size <= sizeLeft;
        },

        filesMaxBites() {
            return this.createTicketOptions?.config?.filesMaxBites || (2 * 1048576);
        },

        getUsersForGroup(groupCode, users) {
            if (!groupCode || !users || !users.length) {
                return [];
            }

            let result = [], user;

            for (let i in users) {
                user = users[i];
                if (user.groups && getObjectByCode(user.groups, groupCode)) {
                    result.push(user);
                }
            }

            return result;
        },

        blockCoordinator() {
            if (this.createTicketOptions.blockFields.coordinator) {
                this.coordinator = this.emptyUser;
            }
        },

        close() {
            this.coordinator = this.emptyUser;
            this.assignee = this.emptyUser;
            this.coordinatorGroup = this.emptyEntity;
            this.assigneeGroup = this.emptyEntity;

            this.priority = this.emptyEntity;
            this.informationSource = this.emptyEntity;
            this.informationSourceContact = '';
            this.subjectState = this.emptyEntity;

            this.previewText = '';
            this.detailText = '';

            this.storedFiles = [];
            this.files = [];

            this.hide();
        }
    },
    computed: {

        isReadableSubjectService() {
            return this.isServiceSubject && this.fieldPermissionsService.canRead(this.fields.CONSUMED_SERVICE)
        },

        isReadableChannelSubjectService() {
            return this.isServiceChannelSubject && this.fieldPermissionsService.canRead(this.fields.CONSUMED_SERVICE_CHANNEL)
        },

        subjectStateLabel() {
            return this.isServiceSubject || this.isServiceChannelSubject
                ? 'Состояние услуги' : 'Состояние объекта инфраструктуры';
        },

        manualInfrastructureTypeTitle() {
            if (!this.manualInfrastructure || !this.createTicketOptions.lists.infrastructureObjectTypes) {
                return '';
            }

            const type = getObjectByCode(
                this.createTicketOptions.lists.infrastructureObjectTypes,
                this.manualInfrastructure.type
            );

            return type ? type.title : ''
        },

        coordinatorVariants() {
            return this.getUsersForGroup(
                this.coordinatorGroup?.code,
                this.createTicketOptions.lists?.coordinatorVariants
            );
        },

        assigneeVariants() {
            return this.getUsersForGroup(
                this.assigneeGroup?.code,
                this.createTicketOptions.lists?.assigneeVariants
            );
        },

        isManualServiceSubject() {
            return this.manualService && this.manualService.service;
        },

        isManualInfrastructureSubject() {
            return this.manualInfrastructure && this.manualInfrastructure.name;
        },

        isServiceSubject() {
            return this.service && this.service.id && !this.isServiceChannelSubject;
        },

        isServiceChannelSubject() {
            return this.channel && this.channel.id;
        },

        isInfrastructureObjectSubject() {
            return this.infrastructureObject && this.infrastructureObject.id;
        },

    },
    watch: {
        createTicketOptions: function () {
            this.initDefaults();
            this.blockCoordinator();
        },
        draft: function () {
            this.initDefaults();
            this.initFromDraft();
            this.blockCoordinator();
        }
    }
});
</script>
<style>
.form-label.label-inline {
    display: inline-block;
    padding-right: 5px;
}

.delete-file {
    padding-left: 10px;
}
</style>
