import ServiceHistoryApi from "@/modules/api/ServiceHistory";

export default {
    namespaced: true,
    state: {
        serviceConsumedHistory: null,
        serviceConsumedTvChannelHistory: null,
    },
    getters: {
        serviceConsumedHistory: (state) => {
            return state.serviceConsumedHistory;
        },
        serviceConsumedTvChannelHistory: (state) => {
            return state.serviceConsumedTvChannelHistory;
        },
    },
    mutations: {
        setServiceConsumedHistory: (state, payload) => {
            state.serviceConsumedHistory = payload;
        },
        setServiceConsumedTvChannelHistory: (state, payload) => {
            state.serviceConsumedTvChannelHistory = payload;
        },
    },
    actions: {
        getServiceConsumedHistory: async (context, payload) => {
            const result = await ServiceHistoryApi.getServiceConsumedHistory(payload);
            if (result.isSuccess()) {
                context.commit('setServiceConsumedHistory', result.getData());
            }
        },
        getServiceConsumedTvChannelHistory: async (context, payload) => {
            const result = await ServiceHistoryApi.getServiceConsumedTvChannelHistory(payload.serviceId, payload.tvChannelId);
            if (result.isSuccess()) {
                context.commit('setServiceConsumedTvChannelHistory', result.getData());
            }
        }
    }
}
