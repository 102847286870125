<template>
    <ModalPopup :id="id"
                ref="modal"
                title="Выбор состояния услуги"
                :is-forced="true">
        <div class="list-group list-group-flush list-group-hoverable card">
            <div v-for="state in states" :key="state.code"
                 class="list-group-item p-2"
                 :class="{'bg-blue-lt': state.code===currentState?.code}"
                 @click="selectState(state)"
                 @dblclick="chooseState(state)"
            >
                <div class="row align-items-center">
                    <div class="col text-truncate ps-3">
                        {{ state.title }}
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 d-flex justify-content-center">
            <div class="btn" @click="chooseState(null)">Выбрать</div>
            <div class="btn" data-bs-dismiss="modal">Выйти без выбора</div>
        </div>
    </ModalPopup>
</template>

<script>
import {defineComponent} from 'vue';
import ModalPopup from "@/components/utils/ModalPopup.vue";
import {getObjectByCode} from "@/components/utils/utilsMethods/getObjectByCode";

export default defineComponent({
    name: 'SubjectState',
    components: {ModalPopup},
    props: {
        states: {
            type: Array
        },
        id: {
            type: String
        }
    },
    emits: ['chooseState'],
    data() {
        return {
            currentState: null
        };
    },
    methods: {
        chooseState(state) {
            if (state) {
                this.selectState(state);
            }

            if (this.currentState && getObjectByCode(this.states, this.currentState.code)) {
                this.$emit('chooseState', this.currentState);
                this.$refs.modal.hide();
            }
        },
        selectState(state) {
            this.currentState = state;
        }
    }
});
</script>
